import type { MutationFunctionOptions } from '@apollo/client';
import { Button, Input, LoadingSpinner, Typography } from '@pesto/ui';
import { Checkbox } from '@pesto/ui/components/Checkbox';
import type { DropdownOption } from '@pesto/ui/components/DropdownInput';
import { DropdownInput } from '@pesto/ui/components/DropdownInput';
import { Label } from '@pesto/ui/components/Label';
import { Modal } from '@pesto/ui/components/Modal';
import { useCallback, useState } from 'react';

import type {
  CreateUserShipmentForApplicationMutationVariables,
  CreditApplication,
} from '../../../__generated__/graphql/api';
import {
  ShipmentCarrier,
  ShipmentProvider,
  useCreateOutboundShipmentForShippingKitMutation,
  useCreateUserShipmentForApplicationMutation,
  UserShipmentType,
} from '../../../__generated__/graphql/api';

type StringKeyObj = { [key: string]: any };
type Mutation = (options: MutationFunctionOptions<any, any>) => Promise<StringKeyObj>;

export const InboundShippingOptions: DropdownOption[] = [
  { value: UserShipmentType.InboundShippingKit, label: 'Shipping Kit (UPS)' },
  { value: UserShipmentType.UserShipping, label: 'ReadyCloud (UPS)' },
];

const performMutation = async (mutation: Mutation, variables: StringKeyObj, callback: (data: StringKeyObj) => void) => {
  mutation({ variables })
    .then(response => {
      if (response?.data) callback(response.data);
      if (response?.errors) alert(JSON.stringify(response.errors));
    })
    .catch(e => alert(e));
};

export const ShippingActions = (props: { application?: CreditApplication }) => {
  const {
    needsOutboundShippingKitCreated,
    needsOutboundShippingKitSent,
    outboundShippingKitSent,
    latestOutboundShippingKitShipment,
    id: appId,
  } = props?.application || ({} as CreditApplication);

  const [showCreateShipmentModal, setShowCreateShipmentModal] = useState(false);
  const [createShipmentForceCreate, setCreateShipmentForceCreate] = useState(false);
  const [createShipmentOverrideInsurance, setCreateShipmentOverrideInsurance] = useState(false);
  const [createShipmentInsuredValue, setCreateShipmentInsuredValue] = useState(0);
  const [createShipmentType, setCreateShipmentType] = useState<UserShipmentType>();

  const existingOutboundKitLabelUrl = latestOutboundShippingKitShipment?.labelUrl;
  const canCreateOutboundLabel =
    needsOutboundShippingKitCreated || needsOutboundShippingKitSent || outboundShippingKitSent;

  const onViewOutboundKitClick =
    existingOutboundKitLabelUrl && (() => window.open(existingOutboundKitLabelUrl, '_blank'));

  const [createOutboundShippingKit, { loading: createOutboundLoading }] =
    useCreateOutboundShipmentForShippingKitMutation();
  const [createUserShipment, { loading: createShipmentLoading }] = useCreateUserShipmentForApplicationMutation();

  const onCreateOutboundKitClick = useCallback(async () => {
    performMutation(createOutboundShippingKit, { creditApplicationId: appId }, data => {
      if (data.createOutboundShipmentForShippingKit) {
        const { labelUrl } = data.createOutboundShipmentForShippingKit;
        labelUrl && window.open(labelUrl, '_blank');
      }
    });
  }, [appId, createOutboundShippingKit]);

  const onCreateUserShipmentClick = useCallback(async () => {
    const vars: CreateUserShipmentForApplicationMutationVariables = {
      creditApplicationId: appId || '',
      shipmentType: createShipmentType || UserShipmentType.InboundShippingKit,
      carrier: ShipmentCarrier.Ups,
      provider:
        createShipmentType === UserShipmentType.InboundShippingKit
          ? ShipmentProvider.ParcelPro
          : ShipmentProvider.ReadyCloud,
      forceCreate: createShipmentForceCreate,
    };

    createShipmentOverrideInsurance && (vars.insuredValue = createShipmentInsuredValue);

    await performMutation(createUserShipment, vars, data => {
      const labelUrl = data.createUserShipmentForApplication?.labelUrl;
      labelUrl && window.open(labelUrl, '_blank');
    });

    setShowCreateShipmentModal(false);
  }, [
    appId,
    createShipmentType,
    createShipmentForceCreate,
    createShipmentOverrideInsurance,
    createShipmentInsuredValue,
    createUserShipment,
  ]);

  const onInsuredValueChange: (event: { target: { value: any } }) => void = event => {
    setCreateShipmentInsuredValue(event.target.value);
  };

  const isLoading = createOutboundLoading || createShipmentLoading;
  return (
    <>
      <Modal isOpen={showCreateShipmentModal} onClose={() => setShowCreateShipmentModal(false)}>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Typography variant={'headerMedium'}>Request User Shipment</Typography>

            <div>
              <Label>Shipment Type</Label>
              <DropdownInput
                options={InboundShippingOptions}
                value={createShipmentType}
                onChange={v => setCreateShipmentType(v as UserShipmentType)}
              />
              <div className="flex text-center">
                <Checkbox
                  title="Force Create"
                  checked={createShipmentForceCreate}
                  onChange={() => setCreateShipmentForceCreate(!createShipmentForceCreate)}
                />
                <p className="my-auto">Force Create</p>
              </div>
              <div className="flex text-center">
                <Checkbox
                  title="Override Insured Value"
                  checked={createShipmentOverrideInsurance}
                  onChange={() => setCreateShipmentOverrideInsurance(!createShipmentOverrideInsurance)}
                />
                <p className="my-auto">Override insured value</p>
              </div>
              {createShipmentOverrideInsurance && (
                <div className="ml-16">
                  <Label>Insured Value</Label>
                  <Input
                    defaultValue={createShipmentInsuredValue}
                    onChange={onInsuredValueChange}
                    // allowZeroInput={true}
                  />
                </div>
              )}
            </div>
            <div className={'flex flex-col gap-2'}>
              <Button variant={'outline'} onClick={() => setShowCreateShipmentModal(false)}>
                Cancel
              </Button>
              <Button onClick={onCreateUserShipmentClick} disabled={!createShipmentType}>
                Create Shipment
              </Button>
            </div>
          </>
        )}
      </Modal>
      <div className="flex flex-col">
        <div className="font-medium text-gray-400">Shipping Actions</div>
        {!isLoading && (
          <>
            <div>
              <Button className={'m-2'} onClick={() => setShowCreateShipmentModal(true)}>
                Inbound Shipping Label
              </Button>
            </div>
            {onViewOutboundKitClick && (
              <div>
                <Button className={'m-2'} onClick={onViewOutboundKitClick}>
                  View Outbound Shipping Kit Label
                </Button>
              </div>
            )}
            {canCreateOutboundLabel && (
              <div>
                <Button color={'destructive'} className={'m-2'} onClick={onCreateOutboundKitClick}>
                  Create Outbound Shipping Kit Label
                </Button>
              </div>
            )}
            {!canCreateOutboundLabel && (
              <div>
                <Button color={'destructive'} className={'m-2'} onClick={onCreateOutboundKitClick}>
                  Create Outbound Shipping Kit Label*
                </Button>
                <Typography variant={'body'}>* Override User Preference</Typography>
              </div>
            )}
          </>
        )}
        {isLoading && <LoadingSpinner />}
      </div>
    </>
  );
};
