import { Button, Card, LoadingSpinner, Typography } from '@pesto/ui';
import { BackButton } from '@pesto/ui/components/BackButton';
import { DataList } from '@pesto/ui/components/DataList';
import { CurrencyField } from '@pesto/ui/components/Forms/CurrencyField';
import { TextField } from '@pesto/ui/components/Forms/TextField';
import { Modal } from '@pesto/ui/components/Modal';
import { humanize, isNetworkRequestInFlight } from '@pesto/utils';
import { OpenInNewWindowIcon } from '@radix-ui/react-icons';
import type { ChangeEvent, ReactNode } from 'react';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { TextAreaField } from '../../../../../packages/ui/components/Forms/TextAreaField';
import {
  useAddAcquirerChargeFeeMutation,
  useCreditFundsMutation,
  useDebitFundsMutation,
  useDoPreAuthorizationMutation,
  useGetCardAprDetailsMutation,
  useGetCardByIdQuery,
  useGetCardStatusMutation,
  useReverseAcquirerChargeFeeMutation,
  useReverseTransactionMutation,
  useSetCardStatusMutation,
  useUpdateCardCashAprMutation,
  useUpdateCardPurchaseAprMutation,
  useUpdateCreditCardLimitMutation,
} from '../../__generated__/graphql/api';
import { routes } from '../../constants/routes';

export enum CreditCardActions {
  ADD_ACQUIRER_CHARGE_FEE = 'ADD_ACQUIRER_CHARGE_FEE',
  REVERSE_ACQUIRER_CHARGE_FEE = 'REVERSE_ACQUIRER_CHARGE_FEE',
  REVERSE_TRANSACTION = 'REVERSE_TRANSACTION',
  DEBIT_FUNDS = 'DEBIT_FUNDS',
  CREDIT_FUNDS = 'CREDIT_FUNDS',
  DO_PRE_AUTHORIZATION = 'DO_PRE_AUTHORIZATION',
  SET_CARD_STATUS = 'SET_CARD_STATUS',
  UPDATE_CREDIT_CARD_LIMIT = 'UPDATE_CREDIT_CARD_LIMIT',
  GET_CARD_STATUS = 'GET_CARD_STATUS',
  UPDATE_CARD_PURCHASE_APR = 'UPDATE_CARD_PURCHASE_APR',
  UPDATE_CARD_CASH_APR = 'UPDATE_CARD_CASH_APR',
  GET_CARD_APR_DETAILS = 'GET_CARD_APR_DETAILS',
}

export const CardDetails = () => {
  const params = useParams();
  const [action, setAction] = useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [description, setDescription] = useState('');
  const [transId, setTransId] = useState('');
  const [preAuthExpire, setPreAuthExpire] = useState('');
  const [statusCode, setStatusCode] = useState('');
  const [newApr, setNewApr] = useState<string>('');
  const [amount, setAmount] = useState(0);
  const { data, loading, networkStatus } = useGetCardByIdQuery({
    variables: { cardId: params?.cardId ?? '' },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const resetAllState = () => {
    setDescription('');
    setTransId('');
    setPreAuthExpire('');
    setStatusCode('');
    setNewApr('');
    setAmount(0);
  };

  const card = data?.card;
  const cardDetails = {
    id: card?.id,
    status: card?.status,
    creditLimit: card?.creditLimit,
    insertedAt: card?.insertedAt,
    referenceId: card?.referenceId,
  };
  const userDetails = {
    id: card?.user?.id,
    firstName: card?.user?.firstName,
    lastName: card?.user?.lastName,
    email: card?.user?.email,
    phone: card?.user?.phone,
  };

  const basicVariables = {
    referenceId: card?.referenceId ?? '',
    description: description,
  };

  const [addAcquirerChargeFee, { loading: addAcquirerChargeFeeLoading }] = useAddAcquirerChargeFeeMutation({
    variables: {
      ...basicVariables,
      feeAmountInCents: amount,
    },
  });
  const [reverseAcquirerChargeFee, { loading: reverseAcquirerChargeFeeLoading }] = useReverseAcquirerChargeFeeMutation({
    variables: {
      ...basicVariables,
      transId: transId,
    },
  });
  const [reverseTransaction, { loading: reverseTransactionLoading }] = useReverseTransactionMutation({
    variables: {
      ...basicVariables,
      transId: transId,
    },
  });
  const [debitFunds, { loading: debitFundsLoading }] = useDebitFundsMutation({
    variables: {
      ...basicVariables,
      amountInCents: amount,
    },
  });
  const [creditFunds, { loading: creditFundsLoading }] = useCreditFundsMutation({
    variables: {
      ...basicVariables,
      amountInCents: amount,
    },
  });
  const [doPreAuthorization, { loading: doPreAuthorizationLoading }] = useDoPreAuthorizationMutation({
    variables: {
      ...basicVariables,
      amountInCents: amount,
      preAuthExpiry: preAuthExpire,
    },
  });
  const [setCardStatus, { loading: setCardStatusLoading }] = useSetCardStatusMutation({
    variables: {
      ...basicVariables,
      statusCode: statusCode,
    },
  });
  const [updateCreditCardLimit, { loading: updateCreditCardLimitLoading }] = useUpdateCreditCardLimitMutation({
    variables: {
      ...basicVariables,
      newLimitInCents: amount,
    },
  });
  const [getCardStatus, { loading: getCardStatusLoading }] = useGetCardStatusMutation({
    variables: {
      ...basicVariables,
    },
  });
  const [updateCardPurchaseApr, { loading: updateCardPurchaseAprLoading }] = useUpdateCardPurchaseAprMutation({
    variables: {
      ...basicVariables,
      purchaseApr: parseFloat(newApr),
    },
  });
  const [updateCardCashApr, { loading: updateCardCashAprLoading }] = useUpdateCardCashAprMutation({
    variables: {
      ...basicVariables,
      cashAprValue: parseFloat(newApr),
    },
  });
  const [getCardAprDetails, { loading: getCardAprDetailsLoading }] = useGetCardAprDetailsMutation({
    variables: {
      ...basicVariables,
    },
  });

  const actionLoading =
    addAcquirerChargeFeeLoading ||
    reverseAcquirerChargeFeeLoading ||
    reverseTransactionLoading ||
    debitFundsLoading ||
    creditFundsLoading ||
    doPreAuthorizationLoading ||
    setCardStatusLoading ||
    updateCreditCardLimitLoading ||
    getCardStatusLoading ||
    updateCardPurchaseAprLoading ||
    updateCardCashAprLoading ||
    getCardAprDetailsLoading;

  const handleModalConfirm = useCallback(() => {
    const actionsMap = {
      [CreditCardActions.ADD_ACQUIRER_CHARGE_FEE]: addAcquirerChargeFee,
      [CreditCardActions.REVERSE_ACQUIRER_CHARGE_FEE]: reverseAcquirerChargeFee,
      [CreditCardActions.REVERSE_TRANSACTION]: reverseTransaction,
      [CreditCardActions.DEBIT_FUNDS]: debitFunds,
      [CreditCardActions.CREDIT_FUNDS]: creditFunds,
      [CreditCardActions.DO_PRE_AUTHORIZATION]: doPreAuthorization,
      [CreditCardActions.SET_CARD_STATUS]: setCardStatus,
      [CreditCardActions.UPDATE_CREDIT_CARD_LIMIT]: updateCreditCardLimit,
      [CreditCardActions.GET_CARD_STATUS]: getCardStatus,
      [CreditCardActions.UPDATE_CARD_PURCHASE_APR]: updateCardPurchaseApr,
      [CreditCardActions.UPDATE_CARD_CASH_APR]: updateCardCashApr,
      [CreditCardActions.GET_CARD_APR_DETAILS]: getCardAprDetails,
    };
    actionsMap[action as keyof typeof actionsMap]();
    setShowModal(false);
  }, [
    action,
    addAcquirerChargeFee,
    creditFunds,
    debitFunds,
    doPreAuthorization,
    getCardAprDetails,
    getCardStatus,
    reverseAcquirerChargeFee,
    reverseTransaction,
    setCardStatus,
    updateCardCashApr,
    updateCardPurchaseApr,
    updateCreditCardLimit,
  ]);

  const loadingOrReFetching = isNetworkRequestInFlight({ networkStatus: networkStatus, loading: loading });

  const getModalTitle = useCallback(() => {
    return (
      <div>
        Please confirm you want to <strong>{humanize(action)}</strong> for this card
      </div>
    );
  }, [action]);

  const modalReasonTextArea = (
    <TextAreaField defaultValue={''} onChange={value => setDescription(value.target.value)} label={'Reason'} />
  );
  const amountInput = (
    <CurrencyField
      label={'Amount'}
      required
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        setAmount(Number(e.target.value));
      }}
      value={amount}
    />
  );

  const transIdInput = (
    <TextField
      value={transId}
      onChange={(e: any) => {
        setTransId(e.target.value);
      }}
      label={'Transaction ID'}
      required
    />
  );

  const preAuthExpireInput = (
    <TextField
      value={preAuthExpire}
      onChange={(e: any) => {
        setPreAuthExpire(e.target.value);
      }}
      label={'Pre Auth Expiry'}
      required
    />
  );

  const statusCodeInput = (
    <TextField
      value={statusCode}
      onChange={(e: any) => {
        setStatusCode(e.target.value);
      }}
      label={'Status Code'}
      required
    />
  );

  const newAprInput = (
    <TextField
      value={newApr}
      onChange={(e: any) => {
        setNewApr(e.target.value);
      }}
      label={'New APR'}
      required
    />
  );

  const constructForm = (arrayOfInputs: ReactNode[]) => {
    return (
      <div className="flex flex-col gap-4">
        {arrayOfInputs.map((input, index) => {
          return <div key={index}>{input}</div>;
        })}
      </div>
    );
  };

  const modalContent = () => {
    const actionComponents = {
      [CreditCardActions.ADD_ACQUIRER_CHARGE_FEE]: constructForm([amountInput, modalReasonTextArea]),
      [CreditCardActions.REVERSE_ACQUIRER_CHARGE_FEE]: constructForm([transIdInput, modalReasonTextArea]),
      [CreditCardActions.REVERSE_TRANSACTION]: constructForm([transIdInput, modalReasonTextArea]),
      [CreditCardActions.DEBIT_FUNDS]: constructForm([amountInput, modalReasonTextArea]),
      [CreditCardActions.CREDIT_FUNDS]: constructForm([amountInput, modalReasonTextArea]),
      [CreditCardActions.DO_PRE_AUTHORIZATION]: constructForm([amountInput, preAuthExpireInput, modalReasonTextArea]),
      [CreditCardActions.SET_CARD_STATUS]: constructForm([statusCodeInput, modalReasonTextArea]),
      [CreditCardActions.UPDATE_CREDIT_CARD_LIMIT]: constructForm([amountInput, modalReasonTextArea]),
      [CreditCardActions.GET_CARD_STATUS]: constructForm([modalReasonTextArea]),
      [CreditCardActions.UPDATE_CARD_PURCHASE_APR]: constructForm([newAprInput, modalReasonTextArea]),
      [CreditCardActions.UPDATE_CARD_CASH_APR]: constructForm([newAprInput, modalReasonTextArea]),
      [CreditCardActions.GET_CARD_APR_DETAILS]: constructForm([modalReasonTextArea]),
    };

    return actionComponents[action as keyof typeof actionComponents];
  };

  if (loadingOrReFetching) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <div className={'flex flex-col gap-4 p-4'}>
        <BackButton />
        <div className="grid grid-cols-2 gap-4">
          <Card className={'bg-primary-foreground flex flex-col gap-6 p-6'}>
            <Typography variant={'headerLarge'}>Card Details</Typography>
            {card && <DataList root={cardDetails} />}
          </Card>{' '}
          <Card className={'bg-primary-foreground flex flex-col gap-6 p-6'}>
            <Typography variant={'headerLarge'}>User Details</Typography>
            {card && <DataList root={userDetails} />}
            <Button
              variant={'outline'}
              className="w-auto self-start"
              onClick={() => {
                window.open(`${routes.users}/${userDetails?.id}`, '_blank');
              }}
            >
              <OpenInNewWindowIcon /> View User
            </Button>
          </Card>
          <Card className={'col-span-2 flex flex-col gap-6 p-6'}>
            <Typography variant={'headerLarge'}>Card actions</Typography>
            <div className="flex flex-wrap gap-4">
              {Object.values(CreditCardActions).map((action, index) => {
                return (
                  <Button
                    key={index}
                    disabled={actionLoading}
                    className="flex gap-2"
                    onClick={() => {
                      setAction(action);
                      setShowModal(true);
                    }}
                  >
                    {actionLoading && <LoadingSpinner />} {humanize(action)}
                  </Button>
                );
              })}
            </div>
          </Card>
        </div>
      </div>
      <Modal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          resetAllState();
        }}
        title={getModalTitle()}
        onConfirm={handleModalConfirm}
        loading={actionLoading}
      >
        <div>{modalContent()}</div>
      </Modal>
    </>
  );
};
