import type { AuthConfig } from '@pesto/features';
import { AuthProvider } from '@pesto/features';
import { UiLibProvider } from '@pesto/ui/providers/UiLibProvider';

import './App.css';
import { routes } from './constants/routes';
import { AuthManageProvider } from './providers/AuthManageProvider';
import ErrorBoundary from './providers/ErrorBoundary';
import { RouterProvider } from './providers/RouterProvider';

const authConfig: AuthConfig = {
  domain: import.meta.env.VITE_AUTH0_DOMAIN || '',
  clientID: import.meta.env.VITE_MC_AUTH0_CLIENT_ID || '',
  audience: import.meta.env.VITE_AUTH0_AUDIENCE || '',
  redirectUri: window && window.location.origin + routes.loginCallback,
};

function App() {
  return (
    <AuthProvider config={authConfig}>
      <AuthManageProvider>
        <ErrorBoundary>
          <UiLibProvider>
            <RouterProvider />
          </UiLibProvider>
        </ErrorBoundary>
      </AuthManageProvider>
    </AuthProvider>
  );
}

export default App;
