import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

import { Input } from '../Input';

import { TextFieldWrapper } from './TextFieldWrapper';
import type { TextFieldProps } from './types/Input.types';

export const TextField = forwardRef((props: TextFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
  const {
    className,
    inputClassName,
    onChange,
    label,
    placeholder,
    value,
    error,
    readOnly = false,
    ...inputProps
  } = props;

  return (
    <TextFieldWrapper className={className} error={error} label={label} readOnly={readOnly}>
      <Input
        ref={ref}
        className={inputClassName}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        readOnly={readOnly}
        {...inputProps}
      />
    </TextFieldWrapper>
  );
});
