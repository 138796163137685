import { Typography } from '@pesto/ui';
import type { DataTableFilterField } from '@pesto/ui/components/DataTable/DataTable.types';
import { DataTableColumnHeader } from '@pesto/ui/components/DataTable/DataTableColumnHeader';
import {
  generateSelectableFilterOptions,
  setColumnFilterAndSortKey,
} from '@pesto/ui/features/SmartTable/utils/helpers';
import { formatDate } from '@pesto/utils';
import type { ColumnDef, TableState } from '@tanstack/react-table';

import { SecurityEventType, type SecurityEvent } from '../../../../__generated__/graphql/api';

const columnIdNames = {
  insertedAt: setColumnFilterAndSortKey('', 'insertedAtSortDirection'),
  userId: setColumnFilterAndSortKey('userIdSearch', 'userIdSortDirection'),
  type: setColumnFilterAndSortKey('typeSelections', 'typeSortDirection'),
};

export const useSecurityEventsListConfig = () => {
  const securityEventsFilterFields: DataTableFilterField<any>[] = [
    {
      label: 'User ID',
      value: columnIdNames.userId,
      placeholder: 'Search by User ID',
    },
    {
      label: 'Type',
      value: columnIdNames.type,
      options: generateSelectableFilterOptions(SecurityEventType),
    },
  ];

  const getSecurityEventsColumns = (): ColumnDef<SecurityEvent>[] => [
    {
      id: columnIdNames.insertedAt,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Created" />,
      accessorFn: row => row.insertedAt,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {formatDate(row.cell.getValue() as string)}
        </Typography>
      ),
    },
    {
      id: columnIdNames.type,
      header: 'Type',
      accessorFn: row => row.type,
      cell: row => row.cell.getValue(),
    },
    {
      id: 'description',
      header: 'Description',
      accessorFn: row => row.description,
      cell: row => row.cell.getValue(),
    },
    {
      id: 'userFirstName',
      header: 'First Name',
      accessorFn: row => row.user?.firstName,
      cell: row => row.cell.getValue(),
    },
    {
      id: 'userLastName',
      header: 'Last Name',
      accessorFn: row => row.user?.lastName,
      cell: row => row.cell.getValue(),
    },
    {
      id: 'userEmail',
      header: 'Email',
      accessorFn: row => row.user?.email,
      cell: row => row.cell.getValue(),
    },
    {
      id: columnIdNames.userId,
      header: 'User ID',
      accessorFn: row => row.user?.id,
      cell: row => row.cell.getValue(),
    },
  ];

  const initialTableState: Partial<TableState> = {
    columnPinning: { left: ['view'] },
    sorting: [{ id: 'insertedAtSortDirection', desc: true }],
  };

  return { securityEventsFilterFields, getSecurityEventsColumns, initialTableState };
};
