import { Navigate, Outlet } from 'react-router-dom';

import { routes } from '../constants/routes';

interface ProtectedRouteProps {
  isAuthenticated: boolean;
  redirectPath?: string;
}
export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { isAuthenticated, redirectPath = routes.login } = props;

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};
