import type { MiniStepperStepType } from './MiniStepper';

export interface MiniStepperConnectorProps {
  stepNumber: number;
  index: number;
  priorStep: MiniStepperStepType;
  nextStep?: MiniStepperStepType;
}

export const MiniStepperConnector = ({ stepNumber, index, priorStep, nextStep }: MiniStepperConnectorProps) => {
  if (!nextStep) return null;
  const priorComplete = index <= stepNumber;
  const nextComplete = index < stepNumber;

  const priorColor = priorComplete ? 'from-green-500' : priorStep.attention ? 'from-yellow-500' : 'from-white';
  const nextColor = nextComplete ? 'to-green-500' : nextStep.attention ? 'to-yellow-500' : 'to-white';

  return (
    <div className="flex flex-col justify-around">
      <div className={`${priorColor} ${nextColor} -z-1 h-1 w-2 bg-gradient-to-r`} />
    </div>
  );
};
