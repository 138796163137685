import type { SelectProps } from '@radix-ui/react-select';
import type { FC } from 'react';

import { TextFieldWrapper } from './Forms/TextFieldWrapper';
import { generateInputStyles } from './Forms/inputStylesHelper';
import type { InputPestoProps } from './Forms/types/Input.types';
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from './Select';

export type DropdownInputProps = InputPestoProps & {
  options: DropdownOptionGroup[] | DropdownOption[];
  value: string | undefined;
  onChange: (value: string) => void;
  placeholder?: string;
  selectTriggerClassName?: string;
};

export type DropdownOptionGroup = {
  label: string;
  items: DropdownOption[];
};

export type DropdownOption = {
  value: string;
  label: string;
};

export const DropdownInput: FC<DropdownInputProps & SelectProps> = props => {
  const {
    className,
    error,
    label,
    options,
    onChange,
    readOnly,
    placeholder = 'Select an option',
    selectTriggerClassName = 'w-full',
  } = props;
  const isGrouped = (options: DropdownOptionGroup[] | DropdownOption[]): options is DropdownOptionGroup[] =>
    (options as DropdownOptionGroup[]).every(option => 'items' in option);

  const selectTrigger = generateInputStyles({
    className: selectTriggerClassName,
    isError: !!error,
    readOnly,
  });
  return (
    <TextFieldWrapper className={className} error={error} label={label}>
      <Select onValueChange={onChange} {...props}>
        <SelectTrigger readOnly={readOnly} className={selectTrigger}>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          {isGrouped(options)
            ? options.map((group, groupIndex) => (
                <SelectGroup key={groupIndex}>
                  <SelectLabel>{group.label}</SelectLabel>
                  {group.items.map(item => (
                    <SelectItem key={item.value} value={item.value}>
                      {item.label}
                    </SelectItem>
                  ))}
                </SelectGroup>
              ))
            : options.map(item => (
                <SelectItem key={item.value} value={item.value}>
                  {item.label}
                </SelectItem>
              ))}
        </SelectContent>
      </Select>
    </TextFieldWrapper>
  );
};
