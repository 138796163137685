import { cn } from '@pesto/utils';
import React from 'react';
import { Link } from 'react-router-dom';

import type { NavigationLinkProps } from './Navigation.types';

export const NavigationLink: React.FC<NavigationLinkProps> = props => {
  const { label, href, current = false, icon = () => null, className, minimized, isSub, onClick } = props;
  const Icon = icon;
  const style = cn(
    'flex items-center gap-4 sm:gap-3 sm:rounded-lg rounded-xl px-3 py-2 transition-all hover:text-primary',
    className,
    {
      'text-muted-foreground': !current,
      'bg-muted text-primary': current,
    },
  );

  const displayLabel = (() => {
    if (minimized && !isSub) {
      return '';
    }
    return label;
  })();

  return (
    <Link to={href || ''} onClick={() => onClick(href || '')} className={style}>
      <Icon className="h-4 w-4" />
      {displayLabel}
    </Link>
  );
};
