import qs from 'qs';
import { z } from 'zod';

const searchParamsSchema = z
  .object({
    page: z.coerce.number().default(1),
    pageNumber: z.coerce.number().default(1),
    resultsPerPage: z.coerce.number().optional(),
    sort: z.any().optional(),
    to: z.string().optional(),
    from: z.string().optional(),
    fullResults: z.boolean().optional(),
  })
  .catchall(z.any())
  .transform(data => {
    const transformSortData = (sort: any) => {
      if (typeof sort === 'string') {
        const [key, direction] = sort.split('.');
        const upperDirection = direction?.toUpperCase() ?? '';
        return {
          [key]: upperDirection,
        };
      }
      return sort;
    };

    const recursiveTransform = (value: any): any => {
      if (value === 'true') return true;
      if (value === 'false') return false;
      if (typeof value === 'string' && value.includes('.')) {
        return value.split('.');
      }
      if (Array.isArray(value)) return value.map(recursiveTransform);
      if (typeof value === 'object' && value !== null) {
        return Object.fromEntries(Object.entries(value).map(([k, v]) => [k, recursiveTransform(v)]));
      }
      return value;
    };

    const transformedData = recursiveTransform(data);

    if (transformedData.sort) {
      transformedData.sort = transformSortData(transformedData.sort);
    }

    return {
      ...transformedData,
      ...('sort' in data && data.sort ? transformSortData(data.sort) : {}),
    };
  });

export const parseFiltersFromSearchParams = <T>(searchParams: URLSearchParams): T => {
  const paramsString = searchParams.toString();
  const paramsObject = qs.parse(paramsString, { ignoreQueryPrefix: true });
  return searchParamsSchema.parse(paramsObject) as T;
};
