import type { Config } from '@pesto/application-config';
import type { ReactNode } from 'react';

import { Toaster } from '../components/Toaster';

import { ConfigProvider } from './ConfigProvider';
import { ThemeProvider } from './ThemeProvider';

interface UiLibProviderProps {
  children: ReactNode;
  config?: Config;
}

export const UiLibProvider = (props: UiLibProviderProps) => {
  const { children, config } = props;
  return (
    <ConfigProvider defaultConfig={config}>
      <ThemeProvider storageKey="ui-theme">
        <Toaster />
        {children}
      </ThemeProvider>
    </ConfigProvider>
  );
};
