import { useListQuery } from '@pesto/hooks';
import { SmartTable } from '@pesto/ui/features/SmartTable/SmartTable';
import { useNavigate } from 'react-router-dom';

import { useListShipmentsQuery, type Shipment } from '../../__generated__/graphql/api';
import { routes } from '../../constants/routes';

import { useShipmentsListConfig } from './hooks/useShipmentsListConfig';

export const Shipments = () => {
  const navigate = useNavigate();
  const { data, loadingOrReFetching } = useListQuery({
    queryHook: useListShipmentsQuery,
  });
  const { getShipmentsColumns, shipmentsFilterFields, initialTableState } = useShipmentsListConfig();
  const amountOfRows = data?.listShipments?.pagination.totalRecords;

  const rows = data?.listShipments?.shipments ?? [];

  const handleRowClicked = (row: Shipment) => {
    navigate(`${routes.shipments}/${row?.trackingNumber}`);
  };
  const columns = getShipmentsColumns(handleRowClicked);

  return (
    <SmartTable
      title="Shipments"
      amountOfRows={amountOfRows}
      rows={rows}
      columns={columns}
      filterFields={shipmentsFilterFields}
      loading={loadingOrReFetching}
      pageCount={data?.listShipments?.pagination?.totalPages}
      initialState={initialTableState}
      enableDateRangeFilter
    />
  );
};
