import { EyeIcon, EyeSlashIcon, PlusCircleIcon } from '@heroicons/react/20/solid';
import { Button } from '@pesto/ui';
import { generateRandomId } from '@pesto/utils';
import { MONEY_MULTIPLIER } from '@pesto/utils/constants/pestoConstants';
import React from 'react';

import type { Item, ItemFragmentFragment } from '../../../__generated__/graphql/api';
import { AssetType } from '../../../__generated__/graphql/api';
import { CashCard } from '../../../components/CashCard/CashCard';
import { ItemCard } from '../../../components/ItemCard';
import { routes } from '../../../constants/routes';
import { ApplicationActions } from '../hooks/useApplicationModal';

import { InactiveItemsPreview } from './InactiveItemsPreview';

type Props = {
  items: ItemFragmentFragment | any;
  inNoActiveItems: ItemFragmentFragment | any;
  filterInactive: boolean;
  handleSetFilterInactive: () => void;
  canAddCollateral: boolean;
  navigate: (path: string) => void;
  application: any;
  refetch: () => void;
  setAction: React.Dispatch<React.SetStateAction<ApplicationActions | null>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setPaymentId: React.Dispatch<React.SetStateAction<string>>;
};
export const ApplicationDetailsView = (props: Props) => {
  const {
    items,
    inNoActiveItems,
    filterInactive,
    handleSetFilterInactive,
    canAddCollateral,
    navigate,
    application,
    refetch,
    setAction,
    setShowModal,
    setPaymentId,
  } = props;
  return (
    <div>
      <div className="flex items-center gap-x-2">
        {canAddCollateral && (
          <Button
            onClick={() => {
              navigate(`${routes.addOtherItem}/${application?.creditApplication?.id}`);
            }}
          >
            <PlusCircleIcon className="h-full" /> Add item
          </Button>
        )}
        <Button
          onClick={() => {
            setAction(ApplicationActions.CREATE_BASIC_ITEM);
            setShowModal(true);
          }}
        >
          <PlusCircleIcon className="h-full" /> Basic Item
        </Button>

        <Button variant="outline" onClick={handleSetFilterInactive}>
          {filterInactive ? <EyeIcon className="h-full" /> : <EyeSlashIcon className="h-full" />}
          {filterInactive ? 'Show Inactive' : 'Hide Inactive'}
        </Button>
      </div>

      <div className="my-4 grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-6">
        {filterInactive &&
          inNoActiveItems?.map((item: Item, key: string) => {
            const creditValue = item?.creditValue ?? 0;
            return (
              <InactiveItemsPreview
                key={key + generateRandomId()}
                itemType={item?.subtype ?? ''}
                insertedAt={item?.insertedAt ?? ''}
                creditValue={creditValue / MONEY_MULTIPLIER}
              />
            );
          })}
      </div>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-4">
        {items?.map((item: Item) => {
          if (item.type === AssetType.Cash)
            return (
              <CashCard
                item={item}
                key={item.id}
                onRefundSuccess={refetch}
                setAction={setAction}
                setShowModal={setShowModal}
                setPaymentId={setPaymentId}
              />
            );
          return <ItemCard item={item} key={item.id} />;
        })}
      </div>
    </div>
  );
};
