import { MiniStepper } from '@pesto/ui/features/SmartTable/components/MiniStepper/MiniStepper';
import { humanize } from '@pesto/utils';

import type { CreditApplication, GetCreditApplicationByIdQuery } from '../../../__generated__/graphql/api';
import { ShippingPreference } from '../../../__generated__/graphql/api';

import { ShippingActions } from './ShipmentActions';

interface ShippingDetailsContentProps {
  application: GetCreditApplicationByIdQuery['creditApplication'];
}

export const ShippingDetailsContent = (props: ShippingDetailsContentProps) => {
  const { application } = props;

  const renderShippingStatus = () => {
    if (application) {
      const {
        hasUserShipment,
        needsOutboundShippingKitCreated,
        needsOutboundShippingKitSent,
        outboundShippingKitSent,
      } = application;

      if (application?.shippingPreference === ShippingPreference.ShippingKit)
        return (
          <MiniStepper
            steps={[
              {
                description: 'User - Create inbound Shipment',
                complete: hasUserShipment,
              },
              {
                description: 'Admin - Create Outbound Shipping Kit label',
                complete: needsOutboundShippingKitSent,
                attention: needsOutboundShippingKitCreated,
              },
              {
                description: 'Admin - send Outbound Shipping Kit',
                complete: outboundShippingKitSent,
                attention: needsOutboundShippingKitSent,
              },
              {
                description: 'User - Ship package',
                complete: application?.hasUserShipmentInTransit ?? false,
              },
              {
                description: 'package delivered',
                complete: application?.hasUserShipmentDelivered ?? false,
              },
            ]}
          />
        );

      if (application?.shippingPreference === ShippingPreference.Ship)
        return (
          <MiniStepper
            steps={[
              {
                description: 'User - Create Shipment',
                complete: hasUserShipment,
              },
              {
                description: 'User - Ship package',
                complete: application?.hasUserShipmentInTransit ?? false,
              },
              {
                description: 'package delivered',
                complete: application?.hasUserShipmentDelivered ?? false,
              },
            ]}
          />
        );
    }
  };

  return (
    <div className={'bg-primary-foreground flex h-full w-full flex-col items-center justify-between'}>
      <div className={'h-full'}>
        <div className="border-b-2">
          <div className="text-primary/60 px-4 py-4 font-medium">
            Shipping Status
            <div className="text-primary mt-2">{renderShippingStatus() ?? '--'}</div>
            {application && <ShippingActions application={application as CreditApplication} />}
          </div>
        </div>

        <div className="border-b">
          <div className="text-primary/60 px-4 py-4 font-medium">
            Has user selected shipment?
            <div className={'text-primary'}>{application?.hasUserShipment ? 'Yes' : 'No'}</div>
          </div>
        </div>
        <div className="border-b">
          <div className="text-primary/60 px-4 py-4 font-medium">
            Shipping Preference --
            <div className={'text-primary'}>{humanize(application?.shippingPreference ?? '') ?? '--'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
