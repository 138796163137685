import axios from 'axios';

export const CLOUFLARE_URL = 'https://' + location.hostname + '/legal/';
const token = localStorage.getItem('token');
const axiosConfig = {
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
};
export const uploadLegalFile = async (file: File, fileName: string) => {
  await axios.put(CLOUFLARE_URL + fileName, file, axiosConfig);
};
