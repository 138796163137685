import { Card, Typography } from '@pesto/ui';
import clsx from 'clsx';
import type { ReactNode } from 'react';

interface UserDetailsCardProps {
  children: ReactNode;
  title?: string;
  className?: string;
}

export const UserDetailsCard = (props: UserDetailsCardProps) => {
  const { children, title, className } = props;
  const rootStyle = clsx('flex flex-col gap-4 border p-4', className);
  return (
    <Card className={rootStyle}>
      {title && <Typography variant={'headerSmall'}>{title}</Typography>}
      {children}
    </Card>
  );
};
