import type { ColumnDef, TableState } from '@tanstack/react-table';
import type { ReactNode } from 'react';
import React from 'react';

import { DataTable } from '../../components/DataTable/DataTable';
import type { DataTableFilterField } from '../../components/DataTable/DataTable.types';
import type { DataTableSkeletonProps } from '../../components/DataTable/DataTableSkeleton';
import { DataTableSkeleton } from '../../components/DataTable/DataTableSkeleton';

import { DateRangeFilter } from './components/DateRangeFilter/DateRangeFilter';
import { AdvancedDataTableToolbar } from './components/Filters/AdvancedDataTableToolbar';
import { TasksTableToolbarActions } from './components/TasksTableToolbarActions';
import { useDataTable } from './hooks/useDataTable';

interface TableProps<TData = any> {
  title?: string;
  exportTableName?: string;
  amountOfRows?: number;
  skeletonProps?: DataTableSkeletonProps;
  rows: TData[];
  columns: ColumnDef<TData>[];
  filterFields?: DataTableFilterField<TData>[];
  pageCount?: number;
  loading?: boolean;
  initialState?: Partial<TableState>;
  showPagination?: boolean;
  enableDateRangeFilter?: boolean;
  customFilters?: ReactNode;
}

interface TableWrapperProps<T = any> extends TableProps<T> {
  children: React.ReactNode;
  tableData?: any;
}

const defaultSkeleton: DataTableSkeletonProps = {
  columnCount: 5,
  cellWidths: ['10rem', '40rem', '12rem', '12rem', '8rem'],
  shrinkZero: true,
  showViewOptions: false,
};

export const TableWrapper = (props: TableWrapperProps) => {
  const { skeletonProps = defaultSkeleton, loading } = props;
  if (loading) return <DataTableSkeleton {...skeletonProps} />;
  return <React.Suspense fallback={<DataTableSkeleton {...skeletonProps} />}>{props.children}</React.Suspense>;
};

export const SmartTable = (props: TableProps) => {
  const {
    rows: data,
    columns: cols,
    pageCount = 1,
    filterFields,
    initialState,
    showPagination,
    amountOfRows,
    title,
    loading,
    enableDateRangeFilter,
    customFilters,
    exportTableName,
  } = props;

  // Memoize the columns so they don't re-render on every render
  const columns = React.useMemo(() => cols, [cols]);

  /**
   * This component can render either a faceted filter or a search filter based on the `options` prop.
   *
   * @prop options - An array of objects, each representing a filter option. If provided, a faceted filter is rendered. If not, a search filter is rendered.
   *
   * Each `option` object has the following properties:
   * @prop {string} label - The label for the filter option.
   * @prop {string} value - The value for the filter option.
   * @prop {React.ReactNode} [icon] - An optional icon to display next to the label.
   * @prop {boolean} [withCount] - An optional boolean to display the count of the filter option.
   */

  const { table } = useDataTable({
    data,
    columns,
    pageCount,
    filterFields,
    enableAdvancedFilter: false,
    initialState: {
      columnPinning: { right: ['actions'] },
      ...initialState,
    },
    // For remembering the previous row selection on page change
    getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
    /* */
  });

  return (
    <div className="flex flex-col gap-3">
      <AdvancedDataTableToolbar
        loading={loading}
        table={table}
        filterFields={filterFields}
        title={title}
        amountOfRows={amountOfRows}
        customFilters={customFilters}
      >
        {enableDateRangeFilter && <DateRangeFilter />}
        <TasksTableToolbarActions table={table} title={exportTableName || title} />
      </AdvancedDataTableToolbar>
      <TableWrapper {...props}>
        <DataTable table={table} showPagination={showPagination}></DataTable>
      </TableWrapper>
    </div>
  );
};
