import { useListQuery } from '@pesto/hooks';
import { SmartTable } from '@pesto/ui/features/SmartTable/SmartTable';
import { useState } from 'react';

import { useGetTransactionsListQuery, type Transaction } from '../../__generated__/graphql/api';

import { ModalSinglePayment } from './components/ModalSinglePayment';
import { useTransactionsListConfig } from './hooks/useTransactionsListConfig';

interface SinglePaymentModal {
  isOpen: boolean;
  transaction: Transaction | null;
}

export const Transactions = () => {
  const [transaction, setTransaction] = useState<SinglePaymentModal>({
    isOpen: false,
    transaction: null,
  });
  const { data, loadingOrReFetching } = useListQuery({
    queryHook: useGetTransactionsListQuery,
  });
  const { transactionsFilterFields, getTransactionsColumns, initialTableState } = useTransactionsListConfig();
  const amountOfRows = data?.listTransactions?.pagination.totalRecords;

  const rows = data?.listTransactions?.transactions ?? [];

  const handleRowClicked = (row: Transaction) => {
    setTransaction({
      isOpen: true,
      transaction: row,
    });
  };
  const columns = getTransactionsColumns(handleRowClicked);

  return (
    <>
      <SmartTable
        title="Transactions"
        amountOfRows={amountOfRows}
        rows={rows}
        columns={columns}
        filterFields={transactionsFilterFields}
        loading={loadingOrReFetching}
        pageCount={data?.listTransactions?.pagination?.totalPages}
        initialState={initialTableState}
        enableDateRangeFilter
      />
      <ModalSinglePayment
        isOpen={transaction.isOpen}
        onClose={() => {
          setTransaction({
            isOpen: false,
            transaction: null,
          });
        }}
        transaction={transaction.transaction}
      />
    </>
  );
};
