import { useListQuery } from '@pesto/hooks';
import { SmartTable } from '@pesto/ui/features/SmartTable/SmartTable';
import { useNavigate } from 'react-router-dom';

import { useGetPaymentsListQuery, type Payment } from '../../__generated__/graphql/api';
import { routes } from '../../constants/routes';

import { usePaymentsListConfig } from './hooks/usePaymentsListConfig';

export const Payments = () => {
  const navigate = useNavigate();
  const { data, loadingOrReFetching } = useListQuery({
    queryHook: useGetPaymentsListQuery,
  });
  const { getPaymentsColumns, paymentsFilterFields, initialTableState } = usePaymentsListConfig();
  const amountOfRows = data?.listPayments?.pagination.totalRecords;

  const rows = data?.listPayments?.payments ?? [];

  const handleRowClicked = (row: Payment) => {
    navigate(`${routes.creditApplications}/${row?.creditApplication?.id}`);
  };

  const columns = getPaymentsColumns(handleRowClicked);

  return (
    <SmartTable
      title="Payments"
      amountOfRows={amountOfRows}
      rows={rows}
      columns={columns}
      filterFields={paymentsFilterFields}
      loading={loadingOrReFetching}
      pageCount={data?.listPayments?.pagination?.totalPages}
      initialState={initialTableState}
      enableDateRangeFilter
    />
  );
};
