import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { createRoot } from 'react-dom/client';

import App from './App';
import './index.css';
import { ApolloProviderWrapper } from './providers/Apollo';

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <BrowserRouter>
      <ApolloProviderWrapper>
        <App />
      </ApolloProviderWrapper>
    </BrowserRouter>
  </StrictMode>,
);
