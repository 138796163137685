import type { MiniStepperStepType } from '@pesto/ui/features/SmartTable/components/MiniStepper/MiniStepper';
import { MiniStepper } from '@pesto/ui/features/SmartTable/components/MiniStepper/MiniStepper';

import type { CreditApplication } from '../../../__generated__/graphql/api';
import { ShippingPreference } from '../../../__generated__/graphql/api';

export const useRenderShippingStatus = () => {
  return (application: CreditApplication) => {
    const { hasUserShipment, needsOutboundShippingKitCreated, needsOutboundShippingKitSent, outboundShippingKitSent } =
      application;

    if (application.shippingPreference === ShippingPreference.ShippingKit)
      return (
        <MiniStepper
          steps={
            [
              {
                description: 'User - Create inbound Shipment',
                complete: hasUserShipment,
              },
              {
                description: 'Admin - Create Outbound Shipping Kit label',
                complete: needsOutboundShippingKitSent,
                attention: needsOutboundShippingKitCreated,
              },
              {
                description: 'Admin - send Outbound Shipping Kit',
                complete: outboundShippingKitSent,
                attention: needsOutboundShippingKitSent,
              },
              {
                description: 'User - Ship package',
                complete: application.hasUserShipmentInTransit,
              },
              {
                description: 'package delivered',
                complete: application.hasUserShipmentDelivered,
              },
            ] as MiniStepperStepType[]
          }
        />
      );

    if (application.shippingPreference === ShippingPreference.Ship)
      return (
        <MiniStepper
          steps={
            [
              {
                description: 'User - Create Shipment',
                complete: hasUserShipment,
              },
              {
                description: 'User - Ship package',
                complete: application.hasUserShipmentInTransit,
              },
              {
                description: 'package delivered',
                complete: application.hasUserShipmentDelivered,
              },
            ] as MiniStepperStepType[]
          }
        />
      );
  };
};
