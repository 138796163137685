import { QRCodeCanvas } from 'qrcode.react';
import React, { FC } from 'react';

import { Modal } from './Modal';
import { Typography } from './Typography';

export const QrCode: FC<{
  data: string;
  size?: number;
  zoomInOnHover?: boolean;
}> = ({ data, size = 140, zoomInOnHover }) => {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <div>
      <button onClick={() => setShowModal(!showModal)}>
        <QRCodeCanvas
          id="qrCode"
          value={data}
          size={size}
          bgColor={'#FFFFFF'}
          level={'Q'}
          className={zoomInOnHover ? 'transition-all duration-300 ease-in-out hover:mr-8 hover:scale-[2]' : ''}
        />
      </button>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <div className={'flex flex-col items-center justify-center gap-6'}>
          <Typography variant={'headerMedium'}>-- Qr Code --</Typography>
          <QRCodeCanvas id="qrCode" value={data} size={200} bgColor={'#FFFFFF'} level={'Q'} />
        </div>
      </Modal>
    </div>
  );
};
