import { useAuth } from '@pesto/features';
import { useUser } from '@pesto/hooks';
import type { ReactNode } from 'react';

import { useCurrentUserQuery } from '../__generated__/graphql/api';

interface AuthManageProviderProps {
  children: ReactNode;
}

export const AuthManageProvider = (props: AuthManageProviderProps) => {
  const { children } = props;
  const { isAuthenticated, signOut } = useAuth();

  useUser({
    queryHook: useCurrentUserQuery,
    isAuthenticated: isAuthenticated,
    signOut: signOut,
  });

  return <>{children}</>;
};
