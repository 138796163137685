import { cn } from '@pesto/utils';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '../Collapsible';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover';
import { Typography } from '../Typography';

import type { ExpandableMenuItem, ExpandableMenuList } from './Navigation.types';
import { NavigationLink } from './NavigationLink';

export interface ExpandableNavProps {
  links: ExpandableMenuList;
  minimized?: boolean;
  onLinkClick?: (href: string) => void;
}
export const ExpandableNav = (props: ExpandableNavProps) => {
  const { links, minimized, onLinkClick } = props;
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const handleLinkClick = (href: string) => {
    onLinkClick && onLinkClick(href);
  };

  const renderMenuItems = (items: ExpandableMenuItem[], isSub?: boolean) => {
    return items.map((item, index) => {
      if (item.type === 'label') {
        return (
          <Typography variant={'bodyLarge'} key={index}>
            {item.label}
          </Typography>
        );
      }
      if (item.type === 'separator') {
        return <hr key={index} />;
      }
      if (item.type === 'item') {
        const current = location.pathname === item?.href;
        return (
          <NavigationLink
            key={index}
            {...item}
            current={current}
            minimized={minimized}
            isSub={isSub}
            onClick={handleLinkClick}
          />
        );
      }
      if (item.type === 'sub') {
        const Icon = item.icon;

        const RootComponent = !minimized ? Collapsible : Popover;
        const TriggerComponent = !minimized ? CollapsibleTrigger : PopoverTrigger;
        const ContentComponent = !minimized ? CollapsibleContent : PopoverContent;

        return (
          <RootComponent
            key={index}
            className={cn('[&[data-state=open]]:bg-muted w-full rounded transition-all')}
            open={open}
            onOpenChange={() => setOpen(!open)}
          >
            <TriggerComponent
              className={cn(
                'hover:text-primary text-muted-foreground flex items-center gap-4 rounded-xl px-3 py-2 transition-all sm:gap-3 sm:rounded-lg [&[data-state=open]>#chevron]:rotate-90',
              )}
            >
              {Icon && <Icon className="h-4 w-4" />}

              {!minimized && (
                <>
                  {item.label}
                  <ChevronRightIcon id="chevron" className="h-5 w-5 transition-transform" />
                </>
              )}
            </TriggerComponent>
            <ContentComponent className={'flex flex-col gap-2 pl-8'} onMouseLeave={() => minimized && setOpen(false)}>
              {renderMenuItems(item.items, true)}
            </ContentComponent>
          </RootComponent>
        );
      }
      return null;
    });
  };

  return <div className={'flex flex-col gap-2'}>{renderMenuItems(links)}</div>;
};
