import { useListQuery } from '@pesto/hooks';
import { SmartTable } from '@pesto/ui/features/SmartTable/SmartTable';
import { useNavigate } from 'react-router-dom';

import { useListStatementsQuery, type Statement } from '../../__generated__/graphql/api';
import { routes } from '../../constants/routes';

import { useStatementsListConfig } from './hooks/useStatementsListConfig';

export const Statments = () => {
  const navigate = useNavigate();
  const { data, loadingOrReFetching } = useListQuery({
    queryHook: useListStatementsQuery,
  });
  const { filterFields, getColumns, initialTableState } = useStatementsListConfig();
  const rows = data?.listStatements?.statements ?? [];
  const amountOfRows = data?.listStatements?.pagination.totalRecords;

  const handleRowClicked = (row: Statement) => {
    navigate(`${routes.statements}/${row?.id}`);
  };

  const columns = getColumns(handleRowClicked);

  return (
    <SmartTable
      title="Statements"
      amountOfRows={amountOfRows}
      rows={rows}
      columns={columns}
      filterFields={filterFields}
      loading={loadingOrReFetching}
      pageCount={data?.listStatements?.pagination?.totalPages}
      initialState={initialTableState}
    />
  );
};
