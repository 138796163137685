import { acceptedKeyValueCheck, cn, formatApplicationValues, humanize, splitCamelCaseToString } from '@pesto/utils';
import type { FC } from 'react';
import { useCallback } from 'react';

interface DataListProps {
  root?: object;
  span?: string;
  spaced?: boolean;
  separator?: boolean;
  keepKeys?: boolean;
  itemClassName?: string;
  noDataText?: string;
  containerClassName?: string;
  className?: string;
}

export const DataList: FC<DataListProps> = ({
  root,
  spaced = false,
  separator = false,
  itemClassName,
  containerClassName,
  keepKeys,
  className,
  noDataText = '-',
}) => {
  const wrapperStyle = cn('flex flex-col gap-2', className);
  const renderProperties = useCallback(() => {
    const properties: JSX.Element[] = [];

    const itemStyle = cn(itemClassName, {
      'border-b': separator,
    });
    const containerStyle = cn('text-foreground/60 font-medium', containerClassName, {
      'flex gap-4 justify-between': spaced,
    });

    root &&
      Object.entries(root)?.map(([key, value]) => {
        if (keepKeys) {
          return properties.push(
            <div key={key} className={itemStyle}>
              <div className={containerStyle}>
                {humanize(splitCamelCaseToString(key) ?? '')}
                <div className={'text-foreground'}>{formatApplicationValues(key, value) ?? noDataText}</div>
              </div>
            </div>,
          );
        }

        const checkAcceptedKeyValue = acceptedKeyValueCheck(key, value);
        const isObject = typeof value === 'object';
        const isArray = Array.isArray(value);

        if (checkAcceptedKeyValue || isArray) return null;
        if (isObject) return properties.push(<DataList key={key} root={value} />);

        return properties.push(
          <div key={key} className={itemStyle}>
            <div className={containerStyle}>
              {humanize(splitCamelCaseToString(key) ?? '')}
              <div className={'text-foreground'}>{formatApplicationValues(key, value) ?? noDataText}</div>
            </div>
          </div>,
        );
      });

    return properties;
  }, [root, spaced, itemClassName, containerClassName, separator, keepKeys, noDataText]);

  return <div className={wrapperStyle}>{renderProperties()}</div>;
};
