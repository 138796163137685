import type { OperationVariables, QueryHookOptions, QueryResult } from '@apollo/client';
import { parseFiltersFromSearchParams } from '@pesto/ui/features/SmartTable/utils/searchParamsFiltersHelper';
import { isNetworkRequestInFlight } from '@pesto/utils';
import { useSearchParams } from 'react-router-dom';

export type UseListQueryOptions<TData, TVariables extends OperationVariables> = {
  queryHook: (options: QueryHookOptions<TData, TVariables>) => QueryResult<TData, TVariables>;
};

export const useListQuery = <TData, TVariables extends OperationVariables>({
  queryHook,
}: UseListQueryOptions<TData, TVariables>) => {
  const [searchParams] = useSearchParams();
  const search = parseFiltersFromSearchParams<TVariables>(searchParams);

  const { data, loading, previousData, networkStatus, error } = queryHook({
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      ...search,
    },
  });

  const loadingOrReFetching = isNetworkRequestInFlight({ networkStatus, loading });

  return { data, loading, previousData, loadingOrReFetching, error };
};
