import { useListQuery } from '@pesto/hooks';
import { SmartTable } from '@pesto/ui/features/SmartTable/SmartTable';

import { useListAdTrackingDetailsQuery } from '../../../__generated__/graphql/api';

import { useAdTrackingListConfig } from './hooks/useAdTrackingListConfig';

export const AdTrackingList = () => {
  const { data, loadingOrReFetching } = useListQuery({
    queryHook: useListAdTrackingDetailsQuery,
  });
  const { getPaymentsColumns, paymentsFilterFields, initialTableState, customFilter } = useAdTrackingListConfig();
  const rows = data?.listAdTrackingDetails?.adTrackingDetails ?? [];
  const amountOfRows = data?.listAdTrackingDetails?.pagination.totalRecords;

  const handleRowClicked = () => {
    return null;
  };

  const columns = getPaymentsColumns(handleRowClicked);

  return (
    <SmartTable
      title="Ad Tracking"
      amountOfRows={amountOfRows}
      rows={rows}
      columns={columns}
      filterFields={paymentsFilterFields}
      loading={loadingOrReFetching}
      pageCount={data?.listAdTrackingDetails?.pagination?.totalPages}
      initialState={initialTableState}
      customFilters={customFilter}
      enableDateRangeFilter
    />
  );
};
