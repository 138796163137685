import { Button, Typography } from '@pesto/ui';
import type { DataTableFilterField } from '@pesto/ui/components/DataTable/DataTable.types';
import { DataTableColumnHeader } from '@pesto/ui/components/DataTable/DataTableColumnHeader';
import { BooleanTag } from '@pesto/ui/features/SmartTable/components/BooleanTag';
import {
  generateSelectableFilterOptions,
  setColumnFilterAndSortKey,
} from '@pesto/ui/features/SmartTable/utils/helpers';
import { centsToFormattedDollars, formatDate } from '@pesto/utils';
import { DownloadIcon } from '@radix-ui/react-icons';
import type { ColumnDef, TableState } from '@tanstack/react-table';

import type { Shipment } from '../../../__generated__/graphql/api';
import { CreditApplicationStatus, ShipmentStatus, ShipmentType } from '../../../__generated__/graphql/api';
import { getTrackingLink } from '../../../utils/getTrackingLink';

const columnIdNames = {
  id: setColumnFilterAndSortKey('idSearch', 'idSortDirection'),
  insertedAt: setColumnFilterAndSortKey('', 'insertedAtSortDirection'),
  tracking: setColumnFilterAndSortKey('trackingNumberSearch', 'trackingNumberSortDirection'),
  status: setColumnFilterAndSortKey('statusSelections', 'statusSortDirection'),
  shippingKitSent: setColumnFilterAndSortKey('shippingKitSent', ''),
  type: setColumnFilterAndSortKey('shipmentTypeSelections', 'shipmentTypeSortDirection'),
  insuredValue: setColumnFilterAndSortKey('', 'insuredValueSortDirection'),
  from: 'from',
  to: 'to',
  firstName: setColumnFilterAndSortKey('userFirstNameSearch', 'userFirstNameSortDirection'),
  lastName: setColumnFilterAndSortKey('userLastNameSearch', 'userLastNameSortDirection'),
  label: 'label',
  creditApplicationStatus: setColumnFilterAndSortKey('creditApplicationStatusSelections', ''),
};

export const useShipmentsListConfig = () => {
  const shipmentsFilterFields: DataTableFilterField<any>[] = [
    {
      label: 'Shipment ID',
      value: columnIdNames.id,
      placeholder: 'Shipment ID search',
    },
    {
      label: 'Tracking',
      value: columnIdNames.tracking,
      placeholder: 'Tracking search',
    },
    {
      label: 'Status',
      value: columnIdNames.status,
      options: generateSelectableFilterOptions(ShipmentStatus),
    },
    {
      label: 'Type',
      value: columnIdNames.type,
      options: generateSelectableFilterOptions(ShipmentType),
    },
    {
      label: 'User First Name',
      value: columnIdNames.firstName,
      placeholder: 'First Name',
    },
    {
      label: 'User Last Name',
      value: columnIdNames.lastName,
      placeholder: 'Last Name',
    },
    {
      label: 'Credit Application Status',
      value: columnIdNames.creditApplicationStatus,
      options: generateSelectableFilterOptions(CreditApplicationStatus),
    },
    {
      label: 'Shipping Kit Sent',
      value: columnIdNames.shippingKitSent,
      options: generateSelectableFilterOptions({
        true: true,
        false: false,
      }),
    },
  ];

  const getShipmentsColumns = (handleRowClicked: (row: Shipment) => void): ColumnDef<Shipment>[] => [
    {
      id: 'view',
      header: 'Action',
      accessorKey: 'id',
      enablePinning: true,
      cell: row => (
        <Button onClick={() => handleRowClicked(row.cell.row.original)} variant={'outline'}>
          View
        </Button>
      ),
    },
    {
      id: columnIdNames.id,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Shimpent ID" />,
      accessorFn: row => row.id,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {row.cell.getValue() as string}
        </Typography>
      ),
    },
    {
      id: columnIdNames.insertedAt,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Created" />,
      accessorFn: row => row.insertedAt,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {formatDate(row.cell.getValue() as string)}
        </Typography>
      ),
    },
    {
      id: columnIdNames.status,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
      accessorFn: row => row.status,
      cell: row => row.cell.getValue(),
    },
    {
      id: columnIdNames.type,
      header: 'Type',
      accessorFn: row => row.shipmentType ?? '-',
      cell: row => row.cell.getValue(),
    },
    {
      id: columnIdNames.insuredValue,
      header: 'Insured Value',
      accessorFn: row => row.insuredValue,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {centsToFormattedDollars(row.cell.getValue() as number)}
        </Typography>
      ),
    },
    {
      id: columnIdNames.tracking,
      header: 'Tracking',
      accessorFn: row => row.trackingNumber,
      cell: row => {
        const trackingNumber = row.cell.getValue() as string;
        const shipment = row.row.original;
        const carrierWebsite = getTrackingLink(shipment.carrier, trackingNumber);

        return (
          <Button variant={'link'} onClick={() => window.open(carrierWebsite, '_blank')}>
            {trackingNumber}
          </Button>
        );
      },
    },
    {
      id: columnIdNames.from,
      header: 'From',
      accessorFn: row => row.fromAddress && `${row.fromAddress.city} ${row.fromAddress.state} ${row.fromAddress.zip}`,
      cell: row => row.cell.getValue(),
    },
    {
      id: columnIdNames.to,
      header: 'To',
      accessorFn: row => row.toAddress && `${row.toAddress.city} ${row.toAddress.state} ${row.toAddress.zip}`,
      cell: row => row.cell.getValue(),
    },
    {
      id: columnIdNames.firstName,
      header: 'First Name',
      accessorFn: row => row.user?.firstName,
      cell: row => row.cell.getValue(),
    },
    {
      id: columnIdNames.lastName,
      header: 'Last Name',
      accessorFn: row => row.user?.lastName,
      cell: row => row.cell.getValue(),
    },
    {
      id: columnIdNames.label,
      header: 'Label',
      accessorFn: row => row.labelUrl,
      cell: row => {
        const labelUrl = row.cell.getValue() as string;
        return (
          <Button variant={'outline'} onClick={() => window.open(labelUrl, '_blank')}>
            <DownloadIcon /> Label
          </Button>
        );
      },
    },
    {
      id: columnIdNames.creditApplicationStatus,
      header: 'Credit Application Status',
      accessorFn: row => row.creditApplication?.status,
      cell: row => row.cell.getValue(),
    },
    {
      id: columnIdNames.shippingKitSent,
      header: 'Shipping Kit Sent',
      accessorFn: row => row.shippingKitSent,
      cell: row => <BooleanTag value={row.cell.getValue() as boolean} />,
    },
  ];

  const initialTableState: Partial<TableState> = {
    columnPinning: { left: ['view'] },
    sorting: [{ id: 'insertedAtSortDirection', desc: true }],
  };

  return { shipmentsFilterFields, getShipmentsColumns, initialTableState };
};
