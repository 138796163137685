import { Button, Typography } from '@pesto/ui';
import type { DataTableFilterField } from '@pesto/ui/components/DataTable/DataTable.types';
import { DataTableColumnHeader } from '@pesto/ui/components/DataTable/DataTableColumnHeader';
import { BooleanTag } from '@pesto/ui/features/SmartTable/components/BooleanTag';
import { generateBooleanFilterOptions, setColumnFilterAndSortKey } from '@pesto/ui/features/SmartTable/utils/helpers';
import { centsToFormattedDollars, formatDate, formatPhoneNumber } from '@pesto/utils';
import type { ColumnDef, TableState } from '@tanstack/react-table';

import type { User } from '../../../__generated__/graphql/api';

const columnIdNames = {
  userId: setColumnFilterAndSortKey('idSearch', 'idSortDirection'),
  insertedAt: setColumnFilterAndSortKey('', 'insertedAtSortDirection'),
  firstName: setColumnFilterAndSortKey('firstNameSearch', 'firstNameSortDirection'),
  lastName: setColumnFilterAndSortKey('lastNameSearch', 'lastNameSortDirection'),
  email: setColumnFilterAndSortKey('emailSearch', 'emailSortDirection'),
  phone: setColumnFilterAndSortKey('phoneSearch', 'phoneSortDirection'),
  dob: setColumnFilterAndSortKey('dobSearch', 'dobSortDirection'),
  annualIncome: setColumnFilterAndSortKey('', 'annualIncomeSortDirection'),
  friendsAndFamily: setColumnFilterAndSortKey('isFriendsAndFam', 'friendsAndFamilySortDirection'),
};

export const useUsersListConfig = () => {
  const usersFilterFields: DataTableFilterField<any>[] = [
    {
      label: 'User ID',
      value: columnIdNames.userId,
      placeholder: 'User ID search',
    },
    {
      label: 'First Name',
      value: columnIdNames.firstName,
      placeholder: 'First Name',
    },
    {
      label: 'Last Name',
      value: columnIdNames.lastName,
      placeholder: 'Last Name',
    },
    {
      label: 'Phone',
      value: columnIdNames.phone,
      placeholder: 'Phone',
    },
    {
      label: 'Friends and Family',
      value: columnIdNames.friendsAndFamily,
      options: generateBooleanFilterOptions(),
    },
  ];

  const getUsersColumns = (handleRowClicked: (row: User) => void): ColumnDef<User>[] => [
    {
      id: 'view',
      header: 'Action',
      accessorKey: 'id',
      enablePinning: true,
      cell: row => (
        <Button onClick={() => handleRowClicked(row.cell.row.original)} variant={'outline'}>
          View
        </Button>
      ),
    },
    {
      id: columnIdNames.userId,
      header: ({ column }) => <DataTableColumnHeader column={column} title="User ID" />,
      accessorFn: row => row.id,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {row.cell.getValue() as string}
        </Typography>
      ),
    },
    {
      id: columnIdNames.insertedAt,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Created" />,
      accessorFn: row => row.insertedAt,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {formatDate(row.cell.getValue() as string)}
        </Typography>
      ),
    },
    {
      id: columnIdNames.firstName,
      header: 'First Name',
      accessorFn: row => row.firstName,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {row.cell.getValue() as string}
        </Typography>
      ),
    },
    {
      id: columnIdNames.lastName,
      header: 'Last Name',
      accessorFn: row => row.lastName,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {row.cell.getValue() as string}
        </Typography>
      ),
    },
    {
      id: columnIdNames.email,
      header: 'Email',
      accessorFn: row => row.email,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {row.cell.getValue() as string}
        </Typography>
      ),
    },
    {
      id: columnIdNames.phone,
      header: 'Phone',
      accessorFn: row => row.phone,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {formatPhoneNumber(row.cell.getValue() as string)}
        </Typography>
      ),
    },
    {
      id: columnIdNames.dob,
      header: 'DOB',
      accessorFn: row => row.dob,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {row.cell.getValue() as string}
        </Typography>
      ),
    },
    {
      id: columnIdNames.annualIncome,
      header: 'Annual Income',
      accessorFn: row => row.annualIncome,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {`${centsToFormattedDollars(row.cell.getValue() as number) || 'N/A'}`}
        </Typography>
      ),
    },
    {
      id: columnIdNames.friendsAndFamily,
      header: 'Friends and Family',
      accessorFn: row => row.isFriendsAndFam,
      cell: row => <BooleanTag value={row.cell.getValue() as boolean} />,
    },
    {
      id: 'cardholder',
      header: 'Cardholder',
      accessorFn: row => row.isCardholder,
      cell: row => <BooleanTag value={row.cell.getValue() as boolean} />,
    },
  ];

  const initialTableState: Partial<TableState> = {
    columnPinning: { left: ['view'] },
    sorting: [{ id: 'insertedAtSortDirection', desc: true }],
  };

  return { usersFilterFields, getUsersColumns, initialTableState };
};
