import { Button, Typography } from '@pesto/ui';
import type { DataTableFilterField } from '@pesto/ui/components/DataTable/DataTable.types';
import { DataTableColumnHeader } from '@pesto/ui/components/DataTable/DataTableColumnHeader';
import {
  generateSelectableFilterOptions,
  setColumnFilterAndSortKey,
} from '@pesto/ui/features/SmartTable/utils/helpers';
import { centsToFormattedDollars, formatDate } from '@pesto/utils';
import type { ColumnDef, TableState } from '@tanstack/react-table';

import type { Payment } from '../../../__generated__/graphql/api';
import { CreditApplicationStatus, PaymentPreference } from '../../../__generated__/graphql/api';

const columnIdNames = {
  id: 'paymentId',
  insertedAt: setColumnFilterAndSortKey('', 'insertedAtSortDirection'),
  amount: setColumnFilterAndSortKey('', 'amountSortDirection'),
  feeAmount: setColumnFilterAndSortKey('', 'feeAmountSortDirection'),
  lastFour: setColumnFilterAndSortKey('', 'lastFourSortDirection'),
  expDate: setColumnFilterAndSortKey('', 'expDateSortDirection'),
  firstName: setColumnFilterAndSortKey('userFirstNameSearch', 'userFirstNameSortDirection'),
  lastName: setColumnFilterAndSortKey('userLastNameSearch', 'userLastNameSortDirection'),
  paymentPreference: setColumnFilterAndSortKey('creditApplicationPaymentPreferenceSelections', ''),
  status: setColumnFilterAndSortKey('creditApplicationStatusSelections', ''),
  creditAppId: 'creditAppId',
};

export const usePaymentsListConfig = () => {
  const paymentsFilterFields: DataTableFilterField<any>[] = [
    {
      label: 'First Name Search',
      value: columnIdNames.firstName,
      placeholder: 'First Name',
    },

    {
      label: 'Last Name Search',
      value: columnIdNames.lastName,
      placeholder: 'Last Name',
    },
    {
      label: 'Payment Preference',
      value: columnIdNames.paymentPreference,
      options: generateSelectableFilterOptions(PaymentPreference),
    },
    {
      label: 'Credit App Status',
      value: columnIdNames.status,
      options: generateSelectableFilterOptions(CreditApplicationStatus),
    },
    {
      label: 'Custom Filters',
      value: 'customFilters',
      options: generateSelectableFilterOptions({
        'Display first payments only': 'firstOf',
      }),
      isCustomCheckboxFilter: true,
    },
  ];

  const getPaymentsColumns = (handleRowClicked: (row: Payment) => void): ColumnDef<Payment>[] => [
    {
      id: 'view',
      header: 'Action',
      accessorKey: 'id',
      enablePinning: true,
      cell: row => (
        <Button onClick={() => handleRowClicked(row.cell.row.original)} variant={'outline'}>
          Go To Credit App
        </Button>
      ),
    },
    {
      id: columnIdNames.id,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Payment ID" />,
      accessorFn: row => row.id,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.insertedAt,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Created" />,
      accessorFn: row => row.insertedAt,
      cell: row => <Typography variant={'body'}>{formatDate(row.cell.getValue() as string)}</Typography>,
    },
    {
      id: columnIdNames.amount,
      header: 'Amount',
      accessorFn: row => row.amount,
      cell: row => <Typography variant={'body'}>{centsToFormattedDollars(row.cell.getValue() as number)}</Typography>,
    },
    {
      id: columnIdNames.feeAmount,
      header: 'Fee Amount',
      accessorFn: row => row.feeAmount,
      cell: row => <Typography variant={'body'}>{centsToFormattedDollars(row.cell.getValue() as number)}</Typography>,
    },
    {
      id: columnIdNames.lastFour,
      header: 'Last Four',
      accessorFn: row => row.lastFour,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.expDate,
      header: 'Exp Date',
      accessorFn: row => row.expDate,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.firstName,
      header: 'First Name',
      accessorFn: row => row.user?.firstName,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.lastName,
      header: 'Last Name',
      accessorFn: row => row.user?.lastName,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.paymentPreference,
      header: 'Payment Preference',
      accessorFn: row => row.creditApplication?.paymentPreference,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.status,
      header: 'Status',
      accessorFn: row => row.creditApplication?.status,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.creditAppId,
      header: 'Credit Application ID',
      accessorFn: row => row.creditApplication?.id,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
  ];

  const initialTableState: Partial<TableState> = {
    columnPinning: { left: ['view'] },
    columnVisibility: {
      [columnIdNames.id]: false,
    },
    sorting: [{ id: 'insertedAtSortDirection', desc: true }],
  };

  return { paymentsFilterFields, getPaymentsColumns, initialTableState };
};
