import { Button, Typography } from '@pesto/ui';
import type { DataTableFilterField } from '@pesto/ui/components/DataTable/DataTable.types';
import { DataTableColumnHeader } from '@pesto/ui/components/DataTable/DataTableColumnHeader';
import {
  generateSelectableFilterOptions,
  setColumnFilterAndSortKey,
} from '@pesto/ui/features/SmartTable/utils/helpers';
import { centsToFormattedDollars, formatDate } from '@pesto/utils';
import { MONEY_MULTIPLIER } from '@pesto/utils/constants/pestoConstants';
import type { ColumnDef, TableState } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

import type { Transaction } from '../../../__generated__/graphql/api';
import { routes } from '../../../constants/routes';

const columnIdNames = {
  insertedAt: setColumnFilterAndSortKey('', 'insertedAtSortDirection'),
  amount: setColumnFilterAndSortKey('amountSearch', 'amountSortDirection'),
  status: setColumnFilterAndSortKey('statusSearch', 'statusSortDirection'),
  confirmation: setColumnFilterAndSortKey('confirmationSearch', 'confirmationSortDirection'),
  firstName: setColumnFilterAndSortKey('userFirstNameSearch', 'userFirstNameSortDirection'),
  lastName: setColumnFilterAndSortKey('userLastNameSearch', 'userLastNameSortDirection'),
  message: setColumnFilterAndSortKey('messageSearch', 'messageSortDirection'),
  bin: setColumnFilterAndSortKey('binSearch', 'binSortDirection'),
  clientMessage: setColumnFilterAndSortKey('clientMessageSearch', 'clientMessageSortDirection'),
  avsCode: setColumnFilterAndSortKey('avsCodeSearch', 'avsCodeSortDirection'),
  batchNumber: setColumnFilterAndSortKey('batchNumberSearch', 'batchNumberSortDirection'),
  paymentType: setColumnFilterAndSortKey('paymentTypeSearch', 'paymentTypeSortDirection'),
  creditApplicationId: 'creditApplicationId',
};

export const useTransactionsListConfig = () => {
  const navigate = useNavigate();

  const transactionsFilterFields: DataTableFilterField<any>[] = [
    {
      label: 'Amount',
      value: columnIdNames.amount,
      placeholder: 'Amount search',
    },
    {
      label: 'Status',
      value: columnIdNames.status,
      placeholder: 'Status search',
    },
    {
      label: 'Confirmation',
      value: columnIdNames.confirmation,
      placeholder: 'Confirmation search',
    },
    {
      label: 'First Name',
      value: columnIdNames.firstName,
      placeholder: 'First Name search',
    },
    {
      label: 'Last Name',
      value: columnIdNames.lastName,
      placeholder: 'Last Name search',
    },
    {
      label: 'Message',
      value: columnIdNames.message,
      placeholder: 'Message search',
    },
    {
      label: 'Bin',
      value: columnIdNames.bin,
      placeholder: 'Bin search',
    },
    {
      label: 'Client Message',
      value: columnIdNames.clientMessage,
      placeholder: 'Client Message search',
    },
    {
      label: 'AVS Code',
      value: columnIdNames.avsCode,
      placeholder: 'AVS Code search',
    },
    {
      label: 'Batch Number',
      value: columnIdNames.batchNumber,
      placeholder: 'Batch Number search',
    },
    {
      label: 'Payment Type',
      value: columnIdNames.paymentType,
      placeholder: 'Payment Type search',
    },
    {
      label: 'Credit Application ID',
      value: columnIdNames.creditApplicationId,
      placeholder: 'Credit Application ID search',
    },
    {
      label: 'Custom Filters',
      value: 'customFilters',
      options: generateSelectableFilterOptions({
        'Display first payments only': 'firstOf',
      }),
      isCustomCheckboxFilter: true,
    },
  ];

  const getTransactionsColumns = (handleRowClicked: (row: Transaction) => void): ColumnDef<Transaction>[] => [
    {
      id: 'view',
      header: 'Action',
      accessorKey: 'id',
      enablePinning: true,
      cell: row => (
        <Button onClick={() => handleRowClicked(row.cell.row.original)} variant={'outline'}>
          View
        </Button>
      ),
    },
    {
      id: columnIdNames.insertedAt,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Created" />,
      accessorFn: row => row.insertedAt,
      cell: row => <Typography variant={'body'}>{formatDate(row.cell.getValue() as string)}</Typography>,
    },
    {
      id: columnIdNames.amount,
      header: 'Amount',
      accessorFn: row => row.amount,
      cell: row => (
        <Typography variant={'body'}>
          {centsToFormattedDollars((row.cell.getValue() as number) * MONEY_MULTIPLIER)}
        </Typography>
      ),
    },
    {
      id: columnIdNames.status,
      header: 'Status',
      accessorFn: row => row.status,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.confirmation,
      header: 'Confirmation',
      accessorFn: row => row.confirmation,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.firstName,
      header: 'First Name',
      accessorFn: row => row.user?.firstName,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.lastName,
      header: 'Last Name',
      accessorFn: row => row.user?.lastName,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.message,
      header: 'Message',
      accessorFn: row => row.message,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.bin,
      header: 'Bin',
      accessorFn: row => row.bin,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.clientMessage,
      header: 'Client Message',
      accessorFn: row => row.clientMessage,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.avsCode,
      header: 'AVS Code',
      accessorFn: row => row.avsCode,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.batchNumber,
      header: 'Batch Number',
      accessorFn: row => row.batchNumber,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.paymentType,
      header: 'Payment Type',
      accessorFn: row => row.paymentType,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.creditApplicationId,
      header: 'Credit Application ID',
      accessorFn: row => row.creditApplication?.id,
      cell: row => {
        const creditAppId = row.cell.getValue() as string;
        return (
          <Button variant={'link'} onClick={() => navigate(`${routes.creditApplications}/${creditAppId}`)}>
            {creditAppId}
          </Button>
        );
      },
    },
  ];

  const initialTableState: Partial<TableState> = {
    columnPinning: { left: ['view'] },
    sorting: [{ id: 'insertedAtSortDirection', desc: true }],
  };

  return { transactionsFilterFields, getTransactionsColumns, initialTableState };
};
