import { PaperClipIcon } from '@heroicons/react/20/solid';
import { Button, Card, Typography } from '@pesto/ui';

interface ReportLink {
  name: string;
  link: string;
}

const reportLinks: ReportLink[] = [
  {
    name: 'Security report',
    link: 'https://docs.google.com/spreadsheets/d/18Bah49nKw90v-Y-8cyfEGsT0eypROiBRL309nvTJVVg',
  },
  {
    name: 'Ads campaigns performance',
    link: 'https://lookerstudio.google.com/u/3/reporting/5533d2a8-8c46-4467-88d0-3243513768ed/page/A03mD',
  },
  {
    name: 'Ads and payments',
    link: 'https://docs.google.com/spreadsheets/d/1QV8171XXRS_AZ-wyT0_3nJrJ8I1kc5ylb716ndqbMXM',
  },
  {
    name: 'Shipments',
    link: 'https://docs.google.com/spreadsheets/d/1FtqIUf30HnMsi64t1BTqqbzVETad4GVAaTlADABaCjs',
  },
  {
    name: 'Ad tracking and shipments',
    link: 'https://docs.google.com/spreadsheets/d/1tslxfoC9gQpbaV7FDrZLw_hCyrk_uTFJRip92OpRt8Y',
  },
  {
    name: 'Ad tracking and item good status',
    link: 'https://docs.google.com/spreadsheets/d/1a2RU-iFgKwKZmMPp2Xgj9uAfMgM8bpZZaJta0Epmu-o',
  },
  {
    name: 'Adverse Action Email',
    link: 'https://docs.google.com/spreadsheets/d/1O4ggVMhTxIXLtAEgkQSv2pxWkoTwuIzsSe6MGNlf744',
  },
  {
    name: 'Incomplete Application Email',
    link: 'https://docs.google.com/spreadsheets/d/1Xiu-r_PcF3WJuS-c8syjMD3gV9nkQ-JGJH_VW69EvyA',
  },
];

export const ReportLink = (props: ReportLink) => {
  const { name, link } = props;
  return (
    <Card key={name + 'files-legal'} className="bg-primary-foreground overflow-hidden">
      <div className="flex flex-col justify-between gap-4 px-4 py-5 sm:flex-row sm:items-center sm:p-6">
        <div className="flex gap-2">
          <PaperClipIcon className="text-primary h-5 w-5 flex-shrink-0" aria-hidden="true" />
          <a href={link} target={'_blank'} className="hover:text-primary/50 text-primary truncate text-sm font-medium">
            {name}
          </a>
        </div>
        <div className="flex gap-2">
          <div className="text-primary mt-1 text-sm">
            <Button color={'secondary'} onClick={() => window.open(link, '_blank')}>
              View
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 px-4 pb-5">
        <div className="text-primary truncate text-sm">{name}</div>
        <div className="text-primary text-sm">{link}</div>
      </div>
    </Card>
  );
};

export const Reports = () => {
  return (
    <div className="flex flex-col gap-5 p-5">
      <div className={'flex flex-col gap-4'}>
        <Typography variant={'headerMedium'}>Reports</Typography>
        <div className="mx-auto w-full max-w-4xl">
          <dl className="flex flex-col gap-4">
            {reportLinks.map((link, index) => {
              return <ReportLink name={link.name} link={link.link} key={index + link.link} />;
            })}
          </dl>
        </div>
      </div>
    </div>
  );
};
