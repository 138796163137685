import { cn } from '@pesto/utils';
import { ChevronLeft } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { ExpandableNav } from './ExpandableNav';
import type { NavigationProps } from './Navigation.types';

export const DesktopNavigation = (props: NavigationProps) => {
  const { links, homeLink } = props;
  const [isMinimized, setIsMinimized] = useState(false);
  const toggle = () => setIsMinimized(!isMinimized);

  const handleToggle = () => {
    toggle();
  };

  return (
    <aside
      className={cn(
        `bg-muted/40 relative hidden w-full flex-none flex-col border-r transition-[width] duration-500 md:flex`,
        !isMinimized ? 'w-72' : 'w-[72px]',
      )}
    >
      <div className={'sticky top-0 flex flex-col'}>
        <div className="hidden p-5 pt-10 lg:block">
          <Link to={homeLink.href} className="flex items-center gap-2 font-semibold">
            <homeLink.icon className="h-6 w-6" />
            {!isMinimized && <span className="">{homeLink.name}</span>}
          </Link>
        </div>
        <ChevronLeft
          className={cn(
            'bg-background text-foreground absolute -right-3 top-10 z-50 cursor-pointer rounded-full border text-3xl',
            isMinimized && 'rotate-180',
          )}
          onClick={handleToggle}
        />
        <div className="flex h-full flex-col justify-between gap-2 p-4">
          <div className="flex h-full w-full flex-1">
            <nav className="grid w-full items-start text-sm font-medium">
              <ExpandableNav links={links} minimized={isMinimized} />
            </nav>
          </div>
        </div>
      </div>
    </aside>
  );
};
