import { Typography } from '@pesto/ui';
import { humanize } from '@pesto/utils';
import { format } from 'date-fns';
import React from 'react';

import type { AdminActionRecord } from '../../../__generated__/graphql/api';

interface AdminActionRecordsContentProps {
  application: any;
}

export const AdminActionRecordsContent: React.FC<AdminActionRecordsContentProps> = ({ application }) => {
  return (
    <div className={'space-y-4'}>
      <Typography variant="headerMedium">Admin Actions</Typography>

      <div className="grid grid-cols-2 gap-6">
        {application?.adminActionRecords?.map((adminActionRecords: AdminActionRecord, index: number) => (
          <>
            <div className="w-full text-left text-sm">
              <p>
                <strong>Action:</strong> #{index + 1}
              </p>
              <p>
                <strong>Action:</strong> {humanize(adminActionRecords?.type ?? '')}
              </p>
              <p>
                <strong>By:</strong> {adminActionRecords?.actor?.displayName}
              </p>
              <p>
                <strong>Notes:</strong> {adminActionRecords?.description}
              </p>
              <p>
                <strong>Date:</strong> {format(new Date(adminActionRecords?.insertedAt), 'MM-dd-yyyy HH:mm')}
              </p>
            </div>
            <div className={'bg-basicSoft mb-4 h-1 max-w-full'} />
          </>
        ))}
      </div>
    </div>
  );
};
