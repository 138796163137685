export const LEGAL_LINK = 'https://files.getpesto.com/legal/';
export const legalLinks = [
  {
    name: 'Cardholder Agreement',
    fileName: 'Pesto-Consumer-Cardholder-Agreement.pdf',
  },
  {
    name: 'Disclosure of Rates & Fees, Item Security Agreement',
    fileName: 'Pesto-Disclosure-of-Rates-Fees-and-Costs-plus-Security-Agreement-Item.pdf',
  },
  {
    name: 'Disclosure of Rates & Fees, Cash Security Agreement',
    fileName: 'Pesto-Disclosure-of-Rates-Fees-and-Costs-plus-Security-Agreement-Cash.pdf',
  },
  {
    name: 'Disclosure of Rates, Fees, and Other Cost Information',
    fileName: 'Pesto-Disclosure-of-Rates-Fees-and-other-Cost-Information.pdf',
  },
  {
    name: 'E-Sign Disclosure',
    fileName: 'Pesto-E-Sign-Disclosure-and-Consent-to-Use-Electronic-Records-and-Signatures.pdf',
  },
  {
    name: 'Patriot Act Notice',
    fileName: 'Pesto-Patriot-Act-Notice.pdf',
  },
  {
    name: 'Preparing for Shipment',
    fileName: 'Pesto-Preparing-your-Valuables-for-Shipment.pdf',
  },
  {
    name: 'Privacy Notice',
    fileName: 'Pesto-Privacy-Notice.pdf',
  },
  {
    name: 'Privacy Policy',
    fileName: 'Pesto-Privacy-Policy.pdf',
  },
  {
    name: 'TCPA Notice',
    fileName: 'Pesto-Telephone-Consumer-Protection-Act-Notice.pdf',
  },
  {
    name: 'Terms of Service',
    fileName: 'Pesto-Terms-of-Service.pdf',
  },
  {
    name: 'Cash Security Agrement',
    fileName: 'Security-Agreement-Cash.pdf',
  },
  {
    name: 'Item Security Agreement',
    fileName: 'Security-Agreement-Item.pdf',
  },
  {
    name: 'TILA',
    fileName: 'TILA-Disclosures-Account-Opening.pdf',
  },
  {
    name: 'Rewards Program Terms and Conditions',
    fileName: 'Pesto-Mastercard-Rewards-Program-Terms-and-Conditions.pdf',
  },
];
