import type { EffectCallback } from 'react';
import { useEffect, useRef } from 'react';

/**
 * ```ts
 * Custom useEffect hook, which is invoked only once (on mount)
 * ```
 */
export function useOnMount(effect: EffectCallback) {
  useEffect(effect, []); // eslint-disable-line react-hooks/exhaustive-deps
}

/**
 * ```ts
 * UNSAFE - use only if you are sure that the effect will not change
 * Custom useEffect hook, which is invoked only once (on mount) even in Strict Mode
 * https://taig.medium.com/prevent-react-from-triggering-useeffect-twice-307a475714d7
 * ```
 * @param effect
 */
export function useUnsafeOnMount(effect: EffectCallback) {
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      effect();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
