import { useListQuery } from '@pesto/hooks';
import { SmartTable } from '@pesto/ui/features/SmartTable/SmartTable';
import { useNavigate } from 'react-router-dom';

import { useListCardsQuery, type Card } from '../../__generated__/graphql/api';
import { routes } from '../../constants/routes';

import { useCardsListConfig } from './hooks/useCardsListConfig';

export const Cards = () => {
  const navigate = useNavigate();
  const { data, loadingOrReFetching } = useListQuery({
    queryHook: useListCardsQuery,
  });
  const { cardsFilterFields, getCardsColumns, initialTableState } = useCardsListConfig();
  const amountOfRows = data?.listCards?.pagination.totalRecords;

  const rows = data?.listCards?.cards ?? [];

  const handleRowClicked = (row: Card) => {
    navigate(`${routes.cards}/${row?.id}`);
  };
  const columns = getCardsColumns(handleRowClicked);

  return (
    <SmartTable
      title="Cards"
      amountOfRows={amountOfRows}
      rows={rows}
      columns={columns}
      filterFields={cardsFilterFields}
      loading={loadingOrReFetching}
      pageCount={data?.listCards?.pagination?.totalPages}
      initialState={initialTableState}
      enableDateRangeFilter
    />
  );
};
