import { Button, Typography } from '@pesto/ui';
import type { StatBoxProps } from '@pesto/ui/components/Dashboard/StatBox';
import StatBox from '@pesto/ui/components/Dashboard/StatBox';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useQueryString } from '../../../../../packages/ui/features/SmartTable/hooks/useQueryString';
import {
  CreditApplicationStatus,
  PaymentPreference,
  SecurityType,
  ShipmentStatus,
  ShipmentType,
} from '../../__generated__/graphql/api';
import { SHIPPING_STATUSES_EXCLUDING_UNKNOWN } from '../../constants/filters';
import { routes } from '../../constants/routes';

import { openPaymentsStatuses, useDashboardData } from './hooks/useDashboardData';

export const Dashboard = () => {
  const navigate = useNavigate();
  const {
    offerRejected,
    offerDecisionNeeded,
    prequalifiedStats,
    inProgressStats,
    completeStats,
    reviewNeededStats,
    manualStatsItems,
    manualStatsCash,
    shippingKits,
    payments,
    payNow,
    payOverTime,
    dataUserTransit,
    dataDelivered,
    dataReceived,
    cardsIssuedCount,
    verifyDoc,
    verifyPayment,
  } = useDashboardData();

  const manualReviewAItemCount = manualStatsItems?.listCreditApplications?.pagination?.totalRecords ?? 0;
  const manualReviewACashCount = manualStatsCash?.listCreditApplications?.pagination?.totalRecords ?? 0;
  const reviewNeededReviewApplicationsCount = reviewNeededStats?.listCreditApplications?.pagination?.totalRecords ?? 0;
  const completeStatsCount = completeStats?.listCreditApplications?.pagination?.totalRecords ?? 0;
  const inProgressStatsCount = inProgressStats?.listCreditApplications?.pagination?.totalRecords ?? 0;
  const prequalifiedStatsCount = prequalifiedStats?.listCreditApplications?.pagination?.totalRecords ?? 0;
  const offerDecisionNeededCount = offerDecisionNeeded?.listCreditApplications?.pagination?.totalRecords ?? 0;
  const offerRejectedCount = offerRejected?.listCreditApplications?.pagination?.totalRecords ?? 0;
  const verifyDocCount = verifyDoc?.listCreditApplications?.pagination?.totalRecords ?? 0;
  const verifyPaymentCount = verifyPayment?.listCreditApplications?.pagination?.totalRecords ?? 0;

  const totalPayments = payments?.listCreditApplications?.pagination.totalRecords ?? 0;
  const payNowOpen = payNow?.listCreditApplications?.pagination.totalRecords ?? 0;
  const payOverTimeOpen = payOverTime?.listCreditApplications?.pagination.totalRecords ?? 0;

  const userShippedCount = dataUserTransit?.listShipments?.pagination?.totalRecords ?? 0;
  const shipDeliveredCount = dataDelivered?.listShipments?.pagination?.totalRecords ?? 0;
  const shipReceivedCount = dataReceived?.listShipments?.pagination?.totalRecords ?? 0;
  const shipShippingKitCount = shippingKits?.listShipments?.pagination?.totalRecords ?? 0;
  const [searchParams] = useSearchParams();
  const { createQueryString } = useQueryString(searchParams);
  const constructURL = (filters: Record<string, string | number | null | any>) => {
    return createQueryString(filters);
  };

  const cardsIssuedSearchFilter = constructURL({
    userIsFriendsAndFam: false,
    active: true,
  });

  const cardholdersSearchFilter = constructURL({
    userIsFriendsAndFam: false,
    statusSelections: [CreditApplicationStatus.Complete],
  });

  const beingAppraisedSearchFilter = constructURL({
    statusSelections: [CreditApplicationStatus.AppraisalInProgress],
    userIsFriendsAndFam: false,
  });

  const prequalifiedSearchFilter = constructURL({
    statusSelections: [CreditApplicationStatus.Prequalified],
    userIsFriendsAndFam: false,
  });

  const offerDecisionNeededSearchFilter = constructURL({
    statusSelections: [CreditApplicationStatus.OfferDecisionNeeded],
    userIsFriendsAndFam: false,
  });

  const manualReviewSearchFilter = constructURL({
    statusSelections: [CreditApplicationStatus.ManualReview],
    securityTypeSelections: [SecurityType.Item],
    userIsFriendsAndFam: false,
  });
  const manualReviewCashSearchFilter = constructURL({
    statusSelections: [CreditApplicationStatus.ManualReview],
    securityTypeSelections: [SecurityType.Cash],
    userIsFriendsAndFam: false,
  });

  const itemReviewSearchFilter = constructURL({
    statusSelections: [CreditApplicationStatus.ItemUnderReview],
    userIsFriendsAndFam: false,
  });
  const offerRejectedSearchFilter = constructURL({
    statusSelections: [CreditApplicationStatus.OfferRejected],
    userIsFriendsAndFam: false,
  });
  const verifyDocSearchFilter = constructURL({
    statusSelections: [CreditApplicationStatus.VerifyDoc],
    userIsFriendsAndFam: false,
  });
  const verifyPaymentSearchFilter = constructURL({
    statusSelections: [CreditApplicationStatus.VerifyPayment],
    userIsFriendsAndFam: false,
  });

  const totalOpenPaymentsSearchFilter = constructURL({
    statusSelections: openPaymentsStatuses,
    securityTypeSelections: [SecurityType.Cash],
    userIsFriendsAndFam: false,
  });
  const payNowOpenSearchFilter = constructURL({
    statusSelections: openPaymentsStatuses,
    securityTypeSelections: [SecurityType.Cash],
    userIsFriendsAndFam: false,
    paymentPreferenceSelections: [PaymentPreference.PayInFull],
  });
  const payOverTimeOpenSearchFilter = constructURL({
    statusSelections: openPaymentsStatuses,
    securityTypeSelections: [SecurityType.Cash],
    userIsFriendsAndFam: false,
    paymentPreferenceSelections: [PaymentPreference.PayOverTime],
  });

  const applicationStats: StatBoxProps[] = [
    {
      title: cardsIssuedCount?.toString(),
      subtitle: 'Cards Issued',
      onClick: () =>
        navigate({
          pathname: routes.cards,
          search: cardsIssuedSearchFilter,
        }),
    },
    {
      title: completeStatsCount?.toString(),
      subtitle: 'Credit Issued',
      onClick: () =>
        navigate({
          pathname: routes.creditApplications,
          search: cardholdersSearchFilter,
        }),
    },
    {
      title: inProgressStatsCount?.toString(),
      subtitle: 'Being appraised',
      onClick: () =>
        navigate({
          pathname: routes.creditApplications,
          search: beingAppraisedSearchFilter,
        }),
    },
    {
      title: prequalifiedStatsCount?.toString(),
      subtitle: 'Prequalified',
      onClick: () =>
        navigate({
          pathname: routes.creditApplications,
          search: prequalifiedSearchFilter,
        }),
    },
    {
      title: offerDecisionNeededCount?.toString(),
      subtitle: 'Offer Sent',
      onClick: () =>
        navigate({
          pathname: routes.creditApplications,
          search: offerDecisionNeededSearchFilter,
        }),
    },
    {
      title: offerRejectedCount?.toString(),
      subtitle: 'Offers Rejected',
      onClick: () =>
        navigate({
          pathname: routes.creditApplications,
          search: offerRejectedSearchFilter,
        }),
    },
    {
      title: verifyDocCount?.toString(),
      subtitle: 'Verify Documents',
      onClick: () =>
        navigate({
          pathname: routes.creditApplications,
          search: verifyDocSearchFilter,
        }),
    },
    {
      title: verifyPaymentCount?.toString(),
      subtitle: 'Verify Payment',
      onClick: () =>
        navigate({
          pathname: routes.creditApplications,
          search: verifyPaymentSearchFilter,
        }),
    },
  ];

  const inReviewStats: StatBoxProps[] = [
    {
      title: reviewNeededReviewApplicationsCount?.toString(),
      subtitle: 'Item Review',
      onClick: () =>
        navigate({
          pathname: routes.creditApplications,
          search: itemReviewSearchFilter,
        }),
    },
    {
      title: manualReviewAItemCount?.toString(),
      subtitle: 'Manual Review - Items',
      onClick: () =>
        navigate({
          pathname: routes.creditApplications,
          search: manualReviewSearchFilter,
        }),
    },

    {
      title: manualReviewACashCount?.toString(),
      subtitle: 'Manual review - Cash',
      onClick: () => {
        navigate({
          pathname: routes.creditApplications,
          search: manualReviewCashSearchFilter,
        });
      },
    },
  ];

  const paymentsStats: StatBoxProps[] = [
    {
      title: totalPayments?.toString(),
      subtitle: 'Total open payments',
      onClick: () => {
        navigate({
          pathname: routes.creditApplications,
          search: totalOpenPaymentsSearchFilter,
        });
      },
    },
    {
      title: payNowOpen?.toString(),
      subtitle: 'Paynow',
      onClick: () => {
        navigate({
          pathname: routes.creditApplications,
          search: payNowOpenSearchFilter,
        });
      },
    },
    {
      title: payOverTimeOpen?.toString(),
      subtitle: 'Pay over time',
      onClick: () => {
        navigate({
          pathname: routes.creditApplications,
          search: payOverTimeOpenSearchFilter,
        });
      },
    },
  ];

  const shipmentsStats: StatBoxProps[] = [
    {
      title: shipShippingKitCount?.toString(),
      subtitle: 'Inbound Ship. Kits',
      onClick: () => {
        navigate(
          `/shipments?shipmentTypeSelections[arr]=${ShipmentType.InboundShippingKit}&shippingKitSent[bool]=false&statusSelections[arr]=${SHIPPING_STATUSES_EXCLUDING_UNKNOWN}`,
        );
      },
    },
    {
      title: userShippedCount?.toString(),
      subtitle: 'In Transit (Fedex, Ups)',
      onClick: () => {
        navigate(
          `/shipments?statusSelections[arr]=${ShipmentStatus.Transit}&shipmentTypeSelections[arr]=${ShipmentType.InboundShippingKit},${ShipmentType.UserShipping},${ShipmentType.Diy}`,
        );
      },
    },
    {
      title: shipDeliveredCount?.toString(),
      subtitle: 'Delivered',
      onClick: () => {
        navigate(`/shipments?statusSelections[arr]=${ShipmentStatus.Delivered}`);
      },
    },
    {
      title: shipReceivedCount?.toString(),
      subtitle: 'Received',
      onClick: () => {
        navigate(`/shipments?statusSelections[arr]=${ShipmentStatus.Received}`);
      },
    },
  ];

  return (
    <>
      <div className="flex flex-col gap-5">
        <div>
          <Typography variant={'headerSmall'}>Marketing Stats</Typography>
          <dl className="mt-5 grid grid-cols-1 gap-3 sm:grid-cols-4">
            <Button
              onClick={() => {
                navigate(routes.lookerStudio);
              }}
              variant={'outline'}
            >
              Marketing Campaign Stats
            </Button>
          </dl>
        </div>
        <div>
          <Typography variant={'headerSmall'}>Applications</Typography>

          <dl className="mt-5 grid grid-cols-1 gap-3 sm:grid-cols-4">
            {applicationStats.map((stat, index) => {
              return (
                <StatBox
                  key={stat.subtitle + index}
                  title={stat.title}
                  subtitle={stat.subtitle}
                  onClick={stat.onClick}
                />
              );
            })}
          </dl>
        </div>

        <div>
          <Typography variant={'headerSmall'}>Needs review</Typography>

          <dl className="mt-5 grid grid-cols-1 gap-3 sm:grid-cols-4">
            {inReviewStats.map((stat, index) => {
              return (
                <StatBox
                  key={stat.subtitle + index}
                  title={stat.title}
                  subtitle={stat.subtitle}
                  onClick={stat.onClick}
                />
              );
            })}
          </dl>
        </div>
        <div>
          <Typography variant={'headerSmall'}>Payments</Typography>

          <dl className="mt-5 grid grid-cols-1 gap-3 sm:grid-cols-4">
            {paymentsStats.map((stat, index) => {
              return (
                <StatBox
                  key={stat.subtitle + index}
                  title={stat.title}
                  subtitle={stat.subtitle}
                  onClick={stat.onClick}
                />
              );
            })}
          </dl>
        </div>
        <div>
          <Typography variant={'headerSmall'}>Shipments</Typography>
          <dl className="mt-5 grid grid-cols-1 gap-3 sm:grid-cols-4">
            {shipmentsStats.map((stat, index) => {
              return (
                <StatBox
                  key={stat.subtitle + index}
                  title={stat.title}
                  subtitle={stat.subtitle}
                  onClick={stat.onClick}
                />
              );
            })}
          </dl>
        </div>
      </div>
    </>
  );
};
