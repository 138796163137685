import { DialogDescription } from '@radix-ui/react-dialog';
import type { ReactNode } from 'react';

import type { ButtonProps } from './Button';
import { Button } from './Button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from './Dialog';
import { LoadingSpinner } from './LoadingSpinner';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: ReactNode;
  description?: string;
  loading?: boolean;
  onConfirm?: () => void;
  confirmProps?: ButtonProps;
  confirmText?: string;
  className?: string;
}

export const Modal = (props: ModalProps) => {
  const {
    isOpen = false,
    title,
    description,
    loading = false,
    onClose,
    className,
    onConfirm,
    children,
    confirmProps,
  } = props;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className={className}>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {children}
            {onConfirm && (
              <DialogFooter>
                <Button variant={'outline'} onClick={onClose}>
                  Cancel
                </Button>
                <Button onClick={onConfirm} {...confirmProps}>
                  Confirm
                </Button>
              </DialogFooter>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
