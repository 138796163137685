import { cn } from '@pesto/utils';
import type { ReactNode } from 'react';

interface MainWrapperProps {
  children: ReactNode;
  className?: string;
}
export const MainWrapper = (props: MainWrapperProps) => {
  const { children, className } = props;
  const rootStyle = cn('mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8', className);
  return (
    <main>
      <div className={rootStyle}>{children}</div>
    </main>
  );
};
