import { useListQuery } from '@pesto/hooks';
import { SmartTable } from '@pesto/ui/features/SmartTable/SmartTable';
import { useNavigate } from 'react-router-dom';

import type { User } from '../../__generated__/graphql/api';
import { useListUsersQuery } from '../../__generated__/graphql/api';
import { routes } from '../../constants/routes';

import { useUsersListConfig } from './hooks/useUsersListConfig';

export const Users = () => {
  const navigate = useNavigate();
  const { data, loadingOrReFetching } = useListQuery({
    queryHook: useListUsersQuery,
  });
  const { usersFilterFields, getUsersColumns, initialTableState } = useUsersListConfig();
  const amountOfRows = data?.listUsers?.pagination.totalRecords;

  const rows = data?.listUsers?.users ?? [];

  const handleRowClicked = (row: User) => {
    navigate(`${routes.users}/${row?.id}`);
  };
  const columns = getUsersColumns(handleRowClicked);

  return (
    <SmartTable
      title="Users"
      amountOfRows={amountOfRows}
      rows={rows}
      columns={columns}
      filterFields={usersFilterFields}
      loading={loadingOrReFetching}
      pageCount={data?.listUsers?.pagination?.totalPages}
      initialState={initialTableState}
      enableDateRangeFilter
    />
  );
};
