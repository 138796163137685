import type { JsonObject } from '../object/dataHelper';

export const parseJsonAndRemoveNulls = (jsonString: string | undefined | null) => {
  if (!jsonString) return {};
  const parsedJson: JsonObject = JSON.parse(jsonString);
  if (parsedJson === null) return {};
  return Object.keys(parsedJson).reduce((acc: JsonObject, key) => {
    if (parsedJson[key] !== '') {
      acc[key] = parsedJson[key];
    }
    return acc;
  }, {});
};
