import { cn } from '@pesto/utils';

export type TextAlign = 'left' | 'center' | 'right';
export type InputSize = 'small' | 'big';

export interface InputStyleProps {
  className?: string;
  isError: boolean;
  readOnly?: boolean;
  inputSize?: InputSize;
  textAlign?: TextAlign;
}

export const generateInputStyles = ({
  className = '',
  isError,
  readOnly,
  inputSize = 'small',
  textAlign = 'left',
}: InputStyleProps) => {
  const align: Record<TextAlign, string> = {
    left: 'text-left',
    center: 'text-center',
    right: 'text-right',
  };

  return cn(
    'border-input bg-background ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring flex h-10 w-full rounded-md border px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
    {
      'border-destructive border': isError,
      'border-0 bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 disabled:cursor-default disabled:opacity-100 px-0':
        readOnly,
      'text-base': inputSize === 'small',
      'text-3xl': inputSize === 'big',
    },
    align[textAlign],
    className,
  );
};
