import { cn } from '@pesto/utils';
import { Moon, Sun } from 'lucide-react';
import type { FC } from 'react';

import { useTheme } from '../providers/ThemeProvider';

import { Button } from './Button';

interface ThemeToggleProps {
  className?: string;
}

export const ThemeToggle: FC<ThemeToggleProps> = props => {
  const { className } = props;
  const { setTheme, theme } = useTheme();

  return (
    <Button
      variant="ghost"
      className={className}
      onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
      onlyIcon
    >
      <Sun
        className={cn('h-[1.5rem] w-[1.3rem]', {
          hidden: theme === 'dark',
        })}
      />
      <Moon
        className={cn('h-5 w-5', {
          hidden: theme === 'light',
        })}
      />
      <span className="sr-only">Toggle theme</span>
    </Button>
  );
};
