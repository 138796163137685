import { cn } from '@pesto/utils';
import { TrashIcon } from 'lucide-react';
import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from '../../../../components/Button';
import type { DataTableFilterOption } from '../../../../components/DataTable/DataTable.types';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../components/Popover';
import type { QueryParams } from '../../hooks/useQueryString';
import { useQueryString } from '../../hooks/useQueryString';

import { DataTableAdvancedFacetedFilter } from './DataTableAdvancedFacetedFilter';

interface DataTableFilterItemProps<TData> {
  selectedOption: DataTableFilterOption<TData>;
  selectedOptions: DataTableFilterOption<TData>[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<DataTableFilterOption<TData>[]>>;
  defaultOpen: boolean;
}

export function CustomFilterItem<TData>({
  selectedOption,
  selectedOptions,
  setSelectedOptions,
  defaultOpen,
}: DataTableFilterItemProps<TData>) {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [searchParams] = useSearchParams();

  const { createQueryString } = useQueryString(searchParams);

  const selectedValues = new Set(selectedOptions.find(item => item.value === selectedOption.value)?.filterValues);

  const [open, setOpen] = React.useState(defaultOpen);

  const optionsList = selectedOption.options;

  const handleUrlUpdate = (valuesObject: QueryParams | null) => {
    if (valuesObject === null) {
      const newSearchParams = createQueryString({});
      navigate(`${pathname}?${newSearchParams}`, { replace: true });
      return;
    }
    const newSearchParams = createQueryString(valuesObject);
    navigate(`${pathname}?${newSearchParams}`, { replace: true });
  };

  const handleSetSelectedOptions = (option: any, index: number, isSelected: boolean) => {
    if (isSelected) {
      selectedValues.delete(String(option.value));
    } else {
      selectedValues.add(String(option.value));
    }
    const filterValues = Array.from(selectedValues);
    const valuesObject = optionsList.reduce((acc, item) => {
      if (filterValues.includes(String(item.value))) {
        acc[String(item.value)] = 'true';
      } else {
        acc[String(item.value)] = null;
      }
      return acc;
    }, {} as QueryParams);

    setSelectedOptions(prev =>
      prev.map(item =>
        item.value === selectedOption.value
          ? {
              ...item,
              filterValues,
            }
          : item,
      ),
    );

    handleUrlUpdate(valuesObject);
  };

  const handleClearFilter = () => {
    handleUrlUpdate(null);
    setSelectedOptions(prev =>
      prev.map(item =>
        item.value === selectedOption.value
          ? {
              ...item,
              filterValues: [],
            }
          : item,
      ),
    );
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className={cn('h-8 gap-0 truncate rounded-md', selectedValues.size > 0 && 'bg-muted/50')}
        >
          <span className="font-medium capitalize">{selectedOption.label}</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-60 space-y-1.5 p-2" align="start">
        <div className="flex items-center space-x-1 pl-1 pr-0.5">
          <div className="flex flex-1 items-center space-x-1">
            <div className="text-muted-foreground text-xs capitalize">{selectedOption.label}</div>
          </div>
          <Button
            aria-label="Remove filter"
            variant="ghost"
            onlyIcon
            className="text-muted-foreground size-7"
            onClick={() => {
              setSelectedOptions(prev => prev.filter(item => item.value !== selectedOption.value));
              handleUrlUpdate(null);
            }}
          >
            <TrashIcon className="size-4" aria-hidden="true" />
          </Button>
        </div>

        <DataTableAdvancedFacetedFilter
          key={String(selectedOption.value)}
          title={selectedOption.label}
          options={optionsList}
          selectedValues={selectedValues}
          onSelectValue={handleSetSelectedOptions}
          onClear={handleClearFilter}
        />
      </PopoverContent>
    </Popover>
  );
}
