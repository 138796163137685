import { cn, isEqual } from '@pesto/utils';
import { Cross1Icon } from '@radix-ui/react-icons';
import { addDays, format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import type { FC } from 'react';
import * as React from 'react';
import type { DateRange } from 'react-day-picker';

import { Button } from './Button';
import { Calendar } from './Calendar';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';
import { Typography } from './Typography';

interface PresetOption {
  label: string;
  value: number;
}

interface DateRangePickerProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  dateValue?: DateRange;
  onDateChange?: (date: DateRange | undefined) => void;
  enablePresetOptions?: boolean;
}

const defaultDateRange: DateRange = {
  from: undefined,
  to: undefined,
};

export const DateRangePicker: FC<DateRangePickerProps> = ({
  className,
  onDateChange,
  enablePresetOptions,
  dateValue = defaultDateRange,
}) => {
  const [date, setDate] = React.useState<DateRange | undefined>(dateValue);

  const dateIsDefault = isEqual(date, defaultDateRange);

  const options: PresetOption[] = [
    { label: 'last week', value: 7 },
    { label: 'last 2 weeks', value: 14 },
    { label: 'last month', value: 30 },
    { label: 'last 3 months', value: 90 },
  ];

  const handleDateChange = (date: DateRange | undefined) => {
    setDate(date);
    onDateChange?.(date);
  };

  const resetDate = () => {
    setDate(defaultDateRange);
    onDateChange?.(undefined);
  };

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <div className="flex">
          <PopoverTrigger asChild>
            <Button
              id="date"
              variant={'outline'}
              size="sm"
              className={cn('justify-start text-left font-normal sm:w-[300px]', !date && 'text-muted-foreground', {
                'rounded-r-none': !dateIsDefault,
              })}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {date?.from ? (
                date.to ? (
                  <>
                    {format(date.from, 'LLL dd, y')} - {format(date.to, 'LLL dd, y')}
                  </>
                ) : (
                  format(date.from, 'LLL dd, y')
                )
              ) : (
                <span>Pick a date</span>
              )}
            </Button>
          </PopoverTrigger>
          <Button
            onClick={() => resetDate()}
            size="sm"
            color={'secondary'}
            className={cn('rounded-l-none border border-l-0', {
              hidden: dateIsDefault,
            })}
          >
            <Cross1Icon className={'h-4 w-4'} />
          </Button>
        </div>
        <PopoverContent className="w-auto p-0" align="start">
          {enablePresetOptions && (
            <div className="flex items-center gap-2 p-4">
              <Typography variant={'body'}>Select</Typography>
              {options.map(option => (
                <Button
                  key={option.value}
                  className={'h-7 w-auto rounded-full'}
                  color={'secondary'}
                  size={'sm'}
                  onClick={() =>
                    handleDateChange({
                      from: addDays(new Date(), -option.value),
                      to: new Date(),
                    })
                  }
                >
                  {option.label}
                </Button>
              ))}
            </div>
          )}
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={handleDateChange}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};
