import { useAuth } from '@pesto/features';
import { Button, Typography } from '@pesto/ui';
import { TriangleAlert } from 'lucide-react';
import { Navigate } from 'react-router-dom';

import { routes } from '../constants/routes';

interface ErrorPageProps {
  error?: string;
}

const ErrorPage = (props: ErrorPageProps) => {
  const { error } = props;
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to={routes.login} replace />;
  }

  return (
    <div className="flex w-full flex-col items-center justify-center gap-4 p-[20px] text-center sm:gap-8">
      <Typography variant="heroSmall" className={'text-6xl'}>
        Whoops! An error occurred.
      </Typography>

      <TriangleAlert className="h-[240px] w-[240px] text-yellow-500" />
      <div>
        <Typography variant="headerSmall" className={'text-6xl'}>
          Error:
        </Typography>

        <Typography variant="body" className="max-w-lg leading-8">
          Something went wrong. Please reload the page or contact support if the issue continues.
        </Typography>
        <Typography variant="body" className="max-w-lg leading-8">
          Error: {error || 'Unknown'}
        </Typography>
      </div>

      <Button size="lg" onClick={() => window.location.reload()}>
        Reload Page
      </Button>
    </div>
  );
};

export default ErrorPage;
