import { Card, Typography } from '@pesto/ui';

import { LinkRow } from './components/LinkRow';
import { legalLinks } from './constants/legalLinks';

export const LegalLinks = () => {
  return (
    <div className="flex flex-col gap-5 p-5">
      <div className={'flex flex-col gap-4'}>
        <Typography variant={'headerMedium'}>Legal Links</Typography>

        <div className="mx-auto flex w-full max-w-4xl flex-col gap-6">
          <Card className="rounded-lg bg-amber-50 p-4 text-sm text-black">
            <Typography>
              <span className="font-medium">Instructions:</span> Click on the "Upload New" button to upload a new file.
              You can only upload one file at a time. Once you upload a new file, the old file will be replaced.
            </Typography>
          </Card>
          <dl className="flex flex-col gap-4">
            {legalLinks.map((link, index) => {
              return <LinkRow name={link.name} fileName={link.fileName} key={index + link.fileName} />;
            })}
          </dl>
        </div>
      </div>
    </div>
  );
};
