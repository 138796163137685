import { BanknotesIcon, CurrencyDollarIcon, NoSymbolIcon } from '@heroicons/react/20/solid';
import { Card, Typography } from '@pesto/ui';
import { cn, formatDate, humanize } from '@pesto/utils';
import { BoltIcon, CheckCircleIcon, PlusIcon, StarIcon } from 'lucide-react';

import type { ItemEvent, Maybe } from '../../../__generated__/graphql/api';
import { ItemEventType } from '../../../__generated__/graphql/api';

interface ItemEventsHistoryProps {
  itemEvents: Maybe<ItemEvent>[];
}

const ItemEventTypeIcon = {
  [ItemEventType.QuickAppraisal]: BoltIcon,
  [ItemEventType.FinalAppraisal]: PlusIcon,
  [ItemEventType.CreditOverride]: CurrencyDollarIcon,
  [ItemEventType.ValuationUpdate]: BanknotesIcon,
  [ItemEventType.ItemApproved]: CheckCircleIcon,
  [ItemEventType.ItemRejected]: NoSymbolIcon,

  default: StarIcon,
};

export const ItemEventsHistory = (props: ItemEventsHistoryProps) => {
  const { itemEvents } = props;
  const reversedItemEvents = itemEvents?.slice().reverse();
  return (
    <Card className="bg-primary-foreground text-primary flow-root p-6">
      <Typography variant="headerSmall" className="mb-4">
        Item Events History
      </Typography>
      <ul role="list" className="-mb-8 flex flex-col gap-2">
        {reversedItemEvents.map((event, eventIdx) => {
          const EventIcon = ItemEventTypeIcon[event?.itemEventType || 'default'];
          if (!event) return null;
          return (
            <li key={event.id} className="h-auto w-full">
              <div className="relative pb-8">
                {eventIdx !== reversedItemEvents.length - 1 ? (
                  <span className="bg-primary/20 absolute left-4 top-4 -ml-px h-full w-0.5" aria-hidden="true" />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={cn(
                        'bg-primary ring-primary-foreground flex h-8 w-8 items-center justify-center rounded-full ring-8',
                      )}
                    >
                      <EventIcon className="text-primary-foreground h-5 w-5" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                    <div className="flex flex-col gap-2">
                      <p className="text-sm">{humanize(event.itemEventType || '')} </p>
                      <p className="text-sm">{event.description} </p>
                      <p className="text-sm">{event.details} </p>
                      <p className="text-sm">{event.actor?.displayName}</p>
                    </div>
                    <div className="whitespace-nowrap text-right text-sm">
                      <time dateTime={event.insertedAt}>{formatDate(event.insertedAt)}</time>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </Card>
  );
};
