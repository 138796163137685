export const routes = {
  login: '/',
  loginCallback: '/login-callback',
  dashboard: '/dashboard',
  shipments: '/shipments',
  creditApplications: '/credit-applications',
  payments: '/payments',
  users: '/users',
  statements: '/statements',
  securityEvents: '/security-events',
  cards: '/cards',
  manualReview: '/manual-review',
  creditEngine: '/credit-engine',
  items: '/items',
  manageCollateralItems: '/manage-collateral-items',
  addCollateralItems: '/add-collateral-items',
  addJewelry: '/add-jewelry',
  addMetal: '/add-metal',
  addDiamond: '/add-diamond',
  addOtherItem: '/add-other',
  addFinalAppraisal: '/add-final-appraisal',
  profile: '/profile',
  settings: '/settings',
  itemDetail: '/item-detail',
  checkAppraisal: '/check-appraisal',
  legalLinks: '/legal-links',
  reports: '/reports',
  lookerStudio: '/looker-studio',
  successful: '/successful',
  transactions: '/transactions',
  adTracking: '/ad-tracking',
};
