import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@pesto/ui';
import { CircleUser } from 'lucide-react';

import type { ExpandableMenuList, ExpandableMenuProps } from './Navigation.types';

export const UserNavDropdown = ({ menuItems }: ExpandableMenuProps) => {
  const renderMenuItems = (items: ExpandableMenuList) => {
    return items.map((item, index) => {
      if (item.type === 'label') {
        return <DropdownMenuLabel key={index}>{item.label}</DropdownMenuLabel>;
      }
      if (item.type === 'separator') {
        return <DropdownMenuSeparator key={index} />;
      }
      if (item.type === 'item') {
        return (
          <DropdownMenuItem key={index} onClick={item.onClick}>
            {item.label}
          </DropdownMenuItem>
        );
      }
      if (item.type === 'sub') {
        return (
          <DropdownMenuSub key={index}>
            <DropdownMenuSubTrigger>{item.label}</DropdownMenuSubTrigger>
            <DropdownMenuSubContent>{renderMenuItems(item.items)}</DropdownMenuSubContent>
          </DropdownMenuSub>
        );
      }
      return null;
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button color="secondary" onlyIcon className="rounded-full">
          <CircleUser className="h-5 w-5" />
          <span className="sr-only">Toggle user menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">{renderMenuItems(menuItems)}</DropdownMenuContent>
    </DropdownMenu>
  );
};
