import { ArrowLeftIcon } from '@radix-ui/react-icons';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from './Button';

interface BackButtonProps {
  onClick?: () => void;
  className?: string;
  children?: ReactNode;
}

export const BackButton = (props: BackButtonProps) => {
  const { onClick, className, children } = props;
  const navigate = useNavigate();
  const rootStyle = clsx('w-auto flex gap-2 self-start', className);
  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(-1);
    }
  };
  return (
    <Button variant={'ghost'} className={rootStyle} onClick={handleClick}>
      <ArrowLeftIcon />
      {children ?? 'Back'}
    </Button>
  );
};
