import { Button, Typography } from '@pesto/ui';
import { DetailRow } from '@pesto/ui/components/DetailRow';
import { BooleanTag } from '@pesto/ui/features/SmartTable/components/BooleanTag';
import { formatDate } from '@pesto/utils';
import { MONEY_MULTIPLIER } from '@pesto/utils/constants/pestoConstants';
import type { ColumnDef } from '@tanstack/react-table';
import { ChevronDown, ChevronRight, Minus } from 'lucide-react';

import type {
  CreditApplication,
  Item,
  Maybe,
  PaymentCheck,
  ScheduledPayment,
} from '../../../__generated__/graphql/api';
import { renderDetails } from '../utils/renderPaymentDetails';

export const useCashCardConfig = (item?: Item) => {
  const transactions = item?.creditApplication?.transactions ?? [];

  const paymentsList = item?.creditApplication?.payments?.map(payment => {
    const subRows =
      payment?.transactions?.map(transaction => {
        if (!transaction) return null;

        const additionalDetails = JSON.parse(transaction?.additionalDetails || 'null');
        const detailsData = {
          Message: transaction?.message ?? '-',
          'Client Message': transaction?.clientMessage ?? '-',
          'Client Status': transaction?.clientStatus ?? '-',
          Bin: transaction?.bin ?? '-',
          'Last Four': transaction?.lastFour ?? '-',
          'Exp Date': transaction?.expDate ?? '-',
          'Card Brand': transaction?.cardBrand ?? '-',
          Details: renderDetails(transaction?.details ?? ''),
          'Refund Confirmation': transaction?.refundConfirmation,
          'Auth Code': transaction?.authCode,
          'Avs Code': transaction?.avsCode,
          'Batch Number': transaction?.batchNumber,
          'Additional Details': <pre>{JSON.stringify(additionalDetails, null, 2)}</pre>,
        };

        return {
          ...transaction,
          amount: Number(transaction?.amount) * MONEY_MULTIPLIER,
          children: transaction && [
            <div className="flex flex-col gap-4 p-4">
              <Typography variant={'headerSmall'}>Additional Details</Typography>
              <div className="grid grid-cols-3 border-b">
                {Object.keys(detailsData).map(key => {
                  return <DetailRow key={key} title={key} value={detailsData[key as keyof typeof detailsData]} />;
                })}
              </div>
            </div>,
          ],
        };
      }) || [];

    return {
      ...payment,
      children: payment?.transactions?.length && payment?.transactions?.length > 1 ? subRows : null,
    };
  });

  const getPaymentsColumns = (): ColumnDef<CreditApplication>[] => [
    {
      header: ' ',
      cell: ({ row }) => (
        <div
          style={{
            paddingLeft: `${row.depth * 2}rem`,
          }}
        >
          {row.getCanExpand() ? (
            <Button variant={'ghost'} onClick={row.getToggleExpandedHandler()} style={{ cursor: 'pointer' }}>
              {row.getIsExpanded() ? <ChevronDown /> : <ChevronRight />}
            </Button>
          ) : (
            row.getParentRow() && (
              <Button variant={'ghost'}>
                <Minus />
              </Button>
            )
          )}
        </div>
      ),
    },
    {
      id: 'hasSplit',
      header: 'Has Split',
      accessorKey: 'transactions',
      cell: row => {
        const value = row.cell.getValue() as any[];
        return BooleanTag({
          value: Array.isArray(value) && value.length > 1,
        });
      },
    },
    {
      id: 'insertedAt',
      accessorKey: 'insertedAt',
      header: 'Created',
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {formatDate(row.cell.getValue() as string)}
        </Typography>
      ),
    },
    {
      id: 'amount',
      header: 'Amount',
      accessorKey: 'amount',
      cell: row => <Typography variant={'body'}>{`$${(row.cell.getValue() as number) / MONEY_MULTIPLIER}`}</Typography>,
    },
    {
      id: 'lastFour',
      header: 'Last Four',
      accessorKey: 'lastFour',
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: 'expDate',
      header: 'Exp Date',
      accessorKey: 'expDate',
      cell: row => (
        <Typography variant={'body'}>
          {typeof row.cell?.getValue() === 'string'
            ? (row.cell?.getValue() as string).replace(/(\d{2})(\d{2})/, '$1/$2')
            : ''}
        </Typography>
      ),
    },
    {
      id: 'feeAmount',
      header: 'Fee Amount',
      accessorKey: 'feeAmount',
      cell: row => <Typography variant={'body'}>{`$${(row.cell.getValue() as number) / MONEY_MULTIPLIER}`}</Typography>,
    },
  ];

  const failedPaymentsList = transactions
    .filter(payment => payment?.status !== 'success')
    .map(filedPayment => {
      const additionalDetails = JSON.parse(filedPayment?.additionalDetails || 'null');
      const detailsData = {
        Message: filedPayment?.message ?? '-',
        'Client Message': filedPayment?.clientMessage ?? '-',
        'Client Status': filedPayment?.clientStatus ?? '-',
        Bin: filedPayment?.bin ?? '-',
        'Last Four': filedPayment?.lastFour ?? '-',
        'Exp Date': filedPayment?.expDate ?? '-',
        'Card Brand': filedPayment?.cardBrand ?? '-',
        Details: renderDetails(filedPayment?.details ?? ''),
        'Refund Confirmation': filedPayment?.refundConfirmation,
        'Auth Code': filedPayment?.authCode,
        'Avs Code': filedPayment?.avsCode,
        'Batch Number': filedPayment?.batchNumber,
        'Additional Details': <pre>{JSON.stringify(additionalDetails, null, 2)}</pre>,
      };

      return {
        ...filedPayment,
        children: filedPayment && [
          <div className="flex flex-col gap-4 p-4">
            <Typography variant={'headerSmall'}>Additional Details</Typography>
            <div className="grid grid-cols-3 border-b">
              {Object.keys(detailsData).map(key => {
                return <DetailRow key={key} title={key} value={detailsData[key as keyof typeof detailsData]} />;
              })}
            </div>
          </div>,
        ],
      };
    });

  const getFailedPaymentsColumns = (): ColumnDef<CreditApplication>[] => [
    {
      header: ' ',
      cell: ({ row }) => (
        <div
          style={{
            paddingLeft: `${row.depth * 2}rem`,
          }}
        >
          {row.getCanExpand() ? (
            <Button variant={'ghost'} onClick={row.getToggleExpandedHandler()} style={{ cursor: 'pointer' }}>
              {row.getIsExpanded() ? <ChevronDown /> : <ChevronRight />}
            </Button>
          ) : (
            row.getParentRow() && (
              <Button variant={'ghost'}>
                <Minus />
              </Button>
            )
          )}
        </div>
      ),
    },
    {
      id: 'insertedAt',
      header: 'Created',
      accessorKey: 'insertedAt',
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {formatDate(row.cell.getValue() as string)}
        </Typography>
      ),
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: 'amount',
      header: 'Amount',
      accessorKey: 'amount',
      cell: row => <Typography variant={'body'}>{`$${(row.cell.getValue() as number) / MONEY_MULTIPLIER}`}</Typography>,
    },
    {
      id: 'confirmation',
      header: 'Confirmation',
      accessorKey: 'confirmation',
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: 'refundConfirmation',
      header: 'Refund Confirmation',
      accessorKey: 'refundConfirmation',
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: 'paymentType',
      header: 'Payment Type',
      accessorKey: 'paymentType',
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: 'lastFour',
      header: 'Last Four',
      accessorKey: 'lastFour',
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: 'expDate',
      header: 'Exp Date',
      accessorKey: 'expDate',
      cell: row => (
        <Typography variant={'body'}>
          {typeof row.cell?.getValue() === 'string'
            ? (row.cell?.getValue() as string).replace(/(\d{2})(\d{2})/, '$1/$2')
            : ''}
        </Typography>
      ),
    },
    {
      id: 'bin',
      header: 'Bin',
      accessorKey: 'bin',
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
  ];

  const paymentChecks = item?.creditApplication?.paymentChecks?.map(paymentCheck => {
    return {
      ...paymentCheck,
      children: paymentCheck?.paymentVerifications?.map(paymentVerification => {
        const detailsData = {
          'Amount Entered': `$${(paymentVerification?.amountEntered ?? 0) / MONEY_MULTIPLIER}`,
          Result: (paymentVerification?.success as boolean)?.toString(),
        };
        return (
          <div className="flex flex-col gap-4 p-4">
            <div className="grid grid-cols-3 border-b">
              {Object.keys(detailsData).map(key => {
                return <DetailRow key={key} title={key} value={detailsData[key as keyof typeof detailsData]} />;
              })}
            </div>
          </div>
        );
      }),
    };
  });
  const getPaymentChecksColumns = (onPaymentCheckClick: (data: PaymentCheck) => void): ColumnDef<PaymentCheck>[] => [
    {
      header: ' ',
      cell: ({ row }) => (
        <div
          style={{
            paddingLeft: `${row.depth * 2}rem`,
          }}
        >
          {row.getCanExpand() ? (
            <Button variant={'ghost'} onClick={row.getToggleExpandedHandler()} style={{ cursor: 'pointer' }}>
              {row.getIsExpanded() ? <ChevronDown /> : <ChevronRight />}
            </Button>
          ) : (
            row.getParentRow() && (
              <Button variant={'ghost'}>
                <Minus />
              </Button>
            )
          )}
        </div>
      ),
    },
    {
      id: 'active',
      header: 'Active',
      accessorKey: 'active',
      cell: row => <BooleanTag value={row.cell.getValue() as boolean} />,
    },
    {
      id: 'reason',
      header: 'Reason',
      accessorKey: 'reason',
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: 'clearPaymentCheck',
      header: 'Clear Payment Check',
      accessorKey: 'clearPaymentCheck',
      cell: row => (
        <Button
          color={'destructive'}
          onClick={() => {
            onPaymentCheckClick(row.cell.row.original);
          }}
        >
          Review Payment Check
        </Button>
      ),
    },
  ];

  // const schedulePaymentColumns = ['Date', 'Status', 'Active', 'Amount', 'Group Id', 'Deleted At'];

  const scheduledPaymentList = item?.creditApplication?.scheduledPayments ?? [];

  const scheduledPaymentStatus = (scheduledPayment: Maybe<ScheduledPayment>) => {
    if (scheduledPayment?.deletedAt) {
      return 'Deleted';
    }
    if (!scheduledPayment?.active && !scheduledPayment?.deletedAt) {
      return 'Paid';
    }
    return 'Scheduled';
  };

  const getScheduledPaymentColumns = (): ColumnDef<CreditApplication>[] => [
    {
      id: 'date',
      header: 'Date',
      accessorKey: 'date',
      cell: row => (
        <Typography variant={'body'} className={'whitespace-nowrap'}>
          {formatDate(row.cell.getValue() as string)}
        </Typography>
      ),
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      cell: row => (
        <Typography variant={'body'}>
          {scheduledPaymentStatus(row.cell.getValue() as Maybe<ScheduledPayment>)}
        </Typography>
      ),
    },
    {
      id: 'active',
      header: 'Active',
      accessorKey: 'active',
      cell: row => <BooleanTag value={row.cell.getValue() as boolean} />,
    },
    {
      id: 'amount',
      header: 'Amount',
      accessorKey: 'amount',
      cell: row => <Typography variant={'body'}>{`$${(row.cell.getValue() as number) / MONEY_MULTIPLIER}`}</Typography>,
    },
    {
      id: 'groupId',
      header: 'Group Id',
      accessorKey: 'groupId',
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: 'deletedAt',
      header: 'Deleted At',
      accessorKey: 'deletedAt',
      cell: row => <Typography variant={'body'}>{formatDate(row.cell.getValue() as string)}</Typography>,
    },
  ];
  return {
    getPaymentsColumns,
    paymentsList,
    getFailedPaymentsColumns,
    failedPaymentsList,
    getPaymentChecksColumns,
    paymentChecks,
    getScheduledPaymentColumns,
    scheduledPaymentList,
  };
};
