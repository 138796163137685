import { format } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';

export const formatDate = (dateStr?: string, formatStr?: string) => {
  formatStr = formatStr || 'd MMMM yyyy HH:mm';

  if (!dateStr) return '';
  const parsedDate = parseISO(dateStr);
  return format(parsedDate, formatStr);
};
