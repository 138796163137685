import { Button, Typography } from '@pesto/ui';
import type { DataTableFilterField } from '@pesto/ui/components/DataTable/DataTable.types';
import { DataTableColumnHeader } from '@pesto/ui/components/DataTable/DataTableColumnHeader';
import {
  generateSelectableFilterOptions,
  setColumnFilterAndSortKey,
} from '@pesto/ui/features/SmartTable/utils/helpers';
import { formatDate } from '@pesto/utils';
import type { ColumnDef, TableState } from '@tanstack/react-table';

import type { Statement } from '../../../__generated__/graphql/api';
import { CreditApplicationStatus } from '../../../__generated__/graphql/api';

const columnIdNames = {
  id: 'statementId',
  insertedAt: setColumnFilterAndSortKey('', 'insertedAtSortDirection'),
  status: setColumnFilterAndSortKey('statusSelections', 'statusSortDirection'),
  firstName: setColumnFilterAndSortKey('userFirstNameSearch', 'userFirstNameSortDirection'),
  lastName: setColumnFilterAndSortKey('userLastNameSearch', 'userLastNameSortDirection'),
  email: setColumnFilterAndSortKey('userEmailSearch', 'userEmailSortDirection'),
  phone: setColumnFilterAndSortKey('userPhoneSearch', 'userPhoneSortDirection'),
};

export const useStatementsListConfig = () => {
  const filterFields: DataTableFilterField<any>[] = [
    {
      label: 'First Name Search',
      value: columnIdNames.firstName,
      placeholder: 'First Name',
    },

    {
      label: 'Last Name Search',
      value: columnIdNames.lastName,
      placeholder: 'Last Name',
    },
    {
      label: 'Email Search',
      value: columnIdNames.email,
      placeholder: 'Email',
    },
    {
      label: 'Phone Search',
      value: columnIdNames.phone,
      placeholder: 'Phone',
    },
    {
      label: 'Status',
      value: columnIdNames.status,
      options: generateSelectableFilterOptions(CreditApplicationStatus),
    },
  ];

  const getColumns = (handleRowClicked: (row: Statement) => void): ColumnDef<Statement>[] => [
    {
      id: 'view',
      header: 'Action',
      accessorKey: 'id',
      enablePinning: true,
      cell: row => (
        <Button onClick={() => handleRowClicked(row.cell.row.original)} variant={'outline'}>
          View
        </Button>
      ),
    },
    {
      id: columnIdNames.id,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Payment ID" />,
      accessorFn: row => row.id,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.insertedAt,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Statement Date" />,
      accessorFn: row => row.insertedAt,
      cell: row => <Typography variant={'body'}>{formatDate(row.cell.getValue() as string)}</Typography>,
    },
    {
      id: columnIdNames.status,
      header: 'Status',
      accessorFn: row => row.status,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.firstName,
      header: 'First Name',
      accessorFn: row => row.user?.firstName,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.lastName,
      header: 'Last Name',
      accessorFn: row => row.user?.lastName,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.email,
      header: 'Email',
      accessorFn: row => row.user?.email,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.phone,
      header: 'Phone',
      accessorFn: row => row.user?.phone,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
  ];

  const initialTableState: Partial<TableState> = {
    columnPinning: { left: ['view'] },
    sorting: [{ id: 'insertedAtSortDirection', desc: true }],
  };

  return { filterFields, getColumns, initialTableState };
};
