import { Button, Typography } from '@pesto/ui';
import { Switch } from '@pesto/ui/components/Switch';
import type { ApplicationData } from '@pesto/ui/features/CreditApplication/components/OverviewDetails';
import { formatDate, humanize } from '@pesto/utils';

import type { GetCreditApplicationByIdQuery } from '../../../__generated__/graphql/api';

import { ApplicationActions } from './useApplicationModal';
import { useCreditApplicationDetails } from './useCreditApplicationDetails';

interface UseApplicationDataProps {
  application?: GetCreditApplicationByIdQuery['creditApplication'];
  setShowModal: (show: boolean) => void;
  setAction: (action: ApplicationActions) => void;
}

export const useApplicationData = ({ application, setShowModal, setAction }: UseApplicationDataProps) => {
  const { applicationActionButtons } = useCreditApplicationDetails();

  const renderActionButtons = applicationActionButtons.map(button => (
    <Button
      key={button.action}
      variant={'outline'}
      onClick={() => {
        setAction(button.action);
        setShowModal(true);
      }}
    >
      {button.label}
    </Button>
  ));

  const getApplicationData = (): ApplicationData[] => [
    {
      title: 'Application',
      keepKeys: true,
      data: {
        id: application?.id,
        'Created At': formatDate(application?.insertedAt),
        'Updated At': formatDate(application?.updatedAt),
        Status: humanize(application?.status || ''),
      },
    },
    {
      title: 'Personal information',
      keepKeys: true,
      data: {
        'First Name': application?.pestoUser?.firstName,
        // 'Middle Name': application?.pestoUser?.middleName,
        'Last Name': application?.pestoUser?.lastName,
        Email: application?.pestoUser?.email,
        'Phone Number': application?.pestoUser?.phone,
        Cardholder: application?.pestoUser?.isCardholder ? (
          <span className="inline-flex items-center rounded-md bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800">
            Yes
          </span>
        ) : (
          <span className="text-destructive inline-flex items-center rounded-md bg-red-100 px-2.5 py-0.5 text-sm font-medium">
            No
          </span>
        ),
      },
    },
    {
      title: 'Total credit estimate',
      keepKeys: true,
      data: {
        'High Credit Estimate': application?.highCreditEstimate,
        'Low Credit Estimate': application?.lowCreditEstimate,
        'Amount of Assets': application?.items?.length,
      },
    },
    {
      title: 'Offer',
      keepKeys: true,
      data: {
        'Calculated offer amount': <Typography variant={'headerMedium'}>{application?.offerAmount}</Typography>,
        Status: application?.creditOffers?.length ? 'Offer Sent' : 'No Offer Sent',
        // 'Send Offer': <div className="flex flex-col gap-2">{}</div>,
      },
    },
    {
      title: 'Quick Actions',
      onlyChildren: true,
      data: (
        <div className="flex flex-col gap-2 px-4 py-4">
          <Typography variant={'body'} className="text-primary/60 font-medium">
            Actions:
          </Typography>
          <div className="flex gap-2">
            <Typography variant={'body'}>Enable Doc Verification</Typography>
            <Switch
              checked={application?.verifyDoc ?? false}
              onCheckedChange={() => {
                setAction(ApplicationActions.TOGGLE_DOC_VERIFICATION);
                setShowModal(true);
              }}
            />
          </div>
          <div className="flex gap-2">
            <Typography variant={'body'}>Enable Payment Verification</Typography>
            <Switch
              checked={application?.verifyPayment ?? false}
              onCheckedChange={() => {
                setAction(ApplicationActions.TOGGLE_PAYMENT_VERIFICATION);
                setShowModal(true);
              }}
            />
          </div>
          {renderActionButtons}
        </div>
      ),
    },
  ];

  return {
    getApplicationData,
  };
};
