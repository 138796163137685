import { Player } from '@lottiefiles/react-lottie-player';
import { useAuth } from '@pesto/features';
import { Button, Card, LoadingSpinner, PhoneInput, Typography } from '@pesto/ui';
import { SixDigitOtp } from '@pesto/ui/components/SixDigitOtp';
import { ThemeToggle } from '@pesto/ui/components/ThemeToggle';
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import logo from '../assets/Logo_Small.svg';

export const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, sendSmsCode, verifySmsCode, codeLoading } = useAuth();
  const handlePhoneSubmit = () => {
    sendSmsCode(phoneNumber)
      .then(() => {
        setIsCodeSent(true);
      })
      .catch((err: { description: string }) => {
        console.error('Error sending passcode:', err);
      });
  };

  const handleVerificationCodeSubmit = () => {
    setIsLoading(true);
    verifySmsCode(phoneNumber, verificationCode)
      .then(() => navigate('/'))
      .catch((err: { description: string }) => {
        setIsLoading(false);
        console.error('Error parsing hash:', err);
      });
  };

  if (isAuthenticated()) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <div className="from-basic to-basicSoft relative flex min-h-screen flex-col items-center justify-center bg-gradient-to-t px-4 py-12 sm:px-6 lg:px-8">
      <ThemeToggle className="absolute right-4 top-4" />
      <Card className={'z-0 p-6'}>
        <div className="flex w-full max-w-md flex-col items-center space-y-8">
          <div className={'flex flex-col items-center gap-2'}>
            <img className="mx-auto h-12 w-auto" src={logo} alt="Your Company" />
            <Player
              autoplay
              speed={1}
              loop
              src="https://assets9.lottiefiles.com/packages/lf20_eeejiiox.json"
              className={'h-auto max-w-[250px]'}
            />
            <Typography variant={'headerLarge'}>Mission Control</Typography>
            <Typography variant={'headerMedium'}>
              {' '}
              {isCodeSent ? 'Enter Verification Code' : 'Sign in to your account'}
            </Typography>
            {isCodeSent && <span className="text-sm text-gray-500">Sent to {phoneNumber}</span>}
            {isCodeSent && (
              <a
                href="#"
                className={'text-action cursor-pointer underline hover:no-underline'}
                onClick={() => setIsCodeSent(false)}
              >
                Reset form
              </a>
            )}
          </div>
          {isLoading || codeLoading ? (
            <LoadingSpinner />
          ) : !isCodeSent ? (
            <form
              className={'flex flex-col gap-4'}
              onSubmit={e => {
                e.preventDefault();
                handlePhoneSubmit();
              }}
            >
              <PhoneInput
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={(value?: string) => setPhoneNumber(value || '')}
              />
              <Button type={'submit'}>Send Code</Button>
            </form>
          ) : (
            <form
              className={'flex flex-col gap-4'}
              onSubmit={e => {
                e.preventDefault();
                handleVerificationCodeSubmit();
              }}
            >
              <SixDigitOtp value={verificationCode} onChange={setVerificationCode} />
              <Button type={'submit'}>Verify Code</Button>
            </form>
          )}
        </div>
      </Card>
    </div>
  );
};
