import { Button, Card, Typography } from '@pesto/ui';
import { DetailRow } from '@pesto/ui/components/DetailRow';
import { Modal } from '@pesto/ui/components/Modal';
import { QrCode } from '@pesto/ui/components/QrCode';
import { SmartTable } from '@pesto/ui/features/SmartTable/SmartTable';
import { humanize, isEmpty } from '@pesto/utils';
import { MONEY_MULTIPLIER } from '@pesto/utils/constants/pestoConstants';
import React, { useState } from 'react';

import type { Item, PaymentCheck } from '../../__generated__/graphql/api';
import { PaymentPreference, useCashSecuredReturnMutation } from '../../__generated__/graphql/api';
import { ApplicationActions } from '../../pages/CreditApplicationDetail/hooks/useApplicationModal';

import { useCashCardConfig } from './hooks/useCashCardConfig';

interface IsOpenConfirm {
  paymentId: string | undefined | null;
  isOpen: boolean;
}

interface CashCardProps {
  item?: Item;
  onRefundSuccess?: () => void;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setAction: React.Dispatch<React.SetStateAction<ApplicationActions | null>>;
  setPaymentId: React.Dispatch<React.SetStateAction<string>>;
}

interface ActiveTabs {
  [key: string]: any;
}

const TAB_NAMES = {
  PAYMENTS: 'payments',
  FAILED_PAYMENTS: 'failed payments',
  PAYMENT_CHECKS: 'payment checks',
  SCHEDULED_PAYMENTS: 'scheduled payments',
} as const;

export const CashCard = (props: CashCardProps) => {
  const { item, onRefundSuccess, setShowModal, setAction, setPaymentId } = props;
  const [, setShowCopied] = useState(false);

  const [activeTab, setActiveTab] = useState<string>(TAB_NAMES.PAYMENTS);
  const [isOpenConfirm, setIsOpenConfirm] = useState<IsOpenConfirm>({
    paymentId: null,
    isOpen: false,
  });

  const [refundPayment] = useCashSecuredReturnMutation();

  const handleRefund = (id: string | undefined | null) => {
    if (!id) return null;

    refundPayment({
      variables: {
        transactionId: id,
      },
    }).then(() => {
      setIsOpenConfirm({
        paymentId: null,
        isOpen: false,
      });
      onRefundSuccess?.();
    });
  };

  const {
    getPaymentsColumns,
    paymentsList,
    getFailedPaymentsColumns,
    failedPaymentsList,
    getPaymentChecksColumns,
    paymentChecks,
    getScheduledPaymentColumns,
    scheduledPaymentList,
  } = useCashCardConfig(item);

  const transactions = item?.creditApplication?.transactions ?? [];

  const isPayOverTime = item?.creditApplication?.paymentPreference === PaymentPreference.PayOverTime;

  const handlePaymentCheckButtonClick = (row: PaymentCheck) => {
    setPaymentId(row.id ?? '');
    setAction(ApplicationActions.REVIEW_PAYMENT_CHECK);
    setShowModal(true);
  };

  const rows: ActiveTabs = {
    [TAB_NAMES.PAYMENTS]: paymentsList,
    [TAB_NAMES.FAILED_PAYMENTS]: failedPaymentsList,
    [TAB_NAMES.PAYMENT_CHECKS]: paymentChecks,
    [TAB_NAMES.SCHEDULED_PAYMENTS]: scheduledPaymentList,
  };

  const columns: ActiveTabs = {
    [TAB_NAMES.PAYMENTS]: getPaymentsColumns(),
    [TAB_NAMES.FAILED_PAYMENTS]: getFailedPaymentsColumns(),
    [TAB_NAMES.PAYMENT_CHECKS]: getPaymentChecksColumns(handlePaymentCheckButtonClick),
    [TAB_NAMES.SCHEDULED_PAYMENTS]: getScheduledPaymentColumns(),
  };

  const tabName: ActiveTabs = {
    [TAB_NAMES.PAYMENTS]: 'Payments',
    [TAB_NAMES.FAILED_PAYMENTS]: 'Failed Payments',
    [TAB_NAMES.PAYMENT_CHECKS]: 'Payment Checks',
    [TAB_NAMES.SCHEDULED_PAYMENTS]: 'Scheduled Payments',
  };

  const tabNamesRender = () => {
    if (item?.creditApplication?.scheduledPayments?.length === 0) {
      return Object.values(TAB_NAMES).filter(tab => tab !== TAB_NAMES.SCHEDULED_PAYMENTS);
    }
    return TAB_NAMES;
  };

  return (
    <Card className={'col-span-12 w-full'}>
      <div className="bg-primary-foreground w-full max-w-full overflow-x-auto sm:rounded-lg">
        <div className="px-4 py-2">
          {!item?.active && (
            <div className="mt-2 inline-flex items-center justify-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800">
              Inactive
            </div>
          )}
          <div className="flex justify-between font-medium">
            <div>
              <Typography variant={'subHeroSmall'}>Cash</Typography>
            </div>
            <QrCode data={item?.id ?? ''} size={70} />
          </div>
        </div>
        <div className={'flex flex-row gap-4 border-b'}>
          <div className={'flex flex-row gap-4'}>
            <DetailRow
              title={'Payment Preference'}
              value={humanize(item?.creditApplication?.paymentPreference || '')}
            />

            <div className="flex flex-row">
              <DetailRow
                title={'Total to Pay'}
                value={(
                  (item?.creditApplication?.paymentDetails?.totalToPayExcludingFee ?? 0) / MONEY_MULTIPLIER
                ).toFixed(2)}
              />
              {isPayOverTime && (
                <DetailRow
                  title={'Total with Fee'}
                  value={(
                    (item?.creditApplication?.paymentDetails?.totalToPayIncludingFee ?? 0) / MONEY_MULTIPLIER
                  ).toFixed(2)}
                />
              )}
            </div>
          </div>
          <div className="flex flex-row">
            <DetailRow
              title={'Total Paid'}
              value={(
                (item?.creditApplication?.paymentDetails?.paidAmountExcludingFee ?? 0) / MONEY_MULTIPLIER
              ).toFixed(2)}
            />
            {isPayOverTime && (
              <DetailRow
                title={'Total Paid with Fee'}
                value={(
                  (item?.creditApplication?.paymentDetails?.paidAmountIncludingFee ?? 0) / MONEY_MULTIPLIER
                ).toFixed(2)}
              />
            )}
          </div>
          {item?.creditApplication?.paymentDetails?.remainingAmountToPay ? (
            <div className="flex justify-between">
              <DetailRow
                title={'Left to Pay'}
                value={(
                  (item?.creditApplication?.paymentDetails?.remainingAmountToPay ?? 0) / MONEY_MULTIPLIER
                ).toFixed(2)}
              />
            </div>
          ) : null}
        </div>
        <div className="border-b">
          <DetailRow title={'Cash item id'} value={item?.id ?? ''} copyEnabled onCopy={() => setShowCopied(true)} />
        </div>
        {isEmpty(transactions) && (
          <div className="flex justify-between border-b">
            <DetailRow title={'Amount'} value={item?.creditValueFormatted ?? ''} />
          </div>
        )}
        <div className="flex gap-4 p-4">
          {Object.values(tabNamesRender()).map(tabName => {
            return (
              <Button
                key={tabName}
                onClick={() => {
                  setActiveTab(tabName);
                }}
                variant={activeTab === tabName ? 'outline' : 'ghost'}
              >
                {tabName} - {rows[tabName]?.length}
              </Button>
            );
          })}
        </div>

        <div className={'p-4'}>
          <SmartTable
            rows={rows[activeTab]}
            columns={columns[activeTab]}
            amountOfRows={rows[activeTab]?.length}
            title={tabName[activeTab as keyof typeof tabName]}
            showPagination={false}
          />
        </div>
        <Modal
          isOpen={
            (
              isOpenConfirm as {
                paymentId: string | null;
                isOpen: boolean;
              }
            ).isOpen
          }
          onClose={() =>
            setIsOpenConfirm({
              ...isOpenConfirm,
              isOpen: !isOpenConfirm.isOpen,
            })
          }
        >
          <Typography variant={'headerMedium'}>Confirm Refund</Typography>
          <Typography variant={'body'}>Are you sure you want to refund this payment?</Typography>
          <div className={'flex gap-6'}>
            <Button
              onClick={() =>
                setIsOpenConfirm({
                  ...isOpenConfirm,
                  isOpen: !isOpenConfirm.isOpen,
                })
              }
            >
              Cancel
            </Button>
            <Button color={'destructive'} onClick={() => handleRefund(isOpenConfirm.paymentId)}>
              Refund
            </Button>
          </div>
        </Modal>
      </div>
    </Card>
  );
};
