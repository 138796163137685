import { NetworkStatus } from '@apollo/client';

interface NetworkStatusProps {
  loading: boolean;
  networkStatus?: NetworkStatus;
}

export const isNetworkRequestInFlight = (props: NetworkStatusProps): boolean => {
  const { loading, networkStatus } = props;
  const isReFetching = networkStatus === NetworkStatus.refetch;
  return loading || isReFetching;
};
