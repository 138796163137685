export const LookerStudio = () => {
  return (
    <iframe
      className="h-full min-h-[90vh]"
      width="100%"
      height="100%"
      src="https://lookerstudio.google.com/embed/reporting/5533d2a8-8c46-4467-88d0-3243513768ed/page/p_ceurf35fdd"
      frameBorder="0"
      allowFullScreen
      sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
    ></iframe>
  );
};
