import { Button } from '@pesto/ui';
import { Modal } from '@pesto/ui/components/Modal';
import { useNavigate } from 'react-router-dom';

import type { Transaction } from '../../../__generated__/graphql/api';
import { SinglePaymentInfo } from '../../../components/CashCard/SinglePaymentInfo';
import { routes } from '../../../constants/routes';

interface ModalSinglePaymentProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  transaction: Transaction | null;
}

export const ModalSinglePayment = (props: ModalSinglePaymentProps) => {
  const { isOpen, onClose, transaction } = props;
  const navigate = useNavigate();
  const goToCreditApplication = () => {
    navigate(routes.creditApplications + '/' + transaction?.creditApplication?.id);
  };
  if (!transaction) return null;
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${transaction?.user?.firstName} ${transaction?.user?.lastName} Payment`}
    >
      <Button onClick={goToCreditApplication} color={'secondary'}>
        Go to Credit Application
      </Button>
      <SinglePaymentInfo payment={transaction} last onRefundSuccess={onClose} />
    </Modal>
  );
};
