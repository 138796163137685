import type { ApolloError, OperationVariables, QueryHookOptions, QueryResult } from '@apollo/client';

export type UseUserQueryOptions<TData extends { currentUser?: any }, TVariables extends OperationVariables> = {
  queryHook: (options: QueryHookOptions<TData, TVariables>) => QueryResult<TData, TVariables>;
  isAuthenticated: () => boolean;
  signOut: () => void;
};

const alertOnLogout = (err: ApolloError) => {
  alert(`
      *** ERROR ***
      There was an error fetching the user's data from the database.
      BE error message - ${err.message}
      `);
};

export const useUser = <TData extends { currentUser?: any }, TVariables extends OperationVariables>({
  queryHook,
  isAuthenticated,
  signOut,
}: UseUserQueryOptions<TData, TVariables>) => {
  const { data, loading, error } = queryHook({
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    skip: !isAuthenticated(),
    onError: err => {
      if (err.message.includes('Unauthorized')) {
        signOut();
      } else {
        alertOnLogout(err);
      }
    },
  });

  const user = data?.currentUser;
  const isLoggedIn = !!user;
  const isLoading = loading;

  return { user, isLoggedIn, isLoading, error };
};
