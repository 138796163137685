import type { ApolloError } from '@apollo/client/errors';
import { useOnMount } from '@pesto/hooks';
import { Checkbox } from '@pesto/ui/components/Checkbox';
import type { DropdownOption } from '@pesto/ui/components/DropdownInput';
import { Label } from '@pesto/ui/components/Label';
import { RadioGroup, RadioGroupItem } from '@pesto/ui/components/RadioGroup';
import { TextArea } from '@pesto/ui/components/TextArea';
import { camelToSnakeCase, humanize } from '@pesto/utils';
import { useCallback } from 'react';

import type { CreditCheck, GetCreditApplicationByIdQuery } from '../../../__generated__/graphql/api';
import { CancellationType } from '../../../__generated__/graphql/api';

interface CloseApplicationProps {
  application: GetCreditApplicationByIdQuery['creditApplication'] | undefined | null;
  checkboxes: { affiliateContributed: boolean; bureauContributed: boolean };
  setCheckboxes: (value: { affiliateContributed: boolean; bureauContributed: boolean }) => void;
  rejectionReason: string[];
  setRejectionReason: (value: string[]) => void;
  approveDenyReason: string;
  setApproveDenyReason: (value: string) => void;
  cancellationType: CancellationType | undefined;
  setCancellationType: (value: CancellationType) => void;
  skipSendEmail: boolean;
  setSkipSendEmail: (value: boolean) => void;
  closeApplicationError: ApolloError | undefined;
}

export const RejectionReasons = {
  CreditFreeze: 'CREDIT_FREEZE',
  AbilityToPay: 'ABILITY_TO_PAY',
  Bankruptcy: 'BANKRUPTCY',
  Ofac: 'OFAC',
  Address: 'ADDRESS',
  Fraud: 'FRAUD',
  NoSsnMatch: 'NO_SSN_MATCH',
  Kyc: 'KYC',
  Synthetic: 'SYNTHETIC',
  Email: 'EMAIL',
  Phone: 'PHONE',
  AlertList: 'ALERT_LIST',
  WatchList: 'WATCH_LIST',
  DeviceOne: 'DEVICE_ONE',
  DeviceTwo: 'DEVICE_TWO',
  DeviceThree: 'DEVICE_THREE',
  PreviouslyKycRejection: 'PREVIOUSLY_KYC_REJECTION',
  PreviousDefault: 'PREVIOUS_DEFAULT',
  IncompleteApplication: 'INCOMPLETE_APPLICATION',
  InsufficientIncome: 'INSUFFICIENT_INCOME',
  CollateralValue: 'COLLATERAL_VALUE',
  DocumentVerification: 'DOCUMENT_VERIFICATION',
  PaymentVerification: 'PAYMENT_VERIFICATION',
} as const;

const defaultRejectionMessage =
  'Application declined due to no response to request for identity verification documents within the required timeframe';

const prepopulatedReason = {
  [RejectionReasons.CreditFreeze]: 'Credit freeze in place',
  [RejectionReasons.AbilityToPay]: 'Income Insufficient for amount of credit being requested',
  [RejectionReasons.Bankruptcy]: 'Bankruptcy',
  [RejectionReasons.Ofac]: 'Applicant is listed on one of the Office of Foreign Assets Control (OFAC) sanctions lists',
  [RejectionReasons.Address]: 'Unable to verify residence',
  [RejectionReasons.PreviouslyKycRejection]:
    'Applicant was previously rejected for the Pesto Mastercard® for failing the identity verification process in the past two years',
  [RejectionReasons.PreviousDefault]: 'Applicant has defaulted on the Pesto Mastercard® in the past two years.',
  [RejectionReasons.IncompleteApplication]: 'Credit application incomplete',
  [RejectionReasons.InsufficientIncome]: 'Income insufficient for amount of credit requested',
  [RejectionReasons.CollateralValue]: 'Value or type of collateral not sufficient',
  [RejectionReasons.DocumentVerification]: 'Unable to verify identity due to unsuccessful documentation verification',
  [RejectionReasons.PaymentVerification]: 'Application declined due to unsuccessful payment verification',
  [RejectionReasons.Fraud]: defaultRejectionMessage,
  [RejectionReasons.NoSsnMatch]: defaultRejectionMessage,
  [RejectionReasons.Kyc]: defaultRejectionMessage,
  [RejectionReasons.Synthetic]: defaultRejectionMessage,
  [RejectionReasons.Email]: defaultRejectionMessage,
  [RejectionReasons.Phone]: defaultRejectionMessage,
  [RejectionReasons.AlertList]: defaultRejectionMessage,
  [RejectionReasons.WatchList]: defaultRejectionMessage,
  [RejectionReasons.DeviceOne]: defaultRejectionMessage,
  [RejectionReasons.DeviceTwo]: defaultRejectionMessage,
  [RejectionReasons.DeviceThree]: defaultRejectionMessage,
};

const rejectionReasons: DropdownOption[] = Object.entries(RejectionReasons).map(([, value]) => {
  return { value, label: humanize(value) };
});
export const CloseApplication = (props: CloseApplicationProps) => {
  const {
    application,
    checkboxes,
    setCheckboxes,
    rejectionReason,
    setRejectionReason,
    approveDenyReason,
    setApproveDenyReason,
    setCancellationType,
    cancellationType,
    skipSendEmail,
    setSkipSendEmail,
    closeApplicationError,
  } = props;

  const creditCheck: CreditCheck | undefined | null = application?.creditChecks?.filter(
    check => check?.active === true,
  )?.[0];

  const predictDefaultValues = () => {
    const cancelTypePredictions = () => {
      return setCancellationType(CancellationType.CanceledSoft);
    };
    const contributionsPredictions = () => {
      const affiliateContributed = Boolean(
        creditCheck?.address ||
          creditCheck?.fraud ||
          creditCheck?.kyc ||
          creditCheck?.synthetic ||
          creditCheck?.email ||
          creditCheck?.phone ||
          creditCheck?.alertlist ||
          creditCheck?.watchlist ||
          creditCheck?.deviceOne ||
          creditCheck?.deviceTwo ||
          creditCheck?.deviceThree,
      );

      const bureauContributed = Boolean(
        creditCheck?.abilityToPay ||
          creditCheck?.bankruptcy ||
          creditCheck?.noSsnMatch ||
          creditCheck?.creditFreeze ||
          creditCheck?.ofac,
      );

      setCheckboxes({
        affiliateContributed: affiliateContributed,
        bureauContributed: bureauContributed,
      });
    };
    const denialPredictions = () => {
      if (!creditCheck) return;
      const predictedArray = Object.entries(creditCheck)
        .map(([key, value]) => {
          const snakeCaseUpperKey = camelToSnakeCase(key).toUpperCase();
          const rejectReasonsArray = Object.values(RejectionReasons);

          if (!!value && rejectReasonsArray.includes(snakeCaseUpperKey as (typeof rejectReasonsArray)[number])) {
            return snakeCaseUpperKey;
          }
          return null;
        })
        .filter(Boolean) as string[];
      setRejectionReason(predictedArray);
      constructPrefilledString(predictedArray);
    };
    cancelTypePredictions();
    contributionsPredictions();
    denialPredictions();
  };

  const constructPrefilledString = useCallback(
    (reasons: string[]) => {
      const bullet = '\u2022';
      const rejectArray = reasons
        ?.map(reason => prepopulatedReason[reason as keyof typeof prepopulatedReason] ?? reason)
        .map(reason => `${bullet} ${reason}`);
      const deduplicatedReasons = [...new Set(rejectArray)];
      const stringReason = deduplicatedReasons.join('\n');
      setApproveDenyReason(stringReason);
    },
    [setApproveDenyReason],
  );

  useOnMount(() => {
    predictDefaultValues();
  });

  return (
    <div className="flex flex-col gap-4">
      <Label className="text-slate-500">Select the type of cancellation</Label>{' '}
      <RadioGroup
        defaultValue={CancellationType.CanceledSoft}
        value={cancellationType}
        onValueChange={(value: CancellationType) => setCancellationType(value)}
        className="flex gap-4"
      >
        <div className="flex items-center space-x-2">
          <RadioGroupItem value={CancellationType.CanceledSoft} id="option-one" />
          <Label htmlFor="option-one">Soft</Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value={CancellationType.CanceledHard} id="option-two" />
          <Label htmlFor="option-two">Hard</Label>
        </div>
      </RadioGroup>
      <Label className="text-slate-500">Contribution</Label>{' '}
      <div className="flex items-center space-x-2">
        <Checkbox
          id="affiliateContributed"
          checked={checkboxes.affiliateContributed}
          onCheckedChange={() =>
            setCheckboxes({
              ...checkboxes,
              affiliateContributed: !checkboxes.affiliateContributed,
            })
          }
        />
        <label
          htmlFor="affiliateContributed"
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          Affiliate Contributed
        </label>
      </div>
      <div className="flex items-center space-x-2">
        <Checkbox
          id="bureauContributed"
          checked={checkboxes.bureauContributed}
          onCheckedChange={() => setCheckboxes({ ...checkboxes, bureauContributed: !checkboxes.bureauContributed })}
        />
        <label
          htmlFor="bureauContributed"
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          Bureau Contributed
        </label>
      </div>
      <Label className="text-slate-500">Cancel reasons </Label>{' '}
      <div className="grid grid-cols-2 gap-4">
        {rejectionReasons.map(reason => {
          if (!reason.value) return null;
          return (
            <div key={reason.value} className="flex items-center space-x-2">
              <Checkbox
                id={reason.value as string}
                checked={rejectionReason.includes(reason.value as string)}
                onCheckedChange={() => {
                  const selection = () => {
                    if (rejectionReason.includes(reason?.value as string)) {
                      return rejectionReason.filter(item => item !== reason.value);
                    }
                    return [...rejectionReason, reason.value as string];
                  };
                  constructPrefilledString(selection());
                  setRejectionReason(selection());
                }}
              />
              <label
                htmlFor={reason.value as string}
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {reason.label}
              </label>
            </div>
          );
        })}
      </div>
      <Label htmlFor={'approveDenyReason'} className="text-slate-500">
        Adverse action text
      </Label>
      <TextArea
        id={'approveDenyReason'}
        value={approveDenyReason}
        onChange={value => setApproveDenyReason(value.target.value)}
        rows={5}
      />
      <div className="flex w-auto items-center space-x-2 rounded border border-slate-200 p-6">
        <Checkbox id="skipSendEmail" checked={skipSendEmail} onCheckedChange={() => setSkipSendEmail(!skipSendEmail)} />
        <label
          htmlFor="skipSendEmail"
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          Skip sending email
        </label>
      </div>
      {closeApplicationError && (
        <div className="text-sm text-red-500">
          <p>{closeApplicationError.message}</p>
        </div>
      )}
    </div>
  );
};
