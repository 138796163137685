import { useDebounce, useDeepCompareEffect } from '@pesto/hooks';
import {
  type ColumnFiltersState,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type PaginationState,
  type SortingState,
  type TableOptions,
  useReactTable,
  type VisibilityState,
} from '@tanstack/react-table';
import type { ReactNode } from 'react';
import * as React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { useConfig } from '../../../providers/ConfigProvider';
import type { UseDataTableProps } from '../types/SmartTable.types';
import { getColumnFilterKey, getColumnSortKey } from '../utils/helpers';

import { useQueryString } from './useQueryString';

interface DataWithChildren {
  children?: DataWithChildren[] | ReactNode;
}

const searchParamsSchema = z
  .object({
    page: z.coerce.number().default(1),
    resultsPerPage: z.coerce.number().optional(),
    sort: z.string().optional(),
  })
  .catchall(z.any());

export function useDataTable<TData extends DataWithChildren>({
  pageCount = -1,
  filterFields = [],
  enableAdvancedFilter = false,
  method = 'replace',
  scroll = false,
  startTransition,
  ...props
}: UseDataTableProps<TData>) {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const { rowsPerPage } = useConfig().pagination;
  // Search paramsTData
  const search = searchParamsSchema.parse(Object.fromEntries(searchParams));

  const page = search.page;
  const perPage = search.resultsPerPage ?? props.initialState?.pagination?.pageSize ?? rowsPerPage;
  const sort =
    search.sort ?? `${props.initialState?.sorting?.[0]?.id}.${props.initialState?.sorting?.[0]?.desc ? 'desc' : 'asc'}`;
  const [column, order] = sort?.split('.') ?? [];

  // Memoize computation of searchableColumns and filterableColumns
  const { searchableColumns, filterableColumns } = React.useMemo(() => {
    return {
      searchableColumns: filterFields?.filter(field => !field.options),
      filterableColumns: filterFields?.filter(field => field.options),
    };
  }, [filterFields]);

  // Create query string
  const { createQueryString } = useQueryString(searchParams);

  // Initial column filters
  const initialColumnFilters: ColumnFiltersState = React.useMemo(() => {
    const createFilter = (column: any, value: string) =>
      ({
        id: String(column.value),
        value: column.options ? value?.split('.') : [value],
      }) satisfies ColumnFiltersState[number];

    return Array.from(searchParams.entries())
      .filter(([key]) => {
        const isFilterableColumn = filterableColumns.some(col => getColumnFilterKey(col.value) === key);
        const isSearchableColumn = searchableColumns.some(col => getColumnFilterKey(col.value) === key);
        return isFilterableColumn || isSearchableColumn;
      })
      .map(([key, value]) => {
        const column =
          filterableColumns.find(col => getColumnFilterKey(col.value) === key) ||
          searchableColumns.find(col => getColumnFilterKey(col.value) === key);
        return createFilter(column, value);
      });
  }, [filterableColumns, searchableColumns, searchParams]);

  // Table states
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(
    props.initialState?.columnVisibility ?? {},
  );
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(initialColumnFilters);

  // Handle server-side pagination
  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: page - 1,
    pageSize: perPage,
  });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const getFullColumnId = (sortFilter: string) => {
    const columns = props.columns;
    return columns.find(col => col?.id?.includes(sortFilter))?.id;
  };

  // Handle server-side sorting
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: getFullColumnId(column) ?? '',
      desc: order === 'desc',
    },
  ]);

  const sortFilterName = (sorting: SortingState) => {
    return getColumnSortKey(sorting[0].id);
  };

  React.useEffect(() => {
    function onUrlChange() {
      const url = `${pathname}?${createQueryString({
        pageNumber: pageIndex + 1,
        resultsPerPage: pageSize,
        sort: sorting[0]?.id ? `${sortFilterName(sorting)}.${sorting[0]?.desc ? 'desc' : 'asc'}` : null,
      })}`;

      method === 'push'
        ? navigate(url, { replace: false, state: { scroll } })
        : navigate(url, { replace: true, state: { scroll } });
    }

    startTransition
      ? startTransition(() => {
          onUrlChange();
        })
      : onUrlChange();
  }, [pageIndex, pageSize, sorting, method, scroll, startTransition, pathname, createQueryString, navigate]);

  // Handle server-side filtering
  const debouncedSearchableColumnFilters = JSON.parse(
    useDebounce(
      JSON.stringify(
        columnFilters.filter(filter => {
          return 'find' in searchableColumns && searchableColumns.find(column => column.value === filter.id);
        }),
      ),
      500,
    ),
  ) as ColumnFiltersState;

  const filterableColumnFilters = columnFilters.filter(filter => {
    return 'find' in filterableColumns && filterableColumns.find(column => column.value === filter.id);
  });

  const [mounted, setMounted] = React.useState(false);

  const table = useReactTable({
    ...props,
    pageCount,
    state: {
      pagination,
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getSubRows: row => row.children,
    getExpandedRowModel: getExpandedRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
  } as TableOptions<TData>);

  useDeepCompareEffect(() => {
    // Opt out when advanced filter is enabled, because it contains additional params
    if (enableAdvancedFilter) return;

    // Prevent resetting the page on initial render
    if (!mounted) {
      setMounted(true);
      return;
    }

    // Initialize new params
    const newParamsObject = {
      page: 1,
    };

    // Handle debounced searchable column filters
    for (const column of debouncedSearchableColumnFilters) {
      if (typeof column.value === 'string') {
        Object.assign(newParamsObject, {
          [getColumnFilterKey(column.id)]: typeof column.value === 'string' ? column.value : null,
        });
      }
    }

    // Handle filterable column filters
    for (const column of filterableColumnFilters) {
      if (typeof column.value === 'object' && Array.isArray(column.value)) {
        Object.assign(newParamsObject, { [getColumnFilterKey(column.id)]: column.value.join('.') });
      }
    }

    // Remove deleted values
    for (const key of searchParams.keys()) {
      if (
        ('find' in searchableColumns &&
          searchableColumns.find(column => getColumnFilterKey(column.value as string) === key) &&
          !debouncedSearchableColumnFilters.find(column => getColumnFilterKey(column.id) === (key as any))) ||
        ('find' in filterableColumns &&
          filterableColumns.find(column => getColumnFilterKey(column.value as string) === key) &&
          !filterableColumnFilters.find(column => getColumnFilterKey(column.id) === (key as any)))
      ) {
        Object.assign(newParamsObject, { [key]: null });
      }
    }

    // After cumulating all the changes, push new params
    function onUrlChange() {
      const url = `${pathname}?${createQueryString(newParamsObject)}`;
      navigate(url, { replace: method !== 'push', state: { scroll } });
    }

    startTransition
      ? startTransition(() => {
          onUrlChange();
        })
      : onUrlChange();

    table.setPageIndex(0);
  }, [debouncedSearchableColumnFilters, filterableColumnFilters, method, scroll]);

  return { table };
}
