import { BackButton } from '@pesto/ui/components/BackButton';
import { useParams } from 'react-router-dom';

import { UserDetails } from '../../components/UserDetails/UserDetails';

export const UserDetailsPage = () => {
  const params = useParams();
  const userId = params.id;
  return (
    <>
      <div className={'flex flex-col gap-4 p-4'}>
        <BackButton />
        <UserDetails userId={userId} />
      </div>
    </>
  );
};
