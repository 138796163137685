import { splitCamelCaseToString } from '@pesto/utils';

const SEPARATOR = '__ID_SEPARATOR__';
export const getColumnFilterKey = (id: string | number | symbol) => {
  const strId = String(id);
  return !strId.includes(SEPARATOR) ? strId : strId.split(SEPARATOR)[0];
};
export const getColumnSortKey = (id: string | number | symbol) => {
  const strId = String(id);
  return !strId.includes(SEPARATOR) ? strId : strId.split(SEPARATOR)[1];
};
export const setColumnFilterAndSortKey = (filterKey: string, sortKey: string) => filterKey + SEPARATOR + sortKey;

export const generateSelectableFilterOptions = (obj: Record<string, string | boolean>, withCount: boolean = true) => {
  return Object.entries(obj).map(([key, value]) => ({
    label: splitCamelCaseToString(String(key)) || String(key),
    value: value,
    withCount: withCount,
  }));
};

export const generateBooleanFilterOptions = () => {
  return generateSelectableFilterOptions({ true: true, false: false }, false);
};
