import { Button, CardComponent } from '@pesto/ui';
import type { ButtonProps } from '@pesto/ui/components/Button';
import { DataList } from '@pesto/ui/components/DataList';

interface OverviewDetailsProps {
  applicationData: ApplicationData[];
  actionButtons: ActionButton[];
  onActionButtonClick?: (action: any) => void;
}

export interface ActionButton {
  label: string;
  action: any;
  variant?: ButtonProps['variant'];
  color?: ButtonProps['color'];
  icon?: React.FC<any>;
  sort: number;
  buttonProps?: ButtonProps;
}
type OnlyChildren = boolean;

export type ApplicationData = {
  title: string;
  keepKeys?: boolean;
  onlyChildren?: OnlyChildren;
  cardClassName?: string;
} & (
  | {
      onlyChildren: true;
      data: React.ReactNode;
    }
  | {
      onlyChildren?: false;
      data: Record<string, string | number | undefined | null | React.ReactNode>;
    }
);

export const OverviewDetails: React.FC<OverviewDetailsProps> = ({
  applicationData,
  actionButtons,
  onActionButtonClick,
}) => {
  const renderMainActionButtons = () => {
    return actionButtons?.map(button => (
      <Button
        key={button.action}
        variant={button.variant || 'primary'}
        color={button.color}
        onClick={() => onActionButtonClick?.(button.action)}
        {...button.buttonProps}
      >
        {button.label}
      </Button>
    ));
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-2">{renderMainActionButtons()}</div>

      <div className="grid grid-cols-[repeat(auto-fill,minmax(400px,1fr))] gap-4">
        {applicationData.map(data => (
          <CardComponent key={data.title} title={data.title} className={data.cardClassName}>
            {data.onlyChildren ? data.data : <DataList keepKeys={data.keepKeys || false} root={data.data} />}
          </CardComponent>
        ))}
      </div>
    </div>
  );
};
