import qs from 'qs';
import { useCallback } from 'react';

export type QueryParams = Record<string, any>;

export function useQueryString(searchParams: URLSearchParams) {
  const createQueryString = useCallback(
    (params: QueryParams) => {
      const existingParams = qs.parse(searchParams.toString(), { ignoreQueryPrefix: true });

      const mergedParams = { ...existingParams, ...params };

      const cleanedParams: Record<string, any> = {};
      for (const [key, value] of Object.entries(mergedParams)) {
        if (value !== null && value !== undefined) {
          cleanedParams[key] = value;
        }
      }

      const queryString = qs.stringify(cleanedParams, {
        addQueryPrefix: false,
        encode: false, // Disable encoding of `[` and `]` for proper readability
        arrayFormat: 'brackets', // Supports array serialization like notNullFilters[gclid]
      });

      return queryString;
    },
    [searchParams],
  );

  return { createQueryString };
}
