import type { Config } from '@pesto/application-config';
import { defaultConfig as initialConfig } from '@pesto/application-config';
import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

const ConfigContext = createContext<Config>(initialConfig);

interface ConfigProviderProps {
  children: ReactNode;
  defaultConfig?: Config;
}

export const ConfigProvider = ({ children, defaultConfig }: ConfigProviderProps) => (
  <ConfigContext.Provider value={defaultConfig || initialConfig}>{children}</ConfigContext.Provider>
);

export const useConfig = () => {
  const context = useContext(ConfigContext);

  if (context === undefined) throw new Error('useConfig must be used within a ConfigProvider');

  return context;
};
