import { Typography } from '@pesto/ui';
import { CARAT_MULTIPLIER } from '@pesto/utils/constants/pestoConstants';

import { formatDate } from '../dateHelper';
import { startCase } from '../string/stringHelper';

export interface JsonObject {
  [key: string]: string | number;
}

export const centsToFormattedDollars = (cents: number | null | undefined) => {
  if (cents || cents == 0) return (cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};
export function formatPhoneNumber(phoneNumberString: string) {
  return phoneNumberString?.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}

export const colorizeResult = (value: string | boolean | undefined | null) => {
  switch (value) {
    case true:
      return (
        <Typography variant={'body'} className={'text-green-600'}>
          true
        </Typography>
      );
    case false:
      return (
        <Typography variant={'body'} className={'text-red-600'}>
          false
        </Typography>
      );
    case null:
    case undefined:
      return (
        <Typography variant={'body'} className={'text-gray-600'}>
          n/a
        </Typography>
      );
    default:
      return (
        <Typography variant={'body'} className={'text-purple-600'}>
          {value}
        </Typography>
      );
  }
};

const formatKey = (key: string, value: string | number | null): string => {
  const stringValue = startCase(value?.toString());
  const stringKey = startCase(key.toString());
  const numberValue = Number(value);

  const formattedValue: {
    [key: string]: string;
  } = {
    brand: stringValue.replace(/_/g, ' '),
    color: `${stringValue} color`,
    clarity: `${stringValue} clarity`,
    length_in_inches: `${stringValue}“`,
    carat: `${numberValue / CARAT_MULTIPLIER} ct`,
    default: `${stringValue}`,
  };
  return `${stringKey}: ${formattedValue[key] || formattedValue.default}`;
};

export const recursivelyRenderDetails = (detail: JsonObject | undefined | null): string[] => {
  if (!detail) return [];
  return Object.entries(detail).reduce((acc: string[], [key, value]) => {
    if (value !== null && typeof value === 'object') {
      return [...acc, ...recursivelyRenderDetails(value as JsonObject)];
    }
    acc.push(`${formatKey(key, value)} |`);
    return acc;
  }, []);
};

export const parseDetails = (detailsJSON: string): string => {
  const details: JsonObject = JSON.parse(detailsJSON);
  const detailsArray = recursivelyRenderDetails(details);
  return detailsArray.join(' ').slice(0, -2);
};
export function parseStringProperties(key: string, value: string) {
  switch (key) {
    case 'updatedAt':
    case 'insertedAt':
      return formatDate(value);
    case 'phone':
      return formatPhoneNumber(value);
    case 'details':
      return parseDetails(value);
    default:
      return value;
  }
}

export function parseNumberProperties(key: string, value: number) {
  switch (key) {
    case 'creditValue':
    case 'valuation':
      return centsToFormattedDollars(value);
    default:
      return value;
  }
}

export function formatApplicationValues(key: string, value: any) {
  switch (typeof value) {
    case 'boolean':
      return colorizeResult(value);
    case 'number':
      return parseNumberProperties(key, value);
    case 'object':
      if (value?.type === 'component') {
        return value.component;
      }
      return parseStringProperties(key, value);
    default: // string
      return parseStringProperties(key, value);
  }
}

export function acceptedKeyValueCheck(key: string, value: any) {
  return (
    key === '__typename' ||
    key === 'creditChecks' ||
    key === 'photoLinks' ||
    key === 'presignedPhotoLinks' ||
    key === 'items' ||
    value === null ||
    value === undefined
  );
}
