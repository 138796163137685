import { ShipmentCarrier } from '../__generated__/graphql/api';

export const getTrackingLink = (carrier: ShipmentCarrier | undefined | null, trackingNumber: string) => {
  const carrierLink = {
    [ShipmentCarrier.Fedex]: 'https://www.fedex.com/fedextrack/?trknbr=',
    [ShipmentCarrier.Ups]: 'https://www.ups.com/track?loc=null&tracknum=',
    [ShipmentCarrier.Usps]: 'https://tools.usps.com/go/TrackConfirmAction?tLabels=',
    default: 'https://tools.usps.com/go/TrackConfirmAction?tLabels=',
  };

  const carrierWebsite = carrierLink[carrier || 'default'] + trackingNumber;

  return carrierWebsite;
};
