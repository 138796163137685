import { Typography } from '@pesto/ui';
import { DataList } from '@pesto/ui/components/DataList';
import { isEmpty } from '@pesto/utils';

import type { AdTrackingDetails } from '../../../__generated__/graphql/api';

import { UserDetailsCard } from './UserDetailsCard';

interface AdTrackingDetailsCardProps {
  adTrackingDetails: AdTrackingDetails[] | undefined | null;
}

export const AdTrackingDetailsCard = (props: AdTrackingDetailsCardProps) => {
  const { adTrackingDetails } = props;
  const latestAdTrackingDetails = adTrackingDetails?.[0];
  const isAdTrackingDetailsEmpty = isEmpty(latestAdTrackingDetails);

  return (
    <UserDetailsCard className={'min-w-[350px]'} title={'Ad tracking details'}>
      {isAdTrackingDetailsEmpty ? (
        <Typography variant={'body'}>No ad tracking details available</Typography>
      ) : (
        <>
          <Typography variant={'body'}>Ad tracking details:</Typography>
          <DataList root={latestAdTrackingDetails} />
        </>
      )}
    </UserDetailsCard>
  );
};
