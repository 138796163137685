import { cn } from '@pesto/utils';
import type { Table } from '@tanstack/react-table';
import { TrashIcon } from 'lucide-react';
import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from '../../../../components/Button';
import type { DataTableFilterOption } from '../../../../components/DataTable/DataTable.types';
import { Input } from '../../../../components/Input';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../components/Popover';
import { useQueryString } from '../../hooks/useQueryString';
import { getColumnFilterKey } from '../../utils/helpers';

import { DataTableAdvancedFacetedFilter } from './DataTableAdvancedFacetedFilter';

interface DataTableFilterItemProps<TData> {
  table: Table<TData>;
  selectedOption: DataTableFilterOption<TData>;
  selectedOptions: DataTableFilterOption<TData>[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<DataTableFilterOption<TData>[]>>;
  defaultOpen: boolean;
}

export function DataTableFilterItem<TData>({
  table,
  selectedOption,
  selectedOptions,
  setSelectedOptions,
  defaultOpen,
}: DataTableFilterItemProps<TData>) {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [searchParams] = useSearchParams();

  const { createQueryString } = useQueryString(searchParams);

  const column = table.getColumn(selectedOption.value ? String(selectedOption.value) : '');

  const selectedValues = new Set(selectedOptions.find(item => item.value === column?.id)?.filterValues);

  const value = (table.getColumn(String(column?.id))?.getFilterValue() as string) ?? '';

  const [open, setOpen] = React.useState(defaultOpen);

  const handleSetSelectedOptions = (option: any, index: number, isSelected: boolean) => {
    if (isSelected) {
      selectedValues.delete(String(option.value));
    } else {
      selectedValues.add(String(option.value));
    }
    const filterValues = Array.from(selectedValues);
    column?.setFilterValue(filterValues.length ? filterValues : undefined);
    setSelectedOptions(prev =>
      prev.map(item =>
        item.value === column?.id
          ? {
              ...item,
              filterValues,
            }
          : item,
      ),
    );
  };

  const handleClearFilter = () => {
    column?.setFilterValue(undefined);
    setSelectedOptions(prev =>
      prev.map(item =>
        item.value === column?.id
          ? {
              ...item,
              filterValues: [],
            }
          : item,
      ),
    );
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className={cn('h-8 gap-0 rounded-md', (selectedValues.size > 0 || value.length > 0) && 'bg-muted/50')}
        >
          <span className="font-medium capitalize">{selectedOption.label}</span>
          {selectedOption.options.length > 0
            ? selectedValues.size > 0 && (
                <span className="text-muted-foreground">
                  <span className="text-foreground">: </span>
                  {selectedValues.size > 2
                    ? `${selectedValues.size} selected`
                    : selectedOption.options
                        .filter(item => typeof item.value === 'string' && selectedValues.has(item.value))
                        .map(item => item.label)
                        .join(', ')}
                </span>
              )
            : value.length > 0 && (
                <span className="text-muted-foreground">
                  <span className="text-foreground">: </span>
                  {value}
                </span>
              )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-60 space-y-1.5 p-2" align="start">
        <div className="flex items-center space-x-1 pl-1 pr-0.5">
          <div className="flex flex-1 items-center space-x-1">
            <div className="text-muted-foreground text-xs capitalize">{selectedOption.label}</div>
          </div>
          <Button
            aria-label="Remove filter"
            variant="ghost"
            onlyIcon
            className="text-muted-foreground size-7"
            onClick={() => {
              setSelectedOptions(prev => prev.filter(item => item.value !== selectedOption.value));

              const newSearchParams = createQueryString({
                [getColumnFilterKey(String(selectedOption.value))]: null,
              });

              navigate(`${pathname}?${newSearchParams}`, { replace: true });
            }}
          >
            <TrashIcon className="size-4" aria-hidden="true" />
          </Button>
        </div>
        {selectedOption.options.length > 0 ? (
          column && (
            <DataTableAdvancedFacetedFilter
              key={String(selectedOption.value)}
              title={selectedOption.label}
              options={selectedOption.options}
              selectedValues={selectedValues}
              onSelectValue={handleSetSelectedOptions}
              onClear={handleClearFilter}
            />
          )
        ) : (
          <Input
            placeholder="Type here..."
            className="h-8"
            value={value}
            onChange={event => table.getColumn(String(column?.id))?.setFilterValue(event.target.value)}
            autoFocus
          />
        )}
      </PopoverContent>
    </Popover>
  );
}
