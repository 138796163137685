import tailwindConfig from '@pesto/tailwind-config/tailwind.config.js';
import { useEffect, useState } from 'react';

import resolveConfig from 'tailwindcss/resolveConfig';

// this is a custom hook that returns a boolean value indicating whether the user is on a mobile device
export function useIsMobile() {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const checkIsMobile = () => {
      if (typeof window !== 'undefined') {
        const smScreen = resolveConfig(tailwindConfig).theme.screens.sm;
        const smScreenPx = parseInt(smScreen.replace('px', ''));

        setIsMobile(window.innerWidth <= smScreenPx);
      }
    };
    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  return isMobile;
}
