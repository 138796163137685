import { useListQuery } from '@pesto/hooks';
import { SmartTable } from '@pesto/ui/features/SmartTable/SmartTable';

import { useListSecurityEventsQuery } from '../../../__generated__/graphql/api';

import { useSecurityEventsListConfig } from './hooks/useSecurityEventsListConfig';

export const SecurityEventsList = () => {
  const { data, loadingOrReFetching } = useListQuery({
    queryHook: useListSecurityEventsQuery,
  });

  const { securityEventsFilterFields, initialTableState, getSecurityEventsColumns } = useSecurityEventsListConfig();
  const amountOfRows = data?.listSecurityEvents?.pagination.totalRecords;

  const rows = data?.listSecurityEvents?.securityEvents ?? [];

  const columns = getSecurityEventsColumns();

  return (
    <SmartTable
      title="Security Events"
      amountOfRows={amountOfRows}
      rows={rows}
      columns={columns}
      filterFields={securityEventsFilterFields}
      loading={loadingOrReFetching}
      pageCount={data?.listSecurityEvents?.pagination?.totalPages}
      initialState={initialTableState}
    />
  );
};
