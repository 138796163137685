import { useIsMobile } from '@pesto/hooks';
import { Button } from '@pesto/ui';
import { Bell } from 'lucide-react';

import { DesktopNavigation } from '../components/Navigation/DesktopNavigation';
import { MobileNavigation } from '../components/Navigation/MobileNavigation';
import type { ApplicationLayoutProps } from '../components/Navigation/Navigation.types';
import { UserNavDropdown } from '../components/Navigation/UserNavDropdown';

export const ApplicationLayout = (props: ApplicationLayoutProps) => {
  const { children, className, component, menuLinks, userMenuItems, homeLink } = props;
  const isMobile = useIsMobile();

  return (
    <div className={`flex h-full w-full max-w-full flex-row flex-nowrap ${className}`}>
      {!isMobile && <DesktopNavigation links={menuLinks} homeLink={homeLink} />}
      <div className="h-full min-h-screen w-full max-w-full overflow-auto">
        <header className="bg-muted/40 flex h-14 w-full items-center gap-4 border-b px-4 sm:h-[60px] lg:px-6">
          {isMobile && <MobileNavigation links={menuLinks} homeLink={homeLink} />}
          <div className="w-full flex-1">{component}</div>
          <Button variant="outline" onlyIcon className="ml-auto h-8 w-8">
            <Bell className="h-4 w-4" />
            <span className="sr-only">Toggle notifications</span>
          </Button>
          <UserNavDropdown menuItems={userMenuItems} />
        </header>
        <div className={'h-full w-full max-w-full p-4'}>{children}</div>
      </div>
    </div>
  );
};
