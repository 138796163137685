import { useIsMobile } from '@pesto/hooks';
import * as React from 'react';
import { Fragment } from 'react';

import { Button } from '../Button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '../Drawer';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../DropdownMenu';

import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from './Breadcrumbs';

export interface BreadcrumbResponsiveProps {
  items: { href?: string; label: string }[];
  itemsToDisplay?: number;
  drawerTitle?: string;
}

export function BreadcrumbComponent({
  items,
  itemsToDisplay = 3,
  drawerTitle = 'Navigate to',
}: BreadcrumbResponsiveProps) {
  const [open, setOpen] = React.useState(false);
  const isDesktop = !useIsMobile();

  const itemsWithoutFirst = items.slice(1);
  const firstItem = items[0];

  const lastItemsToDisplay = items.length > itemsToDisplay ? itemsWithoutFirst.slice(-2) : itemsWithoutFirst;

  return (
    <Breadcrumb>
      <BreadcrumbList>
        {/*First Item*/}
        <BreadcrumbItem>
          <BreadcrumbLink href={firstItem.href || '#'}>{firstItem.label}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />

        {/*Middle Items when there are more than 3 items*/}
        {items.length > itemsToDisplay && (
          <>
            <BreadcrumbItem>
              {isDesktop ? (
                <DropdownMenu open={open} onOpenChange={setOpen}>
                  <DropdownMenuTrigger className="flex items-center gap-1" aria-label="Toggle menu">
                    <BreadcrumbEllipsis className="h-4 w-4" />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="start">
                    {items.slice(1, -2).map((item, index) => (
                      <DropdownMenuItem key={index}>
                        <a href={item.href ? item.href : '#'}>{item.label}</a>
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              ) : (
                <Drawer open={open} onOpenChange={setOpen}>
                  <DrawerTrigger aria-label="Toggle Menu">
                    <BreadcrumbEllipsis className="h-4 w-4" />
                  </DrawerTrigger>
                  <DrawerContent>
                    <DrawerHeader className="text-left">
                      <DrawerTitle>{drawerTitle}</DrawerTitle>
                      <DrawerDescription>Select a page to navigate to.</DrawerDescription>
                    </DrawerHeader>
                    <div className="grid gap-1 px-4">
                      {items.slice(1, -2).map((item, index) => (
                        <a key={index} href={item.href ? item.href : '#'} className="py-1 text-sm">
                          {item.label}
                        </a>
                      ))}
                    </div>
                    <DrawerFooter className="pt-4">
                      <DrawerClose asChild>
                        <Button variant="outline">Close</Button>
                      </DrawerClose>
                    </DrawerFooter>
                  </DrawerContent>
                </Drawer>
              )}
            </BreadcrumbItem>
            <BreadcrumbSeparator />
          </>
        )}

        {/*Last Items*/}
        {lastItemsToDisplay.map((item, index) => (
          <Fragment key={index}>
            <BreadcrumbItem>
              {item.href ? (
                <>
                  <BreadcrumbLink asChild className="max-w-20 truncate md:max-w-none">
                    <a href={item.href}>{item.label}</a>
                  </BreadcrumbLink>
                </>
              ) : (
                <BreadcrumbPage className="max-w-20 truncate md:max-w-none">{item.label}</BreadcrumbPage>
              )}
            </BreadcrumbItem>

            {index !== lastItemsToDisplay.length - 1 && <BreadcrumbSeparator />}
          </Fragment>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  );
}
