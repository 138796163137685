import { cn } from '@pesto/utils';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        primary: 'bg-primary text-primary-foreground hover:bg-opacity-80',
        outline: 'border bg-opacity-0 hover:bg-opacity-20',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-accent underline-offset-4 hover:underline',
      },
      color: {
        primary: 'bg-primary text-primary-foreground border-primary',
        destructive: 'bg-destructive text-destructive border-destructive',
        secondary: 'bg-secondary text-secondary-foreground border-secondary',
        accent: 'bg-accent text-accent-foreground border-accent',
        muted: 'bg-muted text-muted-foreground border-muted',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
      onlyIcon: {
        true: 'aspect-square p-0',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
    compoundVariants: [{ variant: 'primary', color: 'destructive', className: 'text-primary-foreground' }],
  } as const,
);

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color'>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, onlyIcon, color, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn('flex gap-2', buttonVariants({ size, color, variant, onlyIcon, className }))}
        type="button"
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
