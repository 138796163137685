export interface Config {
  pagination: {
    rowsPerPage: number;
  };
}

export const defaultConfig: Config = {
  pagination: {
    rowsPerPage: 20,
  },
};
