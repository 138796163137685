import * as React from 'react';

import { generateInputStyles } from './Forms/inputStylesHelper';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, error, readOnly, ...props }, ref) => {
  const inputStyle = generateInputStyles({
    className,
    isError: !!error,
    readOnly,
  });
  return <input type={type} className={inputStyle} ref={ref} {...props} />;
});
Input.displayName = 'Input';

export { Input };
