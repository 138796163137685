import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { useClipboard } from '@pesto/hooks';
import { Button, Card, LoadingSpinner, Typography } from '@pesto/ui';
import { BackButton } from '@pesto/ui/components/BackButton';
import { Checkbox } from '@pesto/ui/components/Checkbox';
import { DataList } from '@pesto/ui/components/DataList';
import { DropdownInput } from '@pesto/ui/components/DropdownInput';
import { CurrencyField } from '@pesto/ui/components/Forms/CurrencyField';
import { TextAreaField } from '@pesto/ui/components/Forms/TextAreaField';
import { ImageGallery } from '@pesto/ui/components/ImageGallery';
import { Modal } from '@pesto/ui/components/Modal';
import { QrCode } from '@pesto/ui/components/QrCode';
import { formatDate, humanize } from '@pesto/utils';
import { PlusCircledIcon } from '@radix-ui/react-icons';
import { ArrowLeftIcon, CopyIcon, PlusIcon } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import type { CustodyType, Item } from '../../__generated__/graphql/api';
import {
  CreditApplicationStatus,
  DamageRecordCategory,
  ItemEventType,
  useAddDamageRecordToItemMutation,
  useItemReviewMutation,
  useListItemsQuery,
  useOverrideCreditValueMutation,
  useUpdateAppraisalValuationMutation,
  useUpdateCustodyRecordMutation,
} from '../../__generated__/graphql/api';
import { ItemActions, OtherItemActions, itemCustodyEvents, itemDenyReasons } from '../../components/ItemCard';
import { routes } from '../../constants/routes';

import { ItemEventsHistory } from './components/ItemEventsHistory';

export function ItemDetails() {
  const navigate = useNavigate();

  const [itemReviewMutation, { loading: loadingOther }] = useItemReviewMutation();
  const [updateAppraisalValuationMutation, { loading: loadingRevalue }] = useUpdateAppraisalValuationMutation();
  const [addItemDamageMutation, { loading: loadingDamage }] = useAddDamageRecordToItemMutation();
  const [overrideAppraisalCreditValue] = useOverrideCreditValueMutation();
  const [updateCustodyRecord] = useUpdateCustodyRecordMutation();
  const [sendEmail, setSendEmail] = useState(false);
  const [updateAppraisalId, setUpdateAppraisalId] = useState<string>();
  const { copy } = useClipboard();

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState('');
  const [value, setValue] = useState(0);
  const [rejectReason, setRejectReason] = useState<string | null | undefined>(null);
  const [newCustodyEvent, setNewCustodyEvent] = useState<string | null | undefined>(null);
  const [damage, setDamage] = useState<{ category?: DamageRecordCategory; description?: string }>();

  const handleCopy = useCallback(
    (value: string) => {
      copy(value);
    },
    [copy],
  );

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const { itemId } = useParams();

  const { data, loading } = useListItemsQuery({
    errorPolicy: 'all',
    variables: {
      idSearch: itemId,
    },
  });

  const item = data?.listItems?.items?.filter(it => it?.id === itemId)?.[0] as Item;
  const applicationStatus = item?.creditApplication?.status;
  const quickAppraisal = item?.itemEvents?.filter(it => it?.itemEventType === ItemEventType.QuickAppraisal)?.[0];
  const finalAppraisal = item?.itemEvents?.filter(it => it?.itemEventType === ItemEventType.FinalAppraisal)?.[0];
  const canRevaluate =
    applicationStatus === CreditApplicationStatus.Created ||
    applicationStatus === CreditApplicationStatus.PrequalItemValued ||
    applicationStatus === CreditApplicationStatus.FinInfoNeeded ||
    applicationStatus === CreditApplicationStatus.Prequalified ||
    applicationStatus === CreditApplicationStatus.AppraisalInProgress;

  const handleModalConfirm = useCallback(async () => {
    switch (action) {
      case ItemActions.REVALUE:
        await updateAppraisalValuationMutation({
          variables: {
            itemEventId: quickAppraisal?.id ?? '',
            valuation: value,
          },
        });
        setShowModal(false);
        break;
      case ItemActions.REJECT:
        await itemReviewMutation({
          variables: {
            itemId: item?.id ?? '',
            valuation: 0,
            rejectionReason: rejectReason,
          },
        });
        setShowModal(false);
        break;
      case ItemActions.SUBMIT:
        await itemReviewMutation({
          variables: {
            itemId: item?.id ?? '',
            valuation: value,
          },
        });
        setShowModal(false);
        break;
      case ItemActions.DAMAGE: {
        try {
          await addItemDamageMutation({
            variables: {
              itemId: item?.id ?? '',
              description: damage?.description ?? '',
              category: damage?.category as DamageRecordCategory,
            },
          });
          setShowModal(false);
        } catch (e) {
          setShowModal(false);
        }
        break;
      }
      case ItemActions.ADD_CUSTODY_EVENT: {
        await updateCustodyRecord({
          variables: {
            itemId: item?.id ?? '',
            inCustodyOf: newCustodyEvent as CustodyType,
          },
        });
        setShowModal(false);
        break;
      }
      case ItemActions.UPDATE_APPRAISAL: {
        await updateAppraisalValuationMutation({
          variables: {
            itemEventId: updateAppraisalId ?? '',
            valuation: value,
            sendRevalueEmail: sendEmail,
          },
        });
        setShowModal(false);
        break;
      }
      case ItemActions.OVERRIDE_CREDIT_VALUE: {
        await overrideAppraisalCreditValue({
          variables: {
            itemEventId: updateAppraisalId ?? '',
            creditValue: value,
            sendRevalueEmail: sendEmail,
          },
        });
        setShowModal(false);
        break;
      }
    }
  }, [
    action,
    newCustodyEvent,
    item?.id,
    sendEmail,
    overrideAppraisalCreditValue,
    updateAppraisalId,
    addItemDamageMutation,
    damage,
    itemReviewMutation,
    updateAppraisalValuationMutation,
    quickAppraisal?.id,
    updateCustodyRecord,
    value,
    rejectReason,
  ]);

  const getModalTitle = useCallback(() => {
    if (action === ItemActions.DAMAGE) {
      return (
        <div>
          <strong>Report Damaged Item</strong>
        </div>
      );
    }
    return (
      <div>
        Please confirm you want to <strong>{action}</strong> this item
      </div>
    );
  }, [action]);

  const handlePestoAppraisalClick = () => {
    const route = routes.addFinalAppraisal;
    navigate(`${route}/${item?.creditApplication?.id}/${item?.id}`);
  };

  const damageOptions = useMemo(() => {
    return Object.values(DamageRecordCategory).map(dd => {
      return { value: dd, label: humanize(dd) };
    });
  }, []);

  function handleSendEmailChange() {
    setSendEmail(!sendEmail);
  }

  if (loading) return <LoadingSpinner />;

  return (
    <div>
      <BackButton />

      <Card className="bg-primary-foreground w-full p-6">
        <Typography variant={'headerMedium'}>{`Item Details - ${humanize(item?.subtype ?? '')}`}</Typography>
        <div className="flex w-full justify-between gap-4">
          <div>
            <div className={'flex items-center'}>
              <Typography variant={'caption'}>{item?.id ?? ''}</Typography>
              <button className={'mx-4'} onClick={() => handleCopy(item?.id ?? '')}>
                <CopyIcon />
              </button>
            </div>
            <div className={'flex flex-col items-start gap-2'}>
              <Button
                color={'destructive'}
                onClick={() => {
                  setAction(ItemActions.DAMAGE);
                  setShowModal(true);
                }}
              >
                <ExclamationTriangleIcon className={'h-full'} />
                Report Damage
              </Button>
              <Button onClick={() => navigate(`${routes.creditApplications}/${item?.creditApplication?.id}`)}>
                <ArrowLeftIcon className={'h-full'} />
                Go to Credit Application
              </Button>
            </div>
          </div>

          <QrCode data={item?.id ?? ''} size={70} />
        </div>
      </Card>

      <div className={'flex items-center'}>
        <strong className={'my-8'}>Custody Timeline</strong>
        <button
          className={'bg-action ml-2 rounded'}
          onClick={() => {
            setAction(ItemActions.ADD_CUSTODY_EVENT);
            setShowModal(true);
          }}
        >
          <PlusIcon width={20} height={20} className="text-primary" />
        </button>
      </div>
      <div>
        {item?.itemCustodyRecords && item?.itemCustodyRecords?.length > 0 && (
          <ol>
            {item.itemCustodyRecords.map((ev, index) => {
              if (!ev) return null;
              return (
                <li key={ev.item?.id || '' + index}>
                  <div className={'event'}>
                    <label>{humanize(ev?.inCustodyOf ?? '')}</label>
                    <div>
                      <Typography variant={'bodySmall'}>
                        {ev?.insertedAt && formatDate(ev.insertedAt, 'MM-dd-yyyy HH:mm')}
                      </Typography>
                    </div>
                  </div>
                  <span className={'point'}></span>
                  <p className={'description'}>{`by: ${ev?.actor?.displayName}`}</p>
                </li>
              );
            })}
          </ol>
        )}
      </div>
      {item ? (
        <div className={'flex flex-col gap-4'}>
          {item?.presignedPhotoLinks && (
            <div>
              <strong className={'py-4'}>Photos</strong>
              <div className="flex h-[86px] w-[64px] gap-2 rounded object-cover object-center">
                {item?.presignedPhotoLinks?.map((url, index) => (
                  <img
                    key={url || '' + index}
                    className={'w-full rounded object-cover object-center'}
                    src={url || ''}
                    onClick={() => openImageViewer(index)}
                    alt={`Preview of ${item.type}`}
                  />
                ))}
              </div>
            </div>
          )}
          <strong>Actions</strong>
          <div className={'flex items-center gap-2'} id={'actions'}>
            {item?.needsReview && (
              <OtherItemActions
                item={item}
                onValuation={newVal => {
                  setAction(ItemActions.SUBMIT);
                  setValue(newVal);
                  setShowModal(true);
                }}
                onReject={() => {
                  setAction(ItemActions.REJECT);
                  setShowModal(true);
                }}
              />
            )}
            {!finalAppraisal && (
              <Button className={'h-auto'} onClick={handlePestoAppraisalClick}>
                <PlusCircledIcon className="mr-2" />
                Add Final Appraisal
              </Button>
            )}
          </div>
          <div className={'grid gap-4 md:grid-cols-[repeat(auto-fill,_minmax(_320px,_1fr))]'}>
            <Card className="p-6">
              <Typography variant="headerSmall">General Information</Typography>
              <div className="overflow-x-auto shadow-md sm:rounded-lg">
                <DataList root={item} />
              </div>
            </Card>
            {!!quickAppraisal && (
              <Card className="p-6">
                <div className={'mb-4 flex flex-col justify-between gap-2'}>
                  <Typography variant="headerSmall">Quick Appraisal</Typography>
                  {canRevaluate && (
                    <div className={'flex gap-2'}>
                      <Button
                        variant={'outline'}
                        size={'sm'}
                        onClick={() => {
                          setUpdateAppraisalId(quickAppraisal?.id ?? '');
                          setSendEmail(true);
                          setValue(quickAppraisal?.valuation ?? 0);
                          setAction(ItemActions.UPDATE_APPRAISAL);
                          setShowModal(true);
                        }}
                      >
                        Update Valuation
                      </Button>
                      <Button
                        variant={'outline'}
                        size={'sm'}
                        onClick={() => {
                          setUpdateAppraisalId(quickAppraisal?.id ?? '');
                          setSendEmail(false);
                          setValue(quickAppraisal?.creditValue ?? 0);
                          setAction(ItemActions.OVERRIDE_CREDIT_VALUE);
                          setShowModal(true);
                        }}
                      >
                        Override Credit Value
                      </Button>
                    </div>
                  )}
                </div>
                <div className="overflow-x-auto shadow-md sm:rounded-lg">
                  <DataList root={quickAppraisal} />
                </div>
              </Card>
            )}
            {!!finalAppraisal && (
              <div>
                <div className={'mb-4 flex flex-col justify-between gap-2'}>
                  <strong>Final Appraisal</strong>
                  {canRevaluate && (
                    <div className={'flex gap-2'}>
                      <Button
                        variant={'outline'}
                        size={'sm'}
                        onClick={() => {
                          setUpdateAppraisalId(finalAppraisal?.id ?? '');
                          setSendEmail(true);
                          setValue(finalAppraisal?.valuation ?? 0);
                          setAction(ItemActions.UPDATE_APPRAISAL);
                          setShowModal(true);
                        }}
                      >
                        Update Appraisal
                      </Button>
                      <Button
                        variant={'outline'}
                        size={'sm'}
                        onClick={() => {
                          setUpdateAppraisalId(finalAppraisal?.id ?? '');
                          setSendEmail(false);
                          setValue(finalAppraisal?.creditValue ?? 0);
                          setAction(ItemActions.OVERRIDE_CREDIT_VALUE);
                          setShowModal(true);
                        }}
                      >
                        Override Credit Value
                      </Button>
                    </div>
                  )}
                </div>
                <div className="overflow-x-auto shadow-md sm:rounded-lg">
                  <DataList root={finalAppraisal} />
                </div>
              </div>
            )}
          </div>
          <ItemEventsHistory itemEvents={item?.itemEvents ?? []} />
        </div>
      ) : (
        <Typography variant={'subHeroLarge'}>{`Item could not be found`}</Typography>
      )}
      <ImageGallery
        isOpen={isViewerOpen}
        images={(item?.presignedPhotoLinks as string[]) ?? []}
        currentIndex={currentImage}
        disableScroll={false}
        closeOnClickOutside={true}
        onClose={closeImageViewer}
      />
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        loading={loadingOther || loadingRevalue || loadingDamage}
        title={getModalTitle()}
        onConfirm={handleModalConfirm}
      >
        {
          {
            [ItemActions.DAMAGE]: (
              <>
                <DropdownInput
                  options={damageOptions}
                  label={'Select damage'}
                  value={damage?.category}
                  onChange={value => setDamage({ ...damage, category: value as DamageRecordCategory })}
                />
                <TextAreaField
                  defaultValue={''}
                  onChange={value => setDamage({ ...damage, description: value.target.value })}
                  label={'Note'}
                />
              </>
            ),
            [ItemActions.ADD_CUSTODY_EVENT]: (
              <DropdownInput
                options={itemCustodyEvents}
                label={'You must select a new event'}
                value={newCustodyEvent || ''}
                onChange={setNewCustodyEvent}
              />
            ),
            [ItemActions.REJECT]: (
              <DropdownInput
                options={itemDenyReasons}
                label={'You must provide a reason for rejection'}
                value={rejectReason || ''}
                onChange={setRejectReason}
              />
            ),
            [ItemActions.UPDATE_APPRAISAL]: (
              <>
                <div className={'flex items-center gap-x-2'}>
                  <Checkbox title={'Send email'} checked={sendEmail} onChange={handleSendEmailChange} />
                  <Typography variant={'body'} className={'text-black'}>
                    Send email
                  </Typography>
                </div>
                <CurrencyField onChange={e => setValue(Number(e.target.value))} value={value} label={'Valuation'} />
              </>
            ),
            [ItemActions.OVERRIDE_CREDIT_VALUE]: (
              <>
                <div className={'flex items-center gap-x-2'}>
                  <Checkbox title={'Send email'} checked={sendEmail} onChange={handleSendEmailChange} />
                  <Typography variant={'body'} className={'text-black'}>
                    Send email
                  </Typography>
                </div>
                <CurrencyField onChange={e => setValue(Number(e.target.value))} value={value} label={'Credit Value'} />
              </>
            ),
          }[action]
        }
      </Modal>
    </div>
  );
}
