import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Typography } from '@pesto/ui';
import { TextField } from '@pesto/ui/components/Forms/TextField';
import { humanize } from '@pesto/utils';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import type { StatementBloomResponse } from '../../../__generated__/graphql/api';

const setRulesSchema = z.object({
  accountId: z.string(),
  actualMonthlyPayment: z.number(),
  amountPastDue: z.number(),
  creditLimit: z.number(),
  currentBalance: z.number(),
  daysDelinquent: z.number(),
  highestBalance: z.number(),
  lastPaymentDate: z.string(),
  scheduledMonthlyPayment: z.number(),
  statementDate: z.string(),
  statementIdentifier: z.string(),
});

interface Props {
  onSubmitted: (data: StatementBloomResponse) => void;
  defaultValue: StatementBloomResponse;
}

const arrayOfFields = [
  'accountId',
  'actualMonthlyPayment',
  'amountPastDue',
  'creditLimit',
  'currentBalance',
  'daysDelinquent',
  'highestBalance',
  'lastPaymentDate',
  'scheduledMonthlyPayment',
  'statementDate',
  'statementIdentifier',
];

export const UpdateStatementToBloomForm = (props: Props) => {
  const { onSubmitted, defaultValue } = props;
  const [editMode, setEditMode] = React.useState(false);
  const { handleSubmit, watch, reset, setValue } = useForm<StatementBloomResponse>({
    mode: 'onChange',
    // TODO: fix this type issue
    resolver: yupResolver(setRulesSchema) as any,
    defaultValues: {
      ...defaultValue,
    },
  });
  const handleCancel = () => {
    reset(defaultValue);
    setEditMode(false);
  };
  const handleSave = (data: StatementBloomResponse) => {
    // setError('active', { type: 'manual', message: 'This is a manual error' });
    onSubmitted(data);
  };

  const formButtons = (
    <div className="flex gap-4">
      {editMode ? (
        <div className="flex flex-col gap-2">
          <div className="text-danger">{/*<ErrorMessage errors={errors} name={'active'} />*/}</div>
          <div className="flex gap-4">
            <Button type="submit" color="primary">
              Update statement
            </Button>
            <Button type="button" onClick={handleCancel} variant="outline" color="primary">
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <Button
          type="button"
          onClick={e => {
            e.stopPropagation();
            setEditMode(true);
          }}
          variant="outline"
          color="primary"
        >
          Edit statement
        </Button>
      )}
    </div>
  );

  return (
    <div className="mx-auto w-full max-w-4xl overflow-hidden bg-white p-4 shadow sm:rounded-lg">
      <div className="flex flex-col gap-4">
        <Typography variant="headerSmall">Update Statement</Typography>
        <form className="flex flex-col gap-4" onSubmit={handleSubmit(handleSave)}>
          {formButtons}
          {arrayOfFields.map((field, index) => {
            const humanReadableLabel = humanize(field);
            return (
              <TextField
                key={index}
                name={field}
                // TODO: fix this type issue
                value={watch(field as any) || ''}
                // TODO: fix this type issue
                onChange={e => setValue(field as any, e.target.value)}
                label={humanReadableLabel}
                disabled={!editMode}
              />
            );
          })}
          {formButtons}
        </form>
      </div>
    </div>
  );
};
