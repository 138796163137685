import { cn } from '@pesto/utils';
import type { FC } from 'react';

import { Label } from '../Label';
import { Typography } from '../Typography';

import type { InputPestoProps } from './types/Input.types';

type TextFieldWrapperProps = Omit<InputPestoProps, 'readonly'> & {
  children: React.ReactNode;
};

export const TextFieldWrapper: FC<TextFieldWrapperProps> = props => {
  const { className, children, label, error } = props;
  return (
    <div className={cn('flex flex-col gap-2', className)}>
      {label && <Label>{label}</Label>}
      {children}
      {error && <Typography className="text-destructive">{error}</Typography>}
    </div>
  );
};
