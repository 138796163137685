import { CalendarDaysIcon, CircleStackIcon, CreditCardIcon, UserCircleIcon } from '@heroicons/react/20/solid';
import { Badge } from '@pesto/ui';
import { centsToFormattedDollars, formatDate } from '@pesto/utils';
import { MONEY_MULTIPLIER } from '@pesto/utils/constants/pestoConstants';
import { Link } from 'react-router-dom';

import type { Card } from '../__generated__/graphql/api';
import { routes } from '../constants/routes';

interface CreditCardComponentProps {
  card: Card;
}

export const CreditCardComponent = (props: CreditCardComponentProps) => {
  const { card } = props;
  const formattedCreditLimit = centsToFormattedDollars((card.creditLimit || 0) * MONEY_MULTIPLIER);
  const displayName =
    card.user?.firstName && card.user?.lastName ? `${card.user?.firstName} ${card.user?.lastName}` : 'Unknown';
  const cardIssueDate = formatDate(card.insertedAt);

  const cardStatusTag = {
    not_activated: <Badge variant="default">Not activated</Badge>,
    active: <Badge variant="secondary">Active</Badge>,
    blocked: <Badge variant="warning">Blocked</Badge>,
    lost: <Badge variant="warning">Lost</Badge>,
    stolen: <Badge variant="warning">Stolen</Badge>,
    closed: <Badge variant="destructive">Closed</Badge>,
    default: <Badge variant="default">Unknown</Badge>,
  };

  return (
    <div className="lg:col-start-3 lg:row-end-1">
      <h2 className="sr-only">Credit card summary</h2>
      <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
        <dl className="flex flex-wrap">
          <div className="flex-auto pl-6 pt-6">
            <dt className="text-sm font-semibold leading-6 text-gray-900">Credit limit</dt>
            <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">{formattedCreditLimit}</dd>
          </div>
          <div className="flex-none self-end px-6 pt-4">
            <dt className="sr-only">Status</dt>
            {cardStatusTag[(card.status as keyof typeof cardStatusTag) || 'default']}
          </div>
          <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
            <dt className="flex-none">
              <span className="sr-only">Client</span>
              <UserCircleIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <Link
              className="text-action hover:text-actionSoft text-sm font-medium leading-6 hover:cursor-pointer hover:underline"
              to={routes.users + '/' + card.user?.id}
            >
              {displayName}
            </Link>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Inserted at</span>
              <CalendarDaysIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd className="text-sm leading-6 text-gray-500">
              <time dateTime="2023-01-31">{cardIssueDate}</time>
            </dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Status</span>
              <CreditCardIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd className="text-sm leading-6 text-gray-500">{card.referenceId}</dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Status</span>
              <CircleStackIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd className="text-sm leading-6 text-gray-500">{card.id}</dd>
          </div>
        </dl>
        <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
          <Link to={`${routes.cards}/${card.id}`} className="text-action text-sm font-semibold leading-6">
            Card details <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </div>
    </div>
  );
};
