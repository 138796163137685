import { useState } from 'react';
import type { DateRange } from 'react-day-picker';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { DateRangePicker } from '../../../../components/DateRangePicker';
import { useQueryString } from '../../hooks/useQueryString';

export const DateRangeFilter = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const { createQueryString } = useQueryString(searchParams);
  const initialDateValue: DateRange = {
    from: searchParams.get('from') ? new Date(String(searchParams.get('from'))) : undefined,
    to: searchParams.get('to') ? new Date(String(searchParams.get('to'))) : undefined,
  };
  const [dateValue, setDateValue] = useState<DateRange | undefined>(initialDateValue);

  const handleDateChange = (date: DateRange | undefined) => {
    setDateValue(date);
    const fromDate = date?.from?.toISOString().split('T')[0] || null;
    const toDate = date?.to?.toISOString().split('T')[0] || null;
    navigate(`${pathname}?${createQueryString({ from: fromDate, to: toDate })}`, { replace: true });
  };

  return <DateRangePicker dateValue={dateValue} onDateChange={handleDateChange} enablePresetOptions />;
};
