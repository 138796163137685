import { Button, Card, Typography } from '@pesto/ui';

import creditEngine from './assets/credit-engine.png';

export const CreditEngine = () => {
  return (
    <div className="grid">
      <div className="col-start-1 row-start-1 blur-md">
        <img src={creditEngine} alt="Credit Engine" className="h-full w-full object-cover" />
      </div>
      <div className="z-[1] col-start-1 row-start-1 flex items-center justify-center">
        <Card className="flex flex-col gap-4 rounded-xl bg-gradient-to-r from-purple-600 to-blue-600 p-10 text-white opacity-90">
          <div className="space-y-2">
            <Typography variant="headerLarge">Upgrade to Pro</Typography>
            <Typography variant="body">Unlock more features with our Pro plan.</Typography>
          </div>
          <Button>Upgrade</Button>
        </Card>
      </div>
    </div>
  );
};
