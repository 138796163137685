import { REGEXP_ONLY_DIGITS } from 'input-otp';

import { InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot } from './InputOtp';

interface OtpProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
}
export const SixDigitOtp = (props: OtpProps) => {
  const { value, onChange, className } = props;
  return (
    <InputOTP pattern={REGEXP_ONLY_DIGITS} maxLength={6} value={value} onChange={onChange} className={className}>
      <InputOTPGroup>
        <InputOTPSlot index={0} />
        <InputOTPSlot index={1} />
        <InputOTPSlot index={2} />
      </InputOTPGroup>
      <InputOTPSeparator />
      <InputOTPGroup>
        <InputOTPSlot index={3} />
        <InputOTPSlot index={4} />
        <InputOTPSlot index={5} />
      </InputOTPGroup>
    </InputOTP>
  );
};
