import { Button, Sheet, SheetContent, SheetTrigger } from '@pesto/ui';
import { Menu } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { ExpandableNav } from './ExpandableNav';
import type { NavigationProps } from './Navigation.types';

export const MobileNavigation = (props: NavigationProps) => {
  const { links, homeLink } = props;
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);
  const handleLinkClick = () => {
    toggleOpen();
  };
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button variant="outline" onlyIcon className="shrink-0 md:hidden" onClick={toggleOpen}>
          <Menu className="h-5 w-5" />
          <span className="sr-only">Toggle navigation menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="flex flex-col" title="Navigation" description="mobile main navigation">
        <nav className="grid gap-2 text-lg font-medium">
          <Link to={homeLink.href} className="flex items-center gap-2 text-lg font-semibold">
            <homeLink.icon className="h-6 w-6" />
            {homeLink.name}
          </Link>

          <ExpandableNav links={links} onLinkClick={handleLinkClick} />
        </nav>
      </SheetContent>
    </Sheet>
  );
};
