import { Button, Typography } from '@pesto/ui';
import type { DataTableFilterField } from '@pesto/ui/components/DataTable/DataTable.types';
import { DataTableColumnHeader } from '@pesto/ui/components/DataTable/DataTableColumnHeader';
import { BooleanTag } from '@pesto/ui/features/SmartTable/components/BooleanTag';
import {
  generateSelectableFilterOptions,
  setColumnFilterAndSortKey,
} from '@pesto/ui/features/SmartTable/utils/helpers';
import { centsToFormattedDollars, formatDate } from '@pesto/utils';
import { MONEY_MULTIPLIER } from '@pesto/utils/constants/pestoConstants';
import type { ColumnDef, TableState } from '@tanstack/react-table';

import type { Card } from '../../../__generated__/graphql/api';

const columnName = {
  id: setColumnFilterAndSortKey('idSortDirection', 'idSearch'),
  insertedAt: setColumnFilterAndSortKey('', 'insertedAtSortDirection'),
  creditLimit: setColumnFilterAndSortKey('', 'creditLimitSortDirection'),
  active: setColumnFilterAndSortKey('active', ''),
  status: setColumnFilterAndSortKey('', 'statusSortDirection'),
  referenceId: setColumnFilterAndSortKey('', 'referenceIdSortDirection'),
  firstName: setColumnFilterAndSortKey('userFirstNameSearch', 'firstNameSortDirection'),
  lastName: setColumnFilterAndSortKey('userLastNameSearch', 'lastNameSortDirection'),
  email: setColumnFilterAndSortKey('userEmailSearch', 'emailSortDirection'),
  friendsAndFamily: setColumnFilterAndSortKey('userIsFriendsAndFam', ''),
};

export const useCardsListConfig = () => {
  const cardsFilterFields: DataTableFilterField<any>[] = [
    {
      label: 'CardId ID',
      value: columnName.id,
      placeholder: 'Card ID search',
    },
    {
      label: 'Active',
      value: columnName.active,
      options: generateSelectableFilterOptions({
        true: true,
        false: false,
      }),
    },
    {
      label: 'Reference ID',
      value: columnName.referenceId,
      placeholder: 'Reference ID',
    },
    // {
    //   label: 'First Name',
    //   value: columnName.firstName,
    //   placeholder: 'First Name',
    // },
    // {
    //   label: 'Last Name',
    //   value: columnName.lastName,
    //   placeholder: 'Last Name',
    // },
    // {
    //   label: 'Email',
    //   value: columnName.email,
    //   placeholder: 'Email',
    // },
    {
      label: 'Friends and Family',
      value: columnName.friendsAndFamily,
      options: generateSelectableFilterOptions({
        true: true,
        false: false,
      }),
    },
  ];

  const getCardsColumns = (handleRowClicked: (row: Card) => void): ColumnDef<Card>[] => [
    {
      id: 'view',
      header: 'Action',
      accessorKey: 'id',
      enablePinning: true,
      cell: row => (
        <Button onClick={() => handleRowClicked(row.cell.row.original)} variant={'outline'}>
          View
        </Button>
      ),
    },
    {
      id: columnName.id,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Card ID" />,
      accessorFn: row => row.id,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {row.cell.getValue() as string}
        </Typography>
      ),
    },
    {
      id: columnName.insertedAt,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Created" />,
      accessorFn: row => row.insertedAt,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {formatDate(row.cell.getValue() as string)}
        </Typography>
      ),
    },
    {
      id: columnName.creditLimit,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Credit Limit" />,
      accessorFn: row => row.creditLimit,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {`${centsToFormattedDollars((row.cell.getValue() as number) * MONEY_MULTIPLIER) || 'N/A'}`}
        </Typography>
      ),
    },
    {
      id: columnName.active,
      header: 'Active',
      accessorFn: row => row.active,
      cell: row => <BooleanTag value={row.cell.getValue() as boolean} />,
    },
    {
      id: columnName.status,
      header: 'Status',
      accessorFn: row => row.status,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {row.cell.getValue() as string}
        </Typography>
      ),
    },
    {
      id: columnName.referenceId,
      header: 'Reference ID',
      accessorFn: row => row.referenceId,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {row.cell.getValue() as string}
        </Typography>
      ),
    },
    {
      id: columnName.firstName,
      header: 'First Name',
      accessorFn: row => row.user?.firstName,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {row.cell.getValue() as string}
        </Typography>
      ),
    },
    {
      id: columnName.lastName,
      header: 'Last Name',
      accessorFn: row => row.user?.lastName,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {row.cell.getValue() as string}
        </Typography>
      ),
    },
    {
      id: columnName.email,
      header: 'Email',
      accessorFn: row => row.user?.email,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {row.cell.getValue() as string}
        </Typography>
      ),
    },

    {
      id: columnName.friendsAndFamily,
      header: 'Friends and Family',
      accessorFn: row => row.user?.isFriendsAndFam,
      cell: row => <BooleanTag value={row.cell.getValue() as boolean} />,
    },
  ];

  const initialTableState: Partial<TableState> = {
    columnPinning: { left: ['view'] },
    sorting: [{ id: 'insertedAtSortDirection', desc: true }],
  };

  return { cardsFilterFields, getCardsColumns, initialTableState };
};
