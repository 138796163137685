export const startCase = (str?: string | null) => {
  if (!str) return '';
  return str.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
};
/**
 * humanize a string
 * - delete leading underscores, if any.
 * - replace underscores with spaces, if any.
 * - capitalize the first character of each word.
 *
 * for example: "some_string" to "Some String"
 */
export const humanize = (value: string) => {
  try {
    const lowerCase = value?.toLowerCase();
    return startCase(lowerCase);
  } catch (e) {
    return value;
  }
};

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const splitCamelCaseToString = (s?: string) => {
  return s?.split(/(?=[A-Z])/)?.join(' ');
};
