import type { ComponentProps } from 'react';
import { useCallback, useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';

type Maybe<T> = T | null;
type ImageViewerType = ComponentProps<typeof ImageViewer>;

interface ImageGalleryProps extends Omit<ImageViewerType, 'src'> {
  images: string | Maybe<string>[];
  isOpen: boolean;
  openIndex?: number;
  onClose: () => void;
}

export const ImageGallery = (props: ImageGalleryProps) => {
  const { images, isOpen, openIndex = 0, onClose } = props;
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(openIndex);
  const closeImageViewer = useCallback(() => {
    setCurrentImageIndex(0);
    onClose();
  }, [onClose]);

  if (typeof images === 'string') {
    return null;
  }

  const newImages = images.map(image => {
    if (typeof image === 'string') {
      return image;
    }
    return '';
  });

  return (
    <>
      {isOpen && (
        <ImageViewer
          {...props}
          src={newImages}
          currentIndex={currentImageIndex}
          backgroundStyle={{
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            zIndex: 99999,
          }}
          closeComponent={<div>X</div>}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </>
  );
};
