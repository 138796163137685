import { cn } from '@pesto/utils';
import { Cross2Icon, PlusIcon } from '@radix-ui/react-icons';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button } from '../../../../components/Button';
import type { DataTableFilterOption, DataTableToolbarProps } from '../../../../components/DataTable/DataTable.types';
import { DataTableViewOptions } from '../../../../components/DataTable/DataTableViewOptions';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { ScrollArea } from '../../../../components/ScrollArea';
import { Typography } from '../../../../components/Typography';
import { getColumnFilterKey } from '../../utils/helpers';
import { DataTableFilterCombobox } from '../DataTableFilterCombobox';

import { CustomFilterItem } from './CustomFilterItem';
import { DataTableFilterItem } from './DataTableFilterItem';

export function AdvancedDataTableToolbar<TData>({
  title,
  amountOfRows,
  table,
  filterFields = [],
  children,
  className,
  loading,
  customFilters,
}: DataTableToolbarProps<TData>) {
  const [searchParams] = useSearchParams();
  const isFiltered = table.getState().columnFilters.length > 0;
  const options = useMemo<DataTableFilterOption<TData>[]>(() => {
    return filterFields.map(field => {
      return {
        id: crypto.randomUUID(),
        label: field.label,
        value: field.value,
        options: field.options ?? [],
        isCustomCheckboxFilter: field.isCustomCheckboxFilter,
      };
    });
  }, [filterFields]);

  const initialSelectedOptions = useMemo(() => {
    return options
      .filter(option => searchParams.has(getColumnFilterKey(option.value as string)))
      .map(option => {
        const value = searchParams.get(String(getColumnFilterKey(option.value)) as string);

        return {
          ...option,
          filterValues: value?.split('.') ?? [],
        };
      });
  }, [options, searchParams]);

  const [openCombobox, setOpenCombobox] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState<DataTableFilterOption<TData>[]>(initialSelectedOptions);

  const onFilterComboboxItemSelect = () => {
    setOpenCombobox(true);
  };

  return (
    <div className={cn('flex w-full flex-col space-y-2.5', className)}>
      <div className="flex flex-col justify-around gap-2 sm:flex-row">
        <div className="flex flex-col gap-2">
          {title && <Typography variant="headerSmall">{title}</Typography>}
          {loading ? <LoadingSpinner /> : amountOfRows && <Typography variant="body">{amountOfRows} rows</Typography>}
        </div>
        <div className="sm:ml-auto">
          <ScrollArea className="w-full" orientation="horizontal">
            <div className="flex items-center gap-2 p-2">
              {children}

              <DataTableFilterCombobox
                options={options.filter(
                  option => !selectedOptions.some(selectedOption => selectedOption.value === option.value),
                )}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                onSelect={onFilterComboboxItemSelect}
              />

              <DataTableViewOptions table={table} />
            </div>
          </ScrollArea>
        </div>
      </div>
      <ScrollArea className="w-full" orientation="horizontal">
        <div>{customFilters}</div>
      </ScrollArea>
      <ScrollArea className="w-full" orientation="horizontal">
        <div className={cn('flex items-center gap-2')}>
          {selectedOptions
            .filter(option => !option.isMulti)
            .map(selectedOption => {
              if (selectedOption.isCustomCheckboxFilter)
                return (
                  <CustomFilterItem
                    key={String(selectedOption.value)}
                    selectedOption={selectedOption}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    defaultOpen={openCombobox}
                  />
                );
              return (
                <DataTableFilterItem
                  key={String(selectedOption.value)}
                  table={table}
                  selectedOption={selectedOption}
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                  defaultOpen={openCombobox}
                />
              );
            })}

          {options.length > 0 && options.length > selectedOptions.length ? (
            <DataTableFilterCombobox
              options={options}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              onSelect={onFilterComboboxItemSelect}
            >
              <Button
                variant="outline"
                size="sm"
                role="combobox"
                className="h-8 rounded-md"
                onClick={() => setOpenCombobox(true)}
              >
                <PlusIcon className="size-4 opacity-50" aria-hidden="true" />
                Add filter
              </Button>
            </DataTableFilterCombobox>
          ) : null}
          {isFiltered && (
            <Button
              aria-label="Reset filters"
              variant="ghost"
              className="h-8 px-2 lg:px-3"
              onClick={() => table.resetColumnFilters()}
            >
              Reset
              <Cross2Icon className="ml-2 size-4" aria-hidden="true" />
            </Button>
          )}
        </div>
      </ScrollArea>
    </div>
  );
}
