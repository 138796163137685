import { useAuth } from '@pesto/features';
import { useLayoutEffect } from 'react';

export const LoginCallback = () => {
  const { parseAuthToken } = useAuth();
  useLayoutEffect(() => {
    parseAuthToken().then(() => {
      return window.location.replace('/dashboard');
    });
  }, [parseAuthToken]);

  return (
    <div className={'flex h-screen flex-col items-center justify-center'}>
      <div className={'h-32 w-32 animate-spin rounded-full border-b-2 border-t-2 border-gray-900'}></div>
    </div>
  );
};
export default LoginCallback;
