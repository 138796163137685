import { Button, ScrollArea, Typography } from '@pesto/ui';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@pesto/ui/components/Accordion';
import { DetailRow } from '@pesto/ui/components/DetailRow';
import { Modal } from '@pesto/ui/components/Modal';
import { formatDate } from '@pesto/utils';
import { MONEY_MULTIPLIER } from '@pesto/utils/constants/pestoConstants';
import clsx from 'clsx';
import { useState } from 'react';

import type { Transaction } from '../../__generated__/graphql/api';
import { useCashSecuredReturnMutation } from '../../__generated__/graphql/api';

import { renderDetails } from './utils/renderPaymentDetails';

interface SinglePaymentInfoProps {
  payment: Transaction;
  last?: boolean;
  onRefundSuccess?: () => void;
}

export const SinglePaymentInfo = (props: SinglePaymentInfoProps) => {
  const { payment, last = false, onRefundSuccess } = props;

  const additionalDetails = JSON.parse(payment.additionalDetails || 'null');
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [refundPayment, { loading }] = useCashSecuredReturnMutation();
  const canRefund =
    payment.status === 'success' && payment.paymentType === 'PinlessDebit' && !payment.refundConfirmation;
  const handleRefund = (id: string | undefined | null) => {
    if (!id) return null;

    refundPayment({
      variables: {
        transactionId: id,
      },
    }).then(() => {
      setIsOpenConfirm(false);
      onRefundSuccess?.();
    });
  };

  const detailsData = {
    Message: payment.message ?? '-',
    'Client Message': payment.clientMessage ?? '-',
    'Client Status': payment.clientStatus ?? '-',
    Bin: payment.bin ?? '-',
    'Last Four': payment.lastFour ?? '-',
    'Exp Date': payment.expDate ?? '-',
    'Card Brand': payment.cardBrand ?? '-',
    Details: renderDetails(payment.details),
    'Refund Confirmation': payment.refundConfirmation,
    'Auth Code': payment.authCode,
    'Avs Code': payment.avsCode,
    'Batch Number': payment.batchNumber,
    'Additional Details': <pre>{JSON.stringify(additionalDetails, null, 2)}</pre>,
  };

  const rootStyle = clsx('bg-white flex flex-col justify-between', {
    'border-b': !last,
  });
  return (
    <>
      <div className={rootStyle}>
        {canRefund && (
          <Button
            onClick={() => {
              setIsOpenConfirm(true);
            }}
            color={'destructive'}
          >
            Refund This Payment
          </Button>
        )}
        <DetailRow title={'Inserted At'} value={formatDate(payment.insertedAt)} />
        <DetailRow title={'Status'} value={payment.status ?? 'failure'} />
        <DetailRow title={'Amount'} value={payment.amount} />
        {payment.feeAmount && payment.rawAmount ? (
          <div className={'flex'}>
            <DetailRow
              title={'Amount without fee'}
              value={((payment.rawAmount - payment.feeAmount) / MONEY_MULTIPLIER).toFixed(2)}
            />
            <DetailRow title={'Fee Amount'} value={(payment.feeAmount / MONEY_MULTIPLIER).toFixed(2)} />
          </div>
        ) : null}
        <div className="whitespace-nowrap px-4 py-1 font-medium text-gray-900 dark:text-gray-400">
          Confirmation
          <div className={'text-black'}>{payment.confirmation}</div>
        </div>
        <div className="whitespace-nowrap px-4 py-1 font-medium text-gray-900 dark:text-gray-400">
          Payment Type
          <div className={'text-black'}>{payment.paymentType}</div>
        </div>
        <div className="px-4 py-1">
          <Accordion type="single" collapsible>
            <AccordionItem value={`item-1`} className="px-4 py-4">
              <AccordionTrigger>
                <Typography variant={'body'}>Show more details</Typography>
              </AccordionTrigger>
              <AccordionContent>
                <ScrollArea orientation="both">
                  {Object.keys(detailsData).map(key => {
                    return <DetailRow key={key} title={key} value={detailsData[key as keyof typeof detailsData]} />;
                  })}
                </ScrollArea>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
      <Modal
        isOpen={isOpenConfirm}
        onClose={() => {
          setIsOpenConfirm(false);
        }}
      >
        <Typography variant={'headerMedium'}>Confirm Refund</Typography>
        <Typography variant={'body'}>Are you sure you want to refund this payment?</Typography>
        <div className={'flex gap-6'}>
          <Button
            onClick={() => {
              setIsOpenConfirm(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleRefund(payment?.id);
            }}
            disabled={loading}
            color={'destructive'}
          >
            Refund
          </Button>
        </div>
      </Modal>
    </>
  );
};
