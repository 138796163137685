import type {
  ApolloCache,
  ApolloError,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  MutationTuple,
} from '@apollo/client';

type MutationFunction = (
  options?: MutationFunctionOptions<any, any, DefaultContext, ApolloCache<any>>,
) => Promise<FetchResult<any>>;

interface ActionConfig {
  mutation: MutationFunction;
  loading: boolean;
  error: ApolloError | undefined;
}

export const useApplicationActions = (mutations: Record<string, MutationTuple<any, any>>) => {
  const actionsMap = Object.entries(mutations).reduce(
    (acc, [key, [mutationFn, { loading, error }]]) => ({
      ...acc,
      [key]: {
        mutation: (options?: MutationFunctionOptions<any, any>) => mutationFn(options),
        loading,
        error,
      },
    }),
    {} as Record<string, ActionConfig>,
  );

  const isLoading = Object.values(actionsMap).some(action => action.loading);
  const errors = Object.values(actionsMap).map(action => action.error);

  const executeAction = async (action: any): Promise<boolean> => {
    if (action in actionsMap) {
      const result = await actionsMap[action].mutation();
      return !result.errors;
    }
    return false;
  };

  return {
    executeAction,
    isLoading,
    errors,
  };
};
