import { useListQuery } from '@pesto/hooks';
import { DropdownInput } from '@pesto/ui/components/DropdownInput';
import { Modal } from '@pesto/ui/components/Modal';
import { SmartTable } from '@pesto/ui/features/SmartTable/SmartTable';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useItemReviewMutation, useListItemsQuery, type Item } from '../../__generated__/graphql/api';
import { itemDenyReasons } from '../../components/ItemCard';
import { routes } from '../../constants/routes';

import { useItemsListConfig } from './hooks/useItemsListConfig';

export const Items = () => {
  const [showModal, setShowModal] = useState(false);
  const [rejectReason, setRejectReason] = useState<string | undefined | null>(null);
  const [rejectedItemId, setRejectedItemId] = useState(null as string | null);
  const [itemReviewMutation, { loading: loadingOther }] = useItemReviewMutation();

  const navigate = useNavigate();

  const { data, loadingOrReFetching } = useListQuery({
    queryHook: useListItemsQuery,
  });
  const { getItemsColumns, itemsFilterFields, initialTableState } = useItemsListConfig();
  const rows = data?.listItems?.items ?? [];
  const amountOfRows = data?.listItems?.pagination.totalRecords;

  const handleRowClicked = (row: Item) => {
    navigate(`${routes.items}/${row?.id}`);
  };
  const handleRowRejectClicked = (row: Item) => {
    setRejectedItemId(row?.id ?? '');
    setShowModal(true);
  };
  const columns = getItemsColumns(handleRowClicked, handleRowRejectClicked);

  async function handleItemReject() {
    await itemReviewMutation({
      variables: {
        itemId: rejectedItemId ?? '',
        valuation: 0,
        rejectionReason: rejectReason || '',
      },
    });
    setShowModal(false);
    setRejectedItemId(null);
    setRejectReason(null);
  }

  return (
    <>
      <SmartTable
        title="Items"
        amountOfRows={amountOfRows}
        rows={rows}
        columns={columns}
        filterFields={itemsFilterFields}
        loading={loadingOrReFetching}
        pageCount={data?.listItems?.pagination?.totalPages}
        initialState={initialTableState}
        enableDateRangeFilter
      />
      <Modal
        title={'Reject Item'}
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setRejectReason('');
        }}
        onConfirm={handleItemReject}
        loading={loadingOther}
      >
        <DropdownInput
          options={itemDenyReasons}
          label={'You must provide a reason for rejection'}
          value={rejectReason || ''}
          onChange={setRejectReason}
        />
      </Modal>
    </>
  );
};
