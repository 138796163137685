import { MiniStepperStep } from './MiniStepperStep';

export type MiniStepperStepType = {
  key?: string;
  complete?: boolean;
  description: string;
  attention?: boolean;
};

export interface MiniStepperProps {
  steps: MiniStepperStepType[];
}

export const MiniStepper = (props: MiniStepperProps) => {
  const { steps } = props;
  let stepNumber = 0;

  steps.forEach((step, index) => {
    if (step.complete) stepNumber = index;
  });

  return (
    <div>
      <div className="flex flex-row">
        {steps.map((step, index) => (
          <MiniStepperStep
            key={step.key || `${step.description}${index}`}
            stepNumber={stepNumber}
            step={step}
            index={index}
            nextStep={steps[index + 1]}
          />
        ))}
      </div>
    </div>
  );
};
