import type { JsonObject } from '@pesto/utils';
import { humanize } from '@pesto/utils';

export const renderDetails = (detailsJSON: string) => {
  try {
    if (detailsJSON === 'null') return null;
    const details: JsonObject = JSON.parse(detailsJSON);
    return (
      <div className={'flex flex-col'}>
        {Object.keys(details).map((key, index) => {
          const detail = details[key];
          return (
            <div key={index} className={'flex gap-1'}>
              <div className={'text-primary/60'}>{humanize(key)}:</div>
              <div className={'text-primary'}>{detail}</div>
            </div>
          );
        })}
      </div>
    );
  } catch (e) {
    console.error(e);
    return null;
  }
};
