import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../../../components/Tooltip';

import type { MiniStepperStepType } from './MiniStepper';
import { MiniStepperConnector } from './MiniStepperConnector';

export interface MiniStepperStepProps {
  step: MiniStepperStepType;
  nextStep?: MiniStepperStepType;
  stepNumber: number;
  index: number;
}

export const MiniStepperStep = ({ stepNumber, step, nextStep, index }: MiniStepperStepProps) => {
  const { attention, description } = step;
  const complete = index <= stepNumber;
  const colorClasses = complete
    ? 'bg-green-500 outline-green-700'
    : attention
      ? 'bg-yellow-500 outline-yellow-700'
      : 'bg-white outline-gray-500';

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipContent>
          <>
            {description}
            {attention && !complete && (
              <>
                <br />
                <div className="flex w-full justify-around text-yellow-500">
                  <span>[ACTION REQUIRED]</span>
                </div>
              </>
            )}
            {complete && (
              <>
                <br />
                <div className="flex w-full justify-around text-green-500">
                  <span>[COMPLETE]</span>
                </div>
              </>
            )}
          </>
        </TooltipContent>
        <TooltipTrigger>
          <div
            className={`${colorClasses} mx-1 my-2 h-3 w-3 justify-center rounded-full pb-2 text-center align-middle text-xs outline outline-4 duration-200 ease-out hover:scale-150`}
          />
        </TooltipTrigger>
        <MiniStepperConnector stepNumber={stepNumber} index={index} priorStep={step} nextStep={nextStep} />
      </Tooltip>
    </TooltipProvider>
  );
};
