import { cn } from '@pesto/utils';
import type { ReactNode } from 'react';
import React from 'react';

type Hero = 'heroLarge' | 'heroMedium' | 'heroSmall';
type SubHero = 'subHeroLarge' | 'subHeroMedium' | 'subHeroSmall';
type Header = 'headerLarge' | 'headerMedium' | 'headerSmall';
type Body = 'bodyLarge' | 'body' | 'bodySmall';
type Caption = 'caption' | 'captionSmall';

interface TypographyProps {
  children: ReactNode;
  className?: string;
  variant?: Hero | SubHero | Header | Body | Caption;
}
export const Typography: React.FC<TypographyProps> = ({ children, ...props }) => {
  const { className, variant = 'body' } = props;
  const heroTypography = cn('font-sans', {
    'text-[68px] font-bold': variant === 'heroLarge',
    'text-[52px] font-bold': variant === 'heroMedium',
    'text-[44px] font-bold': variant === 'heroSmall',
  });
  const subHeroTypography = cn('font-sans', {
    'text-[40px] font-bold': variant === 'subHeroLarge',
    'text-[40px] font-medium': variant === 'subHeroMedium',
    'text-[30px] font-medium': variant === 'subHeroSmall',
  });

  const headerTypography = cn('font-sans', {
    'text-[28px] font-bold': variant === 'headerLarge',
    'text-[24px] font-bold': variant === 'headerMedium',
    'text-[18px] font-bold': variant === 'headerSmall',
  });
  const bodyTypography = cn('font-sans', {
    'text-[18px] font-normal': variant === 'bodyLarge',
    'text-[15px] font-normal': variant === 'body',
    'text-[12px] font-normal leading-[16px]': variant === 'bodySmall',
  });
  const captionTypography = cn('font-sans', {
    'text-[14px] font-light': variant === 'caption',
    'text-[8px] font-light': variant === 'captionSmall',
  });

  const typographyStyle = cn(
    heroTypography,
    subHeroTypography,
    headerTypography,
    bodyTypography,
    captionTypography,
    className,
  );
  return <div className={typographyStyle}>{children}</div>;
};
