import { Button, Typography } from '@pesto/ui';
import type { DataTableFilterField } from '@pesto/ui/components/DataTable/DataTable.types';
import { setColumnFilterAndSortKey } from '@pesto/ui/features/SmartTable/utils/helpers';
import type { ColumnDef, TableState } from '@tanstack/react-table';
import type { ReactNode } from 'react';

import type { AdTrackingDetails } from '../../../../__generated__/graphql/api';
import { AdTrackingNonNullFilter } from '../components/AdTrackingNonNullFilter';

const columnIdNames = {
  id: 'adTrackingId',
  user: 'user',
  affiliateRef: setColumnFilterAndSortKey('affiliateRefSearch', ''),
  gclid: setColumnFilterAndSortKey('gclidSearch', ''),
  fbclid: setColumnFilterAndSortKey('fbclidSearch', ''),
  msclkid: setColumnFilterAndSortKey('msclkidSearch', ''),
  nerdwalletRef: setColumnFilterAndSortKey('nerdwalletRefSearch', ''),
  lgLeadId: setColumnFilterAndSortKey('lgLeadIdSearch', ''),
  tmSubid1: setColumnFilterAndSortKey('tmSubid1Search', ''),
  flexRefId: setColumnFilterAndSortKey('flexRefIdSearch', ''),
  mdAffiliateId: setColumnFilterAndSortKey('mdAffiliateIdSearch', ''),
  utmCampaign: setColumnFilterAndSortKey('utmCampaignSearch', ''),
  utmContent: setColumnFilterAndSortKey('utmContentSearch', ''),
  utmMedium: setColumnFilterAndSortKey('utmMediumSearch', ''),
  utmSource: setColumnFilterAndSortKey('utmSourceSearch', ''),
};

export const useAdTrackingListConfig = () => {
  const paymentsFilterFields: DataTableFilterField<any>[] = [
    {
      label: 'Affiliate Ref',
      value: columnIdNames.affiliateRef,
      placeholder: 'Affiliate Ref Search',
    },
    {
      label: 'Gclid',
      value: columnIdNames.gclid,
      placeholder: 'Gclid Search',
    },
    {
      label: 'Fbclid',
      value: columnIdNames.fbclid,
      placeholder: 'Fbclid Search',
    },
    {
      label: 'Msclkid',
      value: columnIdNames.msclkid,
      placeholder: 'Msclkid Search',
    },
    {
      label: 'Nerdwallet Ref',
      value: columnIdNames.nerdwalletRef,
      placeholder: 'Nerdwallet Ref Search',
    },
    {
      label: 'Lg Lead Id',
      value: columnIdNames.lgLeadId,
      placeholder: 'Lg Lead Id Search',
    },
    {
      label: 'Tm Subid1',
      value: columnIdNames.tmSubid1,
      placeholder: 'Tm Subid1 Search',
    },
    {
      label: 'Flex Ref Id',
      value: columnIdNames.flexRefId,
      placeholder: 'Flex Ref Id Search',
    },
    {
      label: 'Md Affiliate Id',
      value: columnIdNames.mdAffiliateId,
      placeholder: 'Md Affiliate Id Search',
    },
    {
      label: 'Utm Campaign',
      value: columnIdNames.utmCampaign,
      placeholder: 'Utm Campaign Search',
    },
    {
      label: 'Utm Content',
      value: columnIdNames.utmContent,
      placeholder: 'Utm Content Search',
    },
    {
      label: 'Utm Medium',
      value: columnIdNames.utmMedium,
      placeholder: 'Utm Medium Search',
    },
    {
      label: 'Utm Source',
      value: columnIdNames.utmSource,
      placeholder: 'Utm Source Search',
    },
  ];

  const getPaymentsColumns = (handleRowClicked: (row: AdTrackingDetails) => void): ColumnDef<AdTrackingDetails>[] => [
    {
      id: 'view',
      header: 'Action',
      accessorKey: 'id',
      enablePinning: true,
      cell: row => (
        <Button onClick={() => handleRowClicked(row.cell.row.original)} variant={'outline'}>
          Go To Credit App
        </Button>
      ),
    },

    {
      id: columnIdNames.user,
      header: 'User',
      accessorFn: row => row.user?.id,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },

    {
      id: columnIdNames.id,
      header: 'Ad Tracking Id',
      accessorFn: row => row.id,
      cell: row => <Typography variant={'body'}>{row.cell.getValue() as string}</Typography>,
    },
    {
      id: columnIdNames.affiliateRef,
      header: 'Affiliate Ref',
      accessorFn: row => row.affiliateRef,
      cell: row => <Typography variant={'body'}>{(row.cell.getValue() as string) || '-'}</Typography>,
    },
    {
      id: columnIdNames.gclid,
      header: 'Gclid',
      accessorFn: row => row.gclid,
      cell: row => <Typography variant={'body'}>{(row.cell.getValue() as string) || '-'}</Typography>,
    },
    {
      id: columnIdNames.fbclid,
      header: 'Fbclid',
      accessorFn: row => row.fbclid,
      cell: row => <Typography variant={'body'}>{(row.cell.getValue() as string) || '-'}</Typography>,
    },
    {
      id: columnIdNames.msclkid,
      header: 'Msclkid',
      accessorFn: row => row.msclkid,
      cell: row => <Typography variant={'body'}>{(row.cell.getValue() as string) || '-'}</Typography>,
    },
    {
      id: columnIdNames.nerdwalletRef,
      header: 'Nerdwallet Ref',
      accessorFn: row => row.nerdwalletRef,
      cell: row => <Typography variant={'body'}>{(row.cell.getValue() as string) || '-'}</Typography>,
    },
    {
      id: columnIdNames.lgLeadId,
      header: 'Lg Lead Id',
      accessorFn: row => row.lgLeadId,
      cell: row => <Typography variant={'body'}>{(row.cell.getValue() as string) || '-'}</Typography>,
    },
    {
      id: columnIdNames.tmSubid1,
      header: 'Tm Subid1',
      accessorFn: row => row.tmSubid1,
      cell: row => <Typography variant={'body'}>{(row.cell.getValue() as string) || '-'}</Typography>,
    },
    {
      id: columnIdNames.flexRefId,
      header: 'Flex Ref Id',
      accessorFn: row => row.flexRefId,
      cell: row => <Typography variant={'body'}>{(row.cell.getValue() as string) || '-'}</Typography>,
    },
    {
      id: columnIdNames.mdAffiliateId,
      header: 'Md Affiliate Id',
      accessorFn: row => row.mdAffiliateId,
      cell: row => <Typography variant={'body'}>{(row.cell.getValue() as string) || '-'}</Typography>,
    },
    {
      id: columnIdNames.utmCampaign,
      header: 'Utm Campaign',
      accessorFn: row => row.utmCampaign,
      cell: row => <Typography variant={'body'}>{(row.cell.getValue() as string) || '-'}</Typography>,
    },
    {
      id: columnIdNames.utmContent,
      header: 'Utm Content',
      accessorFn: row => row.utmContent,
      cell: row => <Typography variant={'body'}>{(row.cell.getValue() as string) || '-'}</Typography>,
    },
    {
      id: columnIdNames.utmMedium,
      header: 'Utm Medium',
      accessorFn: row => row.utmMedium,
      cell: row => <Typography variant={'body'}>{(row.cell.getValue() as string) || '-'}</Typography>,
    },
    {
      id: columnIdNames.utmSource,
      header: 'Utm Source',
      accessorFn: row => row.utmSource,
      cell: row => <Typography variant={'body'}>{(row.cell.getValue() as string) || '-'}</Typography>,
    },
  ];

  const initialTableState: Partial<TableState> = {
    columnPinning: { left: ['view'] },
  };
  const customFilter: ReactNode = <AdTrackingNonNullFilter />;
  return { paymentsFilterFields, getPaymentsColumns, initialTableState, customFilter };
};
