import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@pesto/ui';
import { useQueryString } from '@pesto/ui/features/SmartTable/hooks/useQueryString';
import { parseFiltersFromSearchParams } from '@pesto/ui/features/SmartTable/utils/searchParamsFiltersHelper';
import { cn } from '@pesto/utils';
import { CaretSortIcon } from '@radix-ui/react-icons';
import { CheckIcon } from 'lucide-react';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import type { AdNotNullFilters } from '../../../../__generated__/graphql/api';

const AdNotNullFiltersList: Record<keyof AdNotNullFilters, string> = {
  affiliateRef: 'affiliateRef',
  gclid: 'gclid',
  fbclid: 'fbclid',
  msclkid: 'msclkid',
  nerdwalletRef: 'nerdwalletRef',
  lgLeadId: 'lgLeadId',
  tmSubid1: 'tmSubid1',
  flexRefId: 'flexRefId',
  mdAffiliateId: 'mdAffiliateId',
  utmCampaign: 'utmCampaign',
  utmMedium: 'utmMedium',
  utmSource: 'utmSource',
};

export const AdTrackingNonNullFilter = () => {
  const options = useMemo(
    () =>
      Object.entries(AdNotNullFiltersList).map(([value, label]) => ({
        value,
        label,
      })),
    [],
  );

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const { createQueryString } = useQueryString(searchParams);

  const selectedValues = useMemo(() => {
    const parsedFilters = parseFiltersFromSearchParams<Record<string, boolean>>(searchParams);
    return new Set(Object.keys(parsedFilters.notNullFilters || {}));
  }, [searchParams]);

  const handleUrlUpdate = useCallback(
    (valuesObject: Record<string, any> | null) => {
      const newSearchParams = createQueryString(valuesObject || {});
      navigate(`${pathname}?${newSearchParams}`, { replace: true });
    },
    [createQueryString, navigate, pathname],
  );

  const onSelectValue = useCallback(
    (option: { value: string; label: string }, isSelected: boolean) => {
      const newSelectedValues = new Set(selectedValues);

      if (isSelected) {
        newSelectedValues.delete(option.value);
      } else {
        newSelectedValues.add(option.value);
      }

      const notNullFilters =
        newSelectedValues.size > 0
          ? Array.from(newSelectedValues).reduce(
              (acc, key) => {
                acc[key] = true;
                return acc;
              },
              {} as Record<string, boolean>,
            )
          : null;

      handleUrlUpdate({ notNullFilters });
    },
    [selectedValues, handleUrlUpdate],
  );

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm">
          <CaretSortIcon className="size-4 shrink-0" aria-hidden="true" />
          Non-null filters
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[12.5rem] p-0" align="end">
        <Command>
          <CommandInput placeholder="Filter by..." />
          <CommandList>
            <CommandEmpty>No item found.</CommandEmpty>
            <CommandGroup>
              {options.map(option => {
                const isSelected = selectedValues.has(option.value);

                return (
                  <CommandItem key={option.value} onSelect={() => onSelectValue(option, isSelected)}>
                    <div
                      className={cn(
                        'border-primary mr-2 flex size-4 items-center justify-center rounded-sm border',
                        isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible',
                      )}
                    >
                      <CheckIcon className="size-4" aria-hidden="true" />
                    </div>
                    <span>{option.label}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
