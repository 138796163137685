import type { WatchQueryFetchPolicy } from '@apollo/client';

import {
  CreditApplicationStatus,
  PaymentPreference,
  SecurityType,
  ShipmentCarrier,
  ShipmentStatus,
  ShipmentType,
  useListCardsQuery,
  useListCreditApplicationsQuery,
  useListShipmentsQuery,
  usePaymentStatsQuery,
  useStatsQuery,
  useStatsShipmentQuery,
} from '../../../__generated__/graphql/api';
import { apolloClient } from '../../../providers/Apollo';

export const openPaymentsStatuses = [
  CreditApplicationStatus.Prequalified,
  CreditApplicationStatus.PrequalInfoGiven,
  CreditApplicationStatus.ManualReview,
  CreditApplicationStatus.OfferDecisionNeeded,
];

export const useDashboardData = () => {
  const shippingStatusExcludingUnknown = Object.values(ShipmentStatus).filter(
    status => status !== ShipmentStatus.Unknown,
  );

  const baseStatsQueryOptions = {
    errorPolicy: 'all' as const,
    client: apolloClient,
    fetchPolicy: 'cache-first' as WatchQueryFetchPolicy,
  };

  const { data } = useListCardsQuery({
    ...baseStatsQueryOptions,
    variables: {
      userIsFriendsAndFam: false,
      active: true,
    },
  });
  const cardsIssuedCount = data?.listCards?.pagination?.totalRecords || 0;

  const { data: manualStatsItems } = useStatsQuery({
    ...baseStatsQueryOptions,
    variables: {
      statusSelections: [CreditApplicationStatus.ManualReview],
      securityTypeSelections: [SecurityType.Item],
    },
  });
  const { data: manualStatsCash } = useStatsQuery({
    ...baseStatsQueryOptions,
    variables: {
      statusSelections: [CreditApplicationStatus.ManualReview],
      securityTypeSelections: [SecurityType.Cash],
    },
  });

  const { data: reviewNeededStats } = useStatsQuery({
    ...baseStatsQueryOptions,
    variables: {
      statusSelections: [CreditApplicationStatus.ItemUnderReview],
    },
  });
  const { data: completeStats, loading: loadingCompleteStats } = useStatsQuery({
    ...baseStatsQueryOptions,
    variables: {
      statusSelections: [CreditApplicationStatus.Complete],
    },
  });
  const { data: inProgressStats } = useStatsQuery({
    ...baseStatsQueryOptions,
    variables: {
      statusSelections: [CreditApplicationStatus.AppraisalInProgress],
    },
  });
  const { data: prequalifiedStats } = useStatsQuery({
    ...baseStatsQueryOptions,
    variables: {
      statusSelections: [CreditApplicationStatus.Prequalified],
    },
  });
  const { data: offerDecisionNeeded } = useStatsQuery({
    ...baseStatsQueryOptions,
    variables: {
      statusSelections: [CreditApplicationStatus.OfferDecisionNeeded],
    },
  });
  const { data: offerRejected } = useStatsQuery({
    ...baseStatsQueryOptions,
    variables: {
      statusSelections: [CreditApplicationStatus.OfferRejected],
    },
  });

  const { data: verifyDoc } = useStatsQuery({
    ...baseStatsQueryOptions,
    variables: {
      statusSelections: [CreditApplicationStatus.VerifyDoc],
    },
  });
  const { data: verifyPayment } = useStatsQuery({
    ...baseStatsQueryOptions,
    variables: {
      statusSelections: [CreditApplicationStatus.VerifyPayment],
    },
  });

  const appsNeedingOutboundShippingKitLabelCreated =
    useListCreditApplicationsQuery({
      errorPolicy: 'all',
      client: apolloClient,
      fetchPolicy: 'cache-and-network',
      variables: {
        needsOutboundShippingKitCreated: true,
        paginationDetailsOnly: true,
        userIsFriendsAndFam: false,
      },
    })?.data?.listCreditApplications?.pagination?.totalRecords || 0;

  const appsNeedingOutboundShippingKitLabelSent =
    useListCreditApplicationsQuery({
      errorPolicy: 'all',
      client: apolloClient,
      fetchPolicy: 'cache-and-network',
      variables: {
        needsOutboundShippingKitSent: true,
        paginationDetailsOnly: true,
        userIsFriendsAndFam: false,
      },
    })?.data?.listCreditApplications?.pagination?.totalRecords || 0;

  const { data: dataReceived, loading: loadingReceived } = useStatsShipmentQuery({
    errorPolicy: 'all',
    client: apolloClient,
    fetchPolicy: 'cache-and-network',
    variables: {
      shippingSelections: [ShipmentStatus.Received],
    },
  });

  const { data: dataDelivered, loading: loadingDelivered } = useStatsShipmentQuery({
    errorPolicy: 'all',
    client: apolloClient,
    fetchPolicy: 'cache-and-network',
    variables: {
      shippingSelections: [ShipmentStatus.Delivered],
    },
  });

  const { data: dataUserTransit, loading: loadingTransit } = useListShipmentsQuery({
    errorPolicy: 'all',
    client: apolloClient,
    fetchPolicy: 'cache-and-network',
    variables: {
      paginationDetailsOnly: true,
      carrierSelections: [ShipmentCarrier.Fedex, ShipmentCarrier.Ups],
      shipmentTypeSelections: [ShipmentType.InboundShippingKit, ShipmentType.UserShipping, ShipmentType.Diy],
      statusSelections: [ShipmentStatus.Transit],
    },
  });

  const { data: shippingKits, loading: loadingKits } = useListShipmentsQuery({
    errorPolicy: 'all',
    client: apolloClient,
    fetchPolicy: 'cache-and-network',
    variables: {
      paginationDetailsOnly: true,
      shippingKitSent: false,
      statusSelections: shippingStatusExcludingUnknown,
      shipmentTypeSelections: [ShipmentType.InboundShippingKit],
    },
  });

  const { data: payments } = usePaymentStatsQuery({
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    variables: {
      statusSelections: openPaymentsStatuses,
    },
  });

  const { data: payNow } = usePaymentStatsQuery({
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    variables: {
      statusSelections: openPaymentsStatuses,
      paymentPreferenceSelections: PaymentPreference.PayInFull,
    },
  });
  const { data: payOverTime } = usePaymentStatsQuery({
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    variables: {
      statusSelections: openPaymentsStatuses,
      paymentPreferenceSelections: PaymentPreference.PayOverTime,
    },
  });

  const { data: carrierFedex } = useListShipmentsQuery({
    errorPolicy: 'all',
    client: apolloClient,
    fetchPolicy: 'cache-and-network',
    variables: {
      carrierSelections: [ShipmentCarrier.Fedex],
    },
  });

  const { data: carrierUps } = useListShipmentsQuery({
    errorPolicy: 'all',
    client: apolloClient,
    fetchPolicy: 'cache-and-network',
    variables: {
      carrierSelections: [ShipmentCarrier.Ups],
    },
  });

  const { data: carrierUsps } = useListShipmentsQuery({
    errorPolicy: 'all',
    client: apolloClient,
    fetchPolicy: 'cache-and-network',
    variables: {
      carrierSelections: [ShipmentCarrier.Usps],
    },
  });

  return {
    offerRejected,
    verifyDoc,
    verifyPayment,
    offerDecisionNeeded,
    prequalifiedStats,
    inProgressStats,
    completeStats,
    loadingCompleteStats,
    reviewNeededStats,
    manualStatsItems,
    manualStatsCash,
    shippingKits,
    payments,
    payNow,
    payOverTime,
    loadingKits,
    dataUserTransit,
    loadingTransit,
    dataDelivered,
    loadingDelivered,
    dataReceived,
    loadingReceived,
    appsNeedingOutboundShippingKitLabelSent,
    appsNeedingOutboundShippingKitLabelCreated,
    carrierUsps,
    carrierFedex,
    carrierUps,
    cardsIssuedCount,
  };
};
