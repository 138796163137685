import { BackButton } from '../../components/BackButton';
import type { BreadcrumbResponsiveProps } from '../../components/Breadcrumbs/BreadcrumbComponent';
import { BreadcrumbComponent } from '../../components/Breadcrumbs/BreadcrumbComponent';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../components/Tabs';

type Tabs = {
  label: string;
  value: string;
  content: React.ReactNode;
  amount?: number | string;
};

interface CreditApplicationDetailScreenProps {
  breadcrumb: BreadcrumbResponsiveProps;
  tabs: Tabs[];
  loading?: boolean;
}

export const CreditApplicationDetailScreen = (props: CreditApplicationDetailScreenProps) => {
  const { breadcrumb, tabs, loading } = props;

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <BreadcrumbComponent {...breadcrumb} />
          <BackButton />
          <div className={'flex h-full w-full flex-col gap-4'}>
            <div className="flex w-full flex-col gap-4 p-2">
              <Tabs defaultValue={tabs[0].value}>
                <TabsList>
                  {tabs.map(tab => (
                    <TabsTrigger value={tab.value} amount={tab.amount} key={tab.value}>
                      {tab.label}
                    </TabsTrigger>
                  ))}
                </TabsList>
                {tabs.map(tab => (
                  <TabsContent value={tab.value} key={tab.value}>
                    {tab.content}
                  </TabsContent>
                ))}
              </Tabs>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
