import { cn } from '@pesto/utils';
import { CheckIcon } from 'lucide-react';

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '../../../../components/Command';
import type { Option } from '../../../../components/DataTable/DataTable.types';

interface DataTableAdvancedFacetedFilterProps {
  title?: string;
  options: Option[];
  selectedValues: Set<string>;
  onSelectValue: (value: any, index: number, isSelected: boolean) => void;
  onClear: () => void;
}

export function DataTableAdvancedFacetedFilter({
  title,
  options,
  selectedValues,
  onSelectValue,
  onClear,
}: DataTableAdvancedFacetedFilterProps) {
  return (
    <Command className="p-1">
      <div className="border-input flex h-9 w-full rounded-md border bg-transparent px-3 py-1 text-sm shadow-sm [&_[cmdk-input-wrapper]]:border-0 [&_[cmdk-input-wrapper]]:px-0">
        <CommandInput placeholder={title} className="h-full border-0 pl-0 ring-0" autoFocus />
      </div>
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup className="px-0">
          {options.map((option, index) => {
            const isSelected = selectedValues.has(String(option.value));

            return (
              <CommandItem key={String(option.value) + index} onSelect={() => onSelectValue(option, index, isSelected)}>
                <div
                  className={cn(
                    'border-foreground mr-2 flex size-4 items-center justify-center rounded-sm border',
                    isSelected ? 'bg-foreground text-foreground-foreground' : 'opacity-50 [&_svg]:invisible',
                  )}
                >
                  <CheckIcon className="size-4" aria-hidden="true" />
                </div>
                {option.icon && <option.icon className="text-muted-foreground mr-2 size-4" aria-hidden="true" />}
                <span>{option.label}</span>
                {option.withCount && (
                  <span className="ml-auto flex size-4 items-center justify-center font-mono text-xs">
                    {option.count}
                  </span>
                )}
              </CommandItem>
            );
          })}
        </CommandGroup>
        {selectedValues.size > 0 && (
          <>
            <CommandSeparator />
            <CommandGroup>
              <CommandItem onSelect={onClear} className="justify-center text-center">
                Clear filters
              </CommandItem>
            </CommandGroup>
          </>
        )}
      </CommandList>
    </Command>
  );
}
